<template>
    <div
        class="file-drop"
        @dragover.prevent="handleDragOver"
        @dragleave="handleDragLeave"
        @drop.prevent="handleDrop"
        @click="triggerFileInput"
        :class="{ 'drag-over': isDragging }"
    >

        <p v-if="!file">Glissez et déposez un fichier ici, ou cliquez pour télécharger</p>
        <p v-else>Fichier sélectionné : {{ file.name }}</p>
        <input type="file" ref="fileInput" @change="handleFileInput" hidden accept="application/pdf" />
        <p class="error-msg" v-if="showError">{{ error_msg }}</p>
    </div>
</template>
  
<script>
export default {
    props: ['allTypes'],
    data() {
        return {
            file: null,
            isDragging: false,
            error_msg: "erreur",
            showError: false,
        };
    },
    methods: {
        handleDragOver() {
            this.isDragging = true;
        },
        handleDragLeave() {
            this.isDragging = false;
        },
        async handleDrop(event) {
            this.isDragging = false;
            const files = event.dataTransfer.files;

            if (files[0].type !== "application/pdf" && !this.allTypes) {
                this.error_msg = "Erreur : Le fichier doit être au format PDF"
                this.showError = true
                setTimeout(() => {
                    this.showError = false
                }, 2000)

            } else if (files.length > 0) {
                this.file = files[0];
                const base64 = await this.convertToBase64(this.file);
                this.$emit('file-dropped', base64); // Emit base64 string
            }
        },
        triggerFileInput() {
            this.$refs.fileInput.click(); // Ouvre la boîte de dialogue de fichier
        },
        async handleFileInput(event) {
            const files = event.target.files;
            if (files.length > 0 && files[0].type === "application/pdf") {
                this.file = files[0];
                const base64 = await this.convertToBase64(this.file);
                this.$emit('file-dropped', base64); // Emit event with the file
            }
        },
        convertToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result.split(',')[1]); // base64 only
                reader.onerror = error => reject(error);
            });
        },
    },
};
</script>
  
<style>
    .file-drop {
        border: 2px dashed #ccc;
        padding: 20px;
        text-align: center;
        cursor: pointer;
        transition: background-color 0.3s;
    }
    .file-drop.drag-over {
        background-color: #f0f0f0;
    }
    .error-msg {
        color: red;
    }
</style>
  