<template>
    <div class="side-template">
        <div class="template-content">
            <div class="title-icon"><i class="material-icons">bar_chart</i></div>

            <div class="view-selection">
                <select v-model="selectedVue">
                    <option value="all-wealth">Vue globale</option>
                    <option value="family-wealth">Patrimoine familial</option>
                    <option v-for="society in $store.getters.getAllSocieties" :key="society._id" :value="society._id">Patrimoine de {{society.type.toUpperCase()}} {{ society.label }}</option>
                </select>
            </div>

            <div class="indicators-wrapper">
                <div class="indicator-box" @click="toggleComputationDetail('legacy')">
                    <h2>Droits de succession</h2>
                    <i class="material-icons">account_tree</i>
                    <h1>{{ toEuro(legacy.inheritance_cost) }}</h1>
                </div>

                <div class="indicator-box" @click="toggleComputationDetail('taxes')">
                    <h2>Impôt sur le revenu</h2>
                    <i class="material-icons">request_quote</i>
                    <h1>{{ toEuro(taxes_value) }}</h1>
                </div>

                <div class="indicator-box" @click="toggleComputationDetail('wealth-taxes')">
                    <h2>Impôt sur la fortune</h2>
                    <i class="material-icons">real_estate_agent</i>
                    <h1>{{ toEuro(ifi_value) }}</h1>
                </div>

                <div class="indicator-box" @click="toggleComputationDetail('loan-capacity')">
                    <h2>Capacité d'emprunt</h2>
                    <span class="material-symbols-outlined">money_bag</span>
                    <h1>{{ toEuro(loan_capacity) }}</h1>
                </div>
            </div>
        </div>

        <div class="overlay" v-if="selectedComputationDetail != undefined" @click="toggleComputationDetail(undefined)"></div>

        <div class="detail-page" v-if="selectedComputationDetail != undefined">
            <LegacyResult v-if="selectedComputationDetail == 'legacy'"/>
            <TaxResult v-if="selectedComputationDetail == 'taxes'"/>
            <WealthTaxes v-if="selectedComputationDetail == 'wealth-taxes'"/>
            <LoanCapacity v-if="selectedComputationDetail == 'loan-capacity'"/>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import LegacyResult from "../../../../results/Legacy.vue";
import TaxResult from "../../../../results/Taxes.vue";
import WealthTaxes from "../../../../results/WealthTaxes.vue";
import LoanCapacity from '../../../../results/LoanCapacity.vue';

export default {
    components: {
        LegacyResult,
        TaxResult,
        WealthTaxes,
        LoanCapacity,
    },
    data(){
        return {
            selectedVue: "all-wealth",
            selectedComputationDetail: undefined,
            legacy_params: {
                order: 1,
                spouseChoice: '100%US',
            },
            ifi_value: 0,
            legacy: 0,
            taxes_value: 0,
            loan_capacity: 0,
        }
    },
    watch: {
        selectedVue() {
            this.$store.commit('updateSelectedVue', this.selectedVue)
        },
        '$store.getters.is_loaded_scenario': {
            handler() {
                this.computeAll()
            }
        },
        '$store.state': {
            deep: true,
            handler() {
                if (this.$store.getters.is_loaded_scenario) {
                    this.computeAll()
                }
            }
        }
    },
    methods: {
        toggleComputationDetail(name) {
            if (this.selectedComputationDetail == name) {
                this.selectedComputationDetail = undefined
            } else {
                this.selectedComputationDetail = name
            }
        },
        async computeIfi() {
            let body = this.$store.getters.ifiDatas

            const ifi_res = await axios.post(this.$store.getters.get_api_url + 'simulators/ifi-tax', body, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            
            this.ifi_value = ifi_res.data?.ifi_reel?.impot
        },
        async computeLegacy() {
            try {
                const body = {
                    datas: this.$store.getters.legacyDatas(this.legacy_params.order),
                    spouseChoice: this.legacy_params.spouseChoice,
                    death_date: this.legacy_params.death_date,
                }
                
                const res = await axios.post(this.$store.getters.get_api_url + 'simulators/legacy', body, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })
                this.legacy = res.data

            } catch(err) {
                this.legacy_taxes = 0
            }
        },
        async computeTaxes() {
            try {
                let body = this.$store.getters.taxesDatas
                body.choices = {
                    bpi_pvmo: false,
                }

                body.year = 2023
                
                const res = await axios.post(this.$store.getters.get_api_url + 'simulators/income-taxes', body, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })

                const taxesResults = res.data

                this.taxes_value = taxesResults.pfu + taxesResults.netTaxes?.taxes

            } catch(err) {
                this.taxes_value = 0
            }
            
        },
        async computeLoanCapacity() {
            const body = this.$store.getters.loanCapacityDatas({
                nb_mois: 240,
                taux: 0.03,
                taux_assurance: 0,
                achat_rp: false,
            });

            const res = await axios.post(this.$store.getters.get_api_url + 'simulators/loan-capacity', body, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            });
            
            this.loan_capacity = res.data?.resultat?.capacite_emprunt;
        },
        computeAll() {
            this.computeIfi();
            this.computeLegacy();
            this.computeTaxes();
            this.computeLoanCapacity();
        }
    },
    async mounted() {
        const res = await axios.get(this.$store.getters.get_api_url + 'clients/legacy-simulation/' + this.$route.params.scenarioId, {
            headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
        })
        if (res.data) {
            this.legacy_params.order = res.data.order_after
            this.legacy_params.spouseChoice = res.data.choice_after
            this.legacy_params.death_date = res.data.date
        }
    }
}
</script>

<style scoped>
.side-template {
    z-index: 100;
    background-color: white;
    -webkit-box-shadow: 0px 0px 10px -3px #C7C7C7; 
    box-shadow: 0px 0px 10px -3px #C7C7C7;
}
.template-content {
    position: fixed;
    top: 50px;
    right: 0px;
    padding: 25px;
    width: 200px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    color: black;
    align-items: center;
}

.title-icon {
    display: flex;
    justify-content: center;
}

.title-icon .material-icons {
    font-size: 100px;
    color: orange;
}

.view-selection {
    position: relative;
    width: 100%;
    min-height: 50px;
}
.view-selection select {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left:0;
    white-space: nowrap; /* Empêche le texte de s'étaler sur plusieurs lignes */
    overflow: hidden; /* Cache le débordement */
    text-overflow: ellipsis; /* Affiche des points de suspension si le texte est trop long */
}

.indicators-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.indicator-box {
    cursor: pointer;
    padding: 15px;
    margin-top: 15px;
    text-align: center;
    -webkit-box-shadow: 0px 0px 10px -3px #0a0a0a; 
    box-shadow: 0px 0px 10px -3px #C7C7C7;
    border-radius: 15px;
}
.indicator-box h1 {
    padding: 0;
    margin: 0;
    font-size: 16px;
}
.indicator-box h2 {
    font-size: 12px;
}
.indicator-box i, .indicator-box span {
    font-size: 30px;
    color: orange;
}

.overlay {
    position: absolute;
    top: 0px;
    left:0;
    right: 200px;
}

.detail-page {
    z-index: 20;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 200px;
    bottom: 0;
    padding: 30px;
    background-color: white;
    overflow: scroll;
    border-right: 1px solid lightgrey;
}
</style>