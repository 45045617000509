<template>
    <div class="event-frame" v-if="event.type">
        <div class="header">
            <select v-model="type">
                <option v-for="(type, key) in event_types" :key="key" :value="key">{{type}}</option>
            </select>
            <input class="input-title" type="text" v-model="title">
            <input type="date" v-model="date">
        </div>
        
        <text-editor
            id="text" 
            v-model="text"
            height="400"
        />
        <div class="btn-wrapper">
            <div class="btn-save" @click="saveModifications"><span class="material-symbols-outlined">download_for_offline</span>Enregistrer</div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: ['event'],
    data() {
        return {
            text: '',
            title: '',
            type: '',
            date: '',
            event_types: {
                call: 'Appel',
                email: 'Email',
                meeting: 'Rendez-vous',
                visio: 'Visio',
                event: 'Evènement',
                action: "Action",
            },
        }
    },
    methods: {
        async saveModifications() {
            let body   = this.event;
            body.text  = this.text;
            body.title = this.title;
            body.type  = this.type;
            body.date = this.date;

            await axios.put(this.$store.getters.get_api_url + 'crm/event/' + this.event._id, body, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            });
        }
    },
    watch: {
        'event.text': {
            handler() {
                this.text = this.event.text
            }
        },
        'event.title': {
            handler() {
                this.title = this.event.title
            }
        },
        'event.type': {
            handler() {
                this.type = this.event.type
            }
        },
        'event.date': {
            handler() {
                const date = new Date(this.event.date);
                this.date = date.toISOString().split("T")[0];
            }
        },
    }
}
</script>

<style scoped>
.event-frame {
    padding: 1%;
    height: 91%;
}
.event-frame .header {
    display: flex;
    margin-bottom: 10px;
}
.event-frame .header select,
.event-frame .header input {
    padding: 10px;
    border: 1px solid lightgrey;
}
.event-frame .header select:first-child {
    border-radius: 5px 0 0 5px;
    min-width: 150px;
    border-right: none;
}
.event-frame .header input:last-child {
    border-radius: 0 5px 5px 0;
    border-left: none;
    flex-grow: 1;
}

.btn-wrapper {
    display: flex;
    justify-content: center;
}
.btn-save {
    cursor: pointer;
    margin: 10px auto;
    display: inline-block;
    text-align: center;
    padding: 10px;
    background-color: rgb(3, 3, 95);
    color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
}
.btn-save:hover {
    background-color: rgb(255, 166, 0);
}
.input-title {
    min-width: 500px;
}
</style>