<template>
    <div>
        <div class="menu">
            <div :class="{'menu-selection': !show_detailed_result}" @click="show_detailed_result=false">Données de simulation</div>
            <div :class="{'menu-selection': show_detailed_result, 'menu-disabled': computationResult == undefined}" @click="computationResult ? show_detailed_result=true : null">Résultat détaillé</div>
        </div>

        <div class="calculator-frame">

            <div class="left-section" v-if="!show_detailed_result">
                <div class="section-element">
                    <h3>Donation</h3>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>La donation concerne un bien immobilier</label>
                            <select v-model="is_real_estate">
                                <option :value="true">OUI</option>
                                <option :value="false">NON</option>
                            </select>
                        </div>

                        <div class="input-frame">
                            <label>Valeur du bien donné</label>
                            <currency-input v-model="donation_value"/>
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Donation de nue-propriété avec réserve d'usufruit</label>
                            <select v-model="is_bare_property">
                                <option :value="true">OUI</option>
                                <option :value="false">NON</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-section" v-if="is_bare_property">
                        <div class="input-frame">
                            <label>Age de l'usufruitier</label>
                            <input type="date" v-model="usufruct_birthdate"/>
                        </div>

                        <div class="input-frame">
                            <label>Valeur de la nue-propriété</label>
                            <currency-input v-model="barePropertyValue" disabled/>
                        </div>
                    </div>
                </div>
                
                <div class="section-element">
                    <h3>Bénéficiaire de la donation</h3>
                    
                    <div class="form-section">
                        <div class="input-frame">
                            <label>Lien de parenté</label>
                            <select v-model="donee_role">
                                <option v-for="(value, key) in filteredRoles" :key="key" :value="key">{{ value }}</option>
                            </select>
                        </div>

                        <div class="input-frame">
                            <label>Montant des donations reçues les 15 dernières années</label>
                            <currency-input v-model="past_donation_value"/>
                        </div>
                    </div>
                </div>

                <div class="section-element btn-section">
                    <div class="btn-send-computation btn-grow" @click="computeResult">Calculer</div>
                </div>
            </div>

            <div class="left-section" v-else>
                <div class="section-element">
                    <h3>Calcul de l'assiette taxable</h3>

                    <table>
                        <tr>
                            <th>Valeur du bien donné</th>
                            <td>{{ toEuro(donation_value) }}</td>
                        </tr>

                        <tr v-if="is_bare_property">
                            <th>Valeur de la nue-propriété</th>
                            <td>{{ toEuro(barePropertyValue) }}</td>
                        </tr>

                        <tr v-if="computationResult[1].used_reduction_790G > 0">
                            <th>Abattement pour don familial de liquidités</th>
                            <td>{{ toEuro(computationResult[1].reduction_available_790G) }}</td>
                        </tr>

                        <tr v-if="computationResult[1].used_reduction > 0">
                            <th>Abattement de droit commun</th>
                            <td>{{ toEuro(computationResult[1].reduction_available) }}</td>
                        </tr>

                        <tr v-if="computationResult[1].used_reduction_handicapped > 0">
                            <th>Abattement personnes handicappées</th>
                            <td>{{ toEuro(computationResult[1].reduction_available_handicapped) }}</td>
                        </tr>

                        <tr class="highlight">
                            <th>Valeur taxable</th>
                            <td>{{ toEuro(computationResult[1].taxable) }}</td>
                        </tr>
                    </table>
                </div>

                <div class="section-element">
                    <h3>Calcul de l'impôt</h3>

                    <table>
                        <tr class="highlight">
                            <th>Tranche</th>
                            <th>Taux</th>
                            <th>Impôt</th>
                        </tr>

                        <tr v-for="(scale, index) in computationResult[1].used_scales" :key="index">
                            <th>{{ scale.max ? `Jusqu'à ${ toEuro(scale.max) }`: 'Au delà' }}</th>
                            <td>{{ Math.round(scale.rate * 100) }} %</td>
                            <td>{{ toEuro(scale.used * scale.rate) }}</td>
                        </tr>

                        <tr class="highlight">
                            <th>Total</th>
                            <th></th>
                            <th>{{ toEuro(computationResult[1].used_scales.map(s => s.used * s.rate).reduce((sum, current) => sum + current, 0)) }}</th>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="right-section">
                <div class="section-element" v-if="!computationResult">
                    <h3>Résultats</h3>

                    <img src="../ressources/img/illustration.png"/>

                    <p>Lancez le calcul pour obtenir un résultat</p>
                </div>

                <div class="section-element" v-if="computationResult">
                    <h3>Assiette taxable</h3>
                    <h4>{{ toEuro(computationResult[1].taxable) }}</h4>
                </div>

                <div class="section-element" v-if="computationResult">
                    <h3>Impôt à payer</h3>
                    <h4>{{ toEuro(computationResult[1].tax) }}</h4>
                </div>

                <div class="section-element" v-if="computationResult">
                    <h3>Frais de notaire</h3>
                    <h4>{{ toEuro(computationResult[1].notary.total) }}</h4>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            show_detailed_result: false, 
            is_real_estate: true,
            donee_role: 'child',
            donation_value: 100000,
            past_donation_value: 0,
            is_bare_property: false,
            usufruct_birthdate: "1960-01-01",

            computationResult: undefined,
        }
    },
    computed: {
        filteredRoles() {
            let result = {}

            Object.keys(this.$store.getters.allRoles).forEach(key => {
                if (key != 'user') {
                    result[key] = this.$store.getters.allRoles[key]
                }
            })

            return result
        },
        barePropertyValue() {
            const age = this.getAge(this.usufruct_birthdate)
            let us_value = 0
            if (age < 21) {
                us_value = 0.9
            } else if (age < 31) {
                us_value = 0.8
            } else if (age < 41) {
                us_value = 0.7
            } else if (age < 51) {
                us_value = 0.6
            } else if (age < 61) {
                us_value = 0.5
            } else if (age < 71) {
                us_value = 0.4
            } else if (age < 81) {
                us_value = 0.3
            } else if (age < 91) {
                us_value = 0.2
            } else {
                us_value = 0.1
            }

            return this.donation_value * (1 - us_value)
        },
        requestBody() {
            const yesterday = new Date()
            yesterday.setDate(yesterday.getDate() - 1);

            let body = {
                donations: [
                    {
                        _id: 'pastdonations',
                        type: 'manual',
                        donee: 'donee1',
                        value: this.past_donation_value,
                        fiscal_value: this.past_donation_value,
                        date: yesterday,
                    },
                    {
                        _id: 'currentdonation',
                        type: this.is_real_estate ? 'property' : 'manual',
                        donee: 'donee1',
                        is_dismembered: this.is_bare_property,
                        value: this.donation_value,
                        fiscal_value: this.is_bare_property ? this.barePropertyValue : this.donation_value,
                        date: new Date(),
                    }
                ],
                donee: {
                    _id: 'donee1',
                    role: this.donee_role,
                },
            }

            return body
        },
        donationResult() {
            if (!this.computationResult) {
                return {}
            } else {
                return this.computationResult.find(d => d._id == 'currentdonation')
            }
        }
    },
    methods: {
        async computeResult() {
            const res = await axios.post(this.$store.getters.get_api_url + 'simulators/donation-taxes/', this.requestBody, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.computationResult = res.data
        }
    }
}
</script>

<style src="./style.css" scoped></style>