<template>
    <div v-if="show">    
        <teleport to="#scenario-content-frame">
            <div class="overlay" @click="toggleModale"></div>
            <slot></slot>
        </teleport>
    </div>
</template>

<script>
export default {
    props: ['show', 'width'],
    methods: {
        toggleModale() {
            this.$emit('toggle')
        }
    },
}
</script>

<style scoped>
.overlay {
    z-index: 999;
    cursor: pointer;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(126, 125, 125, 0.818);
}
</style>