<template>
    <div>
        <div class="menu">
            <div :class="{'menu-selection': !show_detailed_result}" @click="show_detailed_result=false">Données de simulation</div>
            <div :class="{'menu-selection': show_detailed_result, 'menu-disabled': computationResult == undefined}" @click="computationResult ? show_detailed_result=true : null">Résultat détaillé</div>
        </div>

        <div class="calculator-frame" >
            <div class="left-section" v-if="!show_detailed_result">
                <div class="section-element">
                    <h3>Données de simulation</h3>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Méthode de valorisation</label>

                            <select v-model="methode_de_valorisation">
                                <option v-for="(value, key) in methods" :value="key" :key="key">{{value}}</option>
                            </select>
                        </div>

                        <div class="input-frame">
                            <label>Valeur du bien</label>
                            <currency-input v-model="valeur_du_bien"/>
                        </div>
                    </div>

                    <div class="form-section" v-if="methode_de_valorisation == 'viager'">
                        <div class="input-frame">
                            <label>Age de l'usufruitier</label>
                            <input type="number" v-model="methode_viager.age">
                        </div>
                    </div>

                    <div class="form-section" v-if="methode_de_valorisation == 'duree_fixe'">
                        <div class="input-frame">
                            <label>Durée du démembrement en année</label>
                            <input type="number" v-model="methode_duree_fixe.nb_annees">
                        </div>
                    </div>

                    <div class="form-section" v-if="methode_de_valorisation == 'economique'">
                        <div class="input-frame">
                            <label>Taux de rendement</label>
                            <percent-input v-model="methode_economique.taux_rentabilite"/>
                        </div>

                        <div class="input-frame">
                            <label>Taux de revalorisation du revenu</label>
                            <percent-input v-model="methode_economique.taux_revalorisation_revenu"/>
                        </div>
                    </div>

                    <div class="form-section" v-if="methode_de_valorisation == 'economique'">
                        <div class="input-frame">
                            <label>Durée du démembrement en années</label>
                            <input type="number" v-model="methode_economique.nb_annees"/>
                        </div>
                    </div>
                </div>

                <div class="section-element btn-section">
                    <div class="btn-send-computation btn-grow" @click="computeResult">Calculer</div>
                </div>
            </div>

            <div class="left-section" v-else>
                <div class="section-element">
                    <h3>Résulat détaillé</h3>
                    <table>
                        <tr v-if="methode_de_valorisation == 'viager'">
                            <th>Age de l'usufruitier</th>
                            <td>{{ toEuro(computationResult.resultat.age_usufruitier) }}</td>
                        </tr>

                        <tr>
                            <th>Valeur de l'usufruit</th>
                            <td>{{ toEuro(computationResult.resultat.valeur_usufruit) }}</td>
                        </tr>

                        <tr>
                            <th>Pourcentage usufruit</th>
                            <td>{{ toPercent(computationResult.resultat.valeur_usufruit_pourcentage) }}</td>
                        </tr>

                        <tr>
                            <th>Valeur de la nue-propriété</th>
                            <td>{{ toEuro(computationResult.resultat.valeur_nue_propriete) }}</td>
                        </tr>

                        <tr>
                            <th>Pourcentage nue-propriété</th>
                            <td>{{ toPercent(computationResult.resultat.valeur_nue_propriete_pourcentage) }}</td>
                        </tr>
                    </table>

                    <div v-if="methode_de_valorisation == 'economique'">
                        <h3>Tableau actualisation usufruit economique</h3>
                        <h6>La valeur economique de l'usufruit correspond à la somme de ses flux futurs actualisés :</h6>
                        <table>
                            <tr>
                                <th>Année</th>
                                <th>Loyer</th>
                                <th>Loyer actualisé</th>
                                <th>Valeur usufruit</th>
                            </tr>

                            <tr v-for="(year, index) in computationResult.resultat.tableau_calcul" :key="index">
                                <td>{{ year.annee }}</td>
                                <td>{{ toEuro(year.loyer) }}</td>
                                <td>{{ toEuro(year.loyer_actualise) }}</td>
                                <td>{{ toEuro(year.valeur_usufruit) }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

            <div class="right-section">
                <div class="section-element" v-if="!computationResult">
                    <h3>Résultats</h3>

                    <img src="../ressources/img/illustration.png"/>

                    <p>Lancez le calcul pour obtenir un résultat</p>
                </div>

                <div class="section-element" v-if="computationResult">
                    <h3>Valeur de l'usufruit</h3>
                    <h4>{{ toEuro(computationResult.valeur_usufruit) }}</h4>
                </div>

                <div class="section-element" v-if="computationResult">
                    <h3>Valeur de la nue-propriété</h3>
                    <h4>{{ toEuro(computationResult.resultat.valeur_nue_propriete) }}</h4>
                </div>
            </div>
        </div> 
    </div>
 </template>
 
 <script>
 import axios from 'axios'
 
 export default {
     data() {
         return {
            show_detailed_result: false,
            methods: {
                viager: 'Méthode fiscale démembrement viager',
                duree_fixe: 'Méthode fiscale démembrement à durée fixe',
                economique: 'Méthode économique',
            },

            methode_de_valorisation: "economique", // economique, viager, duree_fixe
            valeur_du_bien: 100000,
            methode_economique: {
                taux_rentabilite: 4,
                taux_revalorisation_revenu: 1.5,
                nb_annees: 15,
            },
            methode_viager: {
                date_de_naissance: undefined,
                age: 65, //prioritaire
            },
            methode_duree_fixe: {
                nb_annees: 15,
            },

            computationResult: undefined,
         }
     },
     computed: {
         requestBody() {
            const body = {
                methode_de_valorisation: this.methode_de_valorisation, // economique, viager, duree_fixe
                valeur_du_bien: this.valeur_du_bien,
                methode_economique: {
                    taux_rentabilite: this.methode_economique.taux_rentabilite / 100,
                    taux_revalorisation_revenu: this.methode_economique.taux_revalorisation_revenu / 100,
                    nb_annees: this.methode_economique.nb_annees,
                },
                methode_viager: {
                    age: this.methode_viager.age,
                },
                methode_duree_fixe: {
                    nb_annees: this.methode_duree_fixe.nb_annees,
                }
            }
 
            return body
         },
     },
     methods: {
         async computeResult() {
            const res = await axios.post(this.$store.getters.get_api_url + 'simulators/usufruct-value/', this.requestBody, {
                 headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            });
 
            this.computationResult = res.data;
         }
     }
 }
 </script>
 
 <style src="./style.css" scoped></style>

<style scoped>
.left-section .section-element table {
    table-layout: fixed;
}
.left-section .section-element table th {
    background-color: rgb(0, 0, 80);
    color: white;
}
</style>