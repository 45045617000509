<template>
    <div>
        <div class="menu">
            <div :class="{'menu-selection': !show_detailed_result}" @click="show_detailed_result=false">Données de simulation</div>
            <div :class="{'menu-selection': show_detailed_result, 'menu-disabled': computationResult == undefined}" @click="computationResult ? show_detailed_result=true : null">Résultat détaillé</div>
        </div>
        
        <div class="calculator-frame">
            <div class="left-section" v-if="!show_detailed_result">

                <div class="section-element">
                    <h3>Actif taxable</h3>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Valeur de la résidence principale</label>
                            <currency-input v-model="main_property_value"/>
                        </div>

                        <div class="input-frame">
                            <label>Valeur des autres biens immobilier</label>
                            <currency-input v-model="real_estate_gross"/>
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Valeur des unités de compte immobilières</label>
                            <currency-input v-model="uc_immo_value"/>
                        </div>
                    </div>
                </div>

                <div class="section-element">
                    <h3>Passif déductible</h3>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Capital restant dû sur la résidence principale</label>
                            <currency-input v-model="crd_main_property"/>
                        </div>
                    </div>

                    <div class="form-section">

                        <div class="input-frame">
                            <label>Capital restant dû sur les autres emprunts immobiliers</label>
                            <currency-input v-model="crd_real_estate"/>
                        </div>
                    </div>
                </div>

                <div class="section-element btn-section">
                    <div class="btn-send-computation btn-grow" @click="computeResult">Calculer</div>
                </div>
            </div>

            <div class="left-section" v-else>
                <div class="section-element">
                    <h3>Assiette imposable</h3>

                    <table>
                        <tr>
                            <th>Actif immobilier brut</th>
                            <td>{{ toEuro(computationResult.actif_brut.actif_brut_total) }}</td>
                        </tr>

                        <tr>
                            <th>Passif déductible</th>
                            <td>{{ toEuro(computationResult.passif_deductible.total_deductible_hors_ifi_theorique) }}</td>
                        </tr>

                        <tr>
                            <th>IFI théorique</th>
                            <td>{{ toEuro(computationResult.ifi_theorique.impot) }}</td>
                        </tr>

                        <tr class="highlight">
                            <th>Assiette imposable</th>
                            <td>{{ toEuro(computationResult.ifi_reel.assiette_ifi) }}</td>
                        </tr>
                    </table>
                </div>

                <div class="section-element">
                    <h3>Calcul de l'IFI</h3>

                    <table>
                        <tr v-for="(scale, index) in computationResult.ifi_reel.tranches_utilises" :key="index">
                            <th>{{ scale.label }}</th>
                            <td>{{ Math.round(scale.rate * 100) }} %</td>
                            <td>{{ toEuro(scale.usedScale) }}</td>
                            <td>{{ toEuro(scale.tax) }}</td>
                        </tr>

                        <tr class="highlight">
                            <th>Total</th>
                            <td></td>
                            <td>{{ toEuro(computationResult.ifi_reel.tranches_utilises.map(s => s.usedScale).reduce((sum, current) => sum + current, 0)) }}</td>
                            <td>{{ toEuro(computationResult.ifi_reel.tranches_utilises.map(s => s.tax).reduce((sum, current) => sum + current, 0)) }}</td>
                        </tr>
                    </table>

                    <section v-if="computationResult.ifi_reel.decote_infos.decote > 0">
                        <h5>En dessous de 1 400 000 €, une décôte est applicable selon la formule suivante :</h5>
                        <p style="text-align: center;">{{ computationResult.ifi_reel.decote_infos.formule }}</p>
                        <h5>L'IFI à payer sera donc de {{ toEuro(computationResult.ifi_reel.impot_avant_decote) }} - {{ toEuro(computationResult.ifi_reel.decote_infos.decote) }} = <b>{{ toEuro(computationResult.ifi_reel.impot) }}</b></h5>
                    </section>
                </div>
            </div>
            
            <div class="right-section">
                <div class="section-element" v-if="!computationResult">
                    <h3>Résultats</h3>

                    <img src="../ressources/img/illustration.png"/>

                    <p>Lancez le calcul pour obtenir un résultat</p>
                </div>

                <div class="section-element" v-if="computationResult">
                    <h3>Assiette de l'IFI</h3>
                    <h4>{{ toEuro(computationResult.ifi_reel.assiette_ifi) }}</h4>
                </div>

                <div class="section-element" v-if="computationResult">
                    <h3>IFI à payer</h3>
                    <h4>{{ toEuro(computationResult.ifi_reel.impot) }}</h4>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            show_detailed_result: false,
            real_estate_gross: 1400000,
            main_property_value: 300000,
            uc_immo_value: 0,
            crd_real_estate: 200000,
            crd_main_property: 100000,
            computationResult: undefined,
        }
    },
    computed: {
        requestBody() {
            let body = {
                persons: [{ _id: 'user1', role: 'user' }],
                properties: [],
                societies: [],
                assets: [],
                shareholders_accounts: [],
                movables: [],
                debts: [],
                incomes: [],
                insurances: [],
            }

            if (this.main_property_value > 0) {
                body.properties.push({
                    _id: 'mainproperty',
                    label: "Résidence principale",
                    type: 'main_property',
                    tenant_company: undefined,
                    value: this.main_property_value,
                    detention: [ { owner_id: 'user1', part: 1, right: 'pp' } ],
                    opening_date: undefined,
                    isfixedTermDismemberment: false,
                    dismembermentDuration: undefined,
                });
            }

            body.properties.push({
                _id: 'realestate',
                label: "Autre immobilier",
                type: 'secondary',
                tenant_company: undefined,
                value: this.real_estate_gross,
                detention: [ { owner_id: 'user1', part: 1, right: 'pp' } ],
                opening_date: undefined,
                isfixedTermDismemberment: false,
                dismembermentDuration: undefined,
            });

            const start_date = `${ new Date().getFullYear() + 1 }-01-01`;

            if (this.crd_main_property > 0) {
                body.debts.push({
                    _id: "rpdebt",
                    label: "CRD RP",
                    type: 'property_loan',
                    value: this.crd_main_property,
                    owner1: 'user1',
                    owner2: undefined,
                    refund_type: 'amortization',
                    start_date: start_date,
                    end_date: undefined,
                    rate: 0,
                    insurance_rate: 0,
                    property_id: "mainproperty",
                });
            }

            if (this.crd_real_estate > 0) {
                body.debts.push({
                    _id: 'otherdebt',
                    label: "CRD other",
                    type: 'property_loan',
                    value: this.crd_real_estate,
                    owner1: 'user1',
                    owner2: undefined,
                    refund_type: 'amortization',
                    start_date: start_date,
                    end_date: undefined,
                    rate: 0,
                    insurance_rate: 0,
                    property_id: "realestate",
                });
            }

            return body;
        }
    },
    methods: {
        async computeResult() {
            
            const res = await axios.post(this.$store.getters.get_api_url + 'simulators/ifi-tax', this.requestBody, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.computationResult = res.data
        }
    }
}
</script>

<style src="./style.css" scoped></style>