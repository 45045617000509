<template>
    <li>
        <span>{{ textObject.title }}</span>
        <span class="material-symbols-outlined" style="cursor: pointer" @click="toggleModale">border_color</span>

        <modale :show="showModale" @toggle="toggleModale">
            <label for="title">Titre de la partie</label>
            <input type="text" v-model="body.title">

            <text-editor
                id="add-text" 
                v-model="body.text"
                height="500"
            />

            <div class="btn-save" @click="modify">Enregistrer</div>
        </modale>
    </li>
</template>

<script>
import axios from 'axios'

export default {
    props: ['textObject'],
    data() {
        return {
            showModale: false,
            body : {
                sectionId      : "",
                title          : "",
                text           : "",
                position       : 0,
                last_update    : new Date()
            }
        }
    },
    methods: {
        toggleModale() {
            this.showModale = !this.showModale
        },
        async modify() {
            this.body.last_update = new Date()
            if (!this.body.title) {
                alert("Veuillez renseigner un titre");
            } else if (!this.body.text) {
                alert("Veuillez renseigner un texte");
            } else {
                await axios.post(this.$store.getters.get_api_url +'library/library-section/', this.body, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })
                this.toggleModale()
            }
        }
    },
    mounted() {
        this.body = this.textObject
    }
}
</script>

<style scoped>
.modale-frame {
    padding: 20px;
}
li {
    display: flex;
    justify-content: space-between;
}
input {
    margin: 10px;
    width: 80%;
}

.btn-save {
    cursor: pointer;
    display: inline-block;
    margin: 10px auto;
    padding: 10px 15px;
    background-color: rgb(253, 178, 38);
    font-weight: bold;
}
</style>