<template>
    <div class="liquidity-frame">
        <div class="overlay" @click="toggle" v-if="showTable"></div>

        <div class="indicator-frame">
            <div class="flex-container" @click="toggle">
                <span>Liquidités à réinvestir : </span>
                <div class="liquidity-value">{{ toEuro(liquidities) }}</div>
            </div>

            <table class="action-list" v-if="showTable">
                <tr v-for="(action, index) in $store.getters.getActionList" :key="index">
                    <td>{{ $store.getters.codeTranslations[action.code] }}</td>
                    <td>{{ $store.getters.findOwnerName(action.person) }}</td>
                    <td>{{ toEuro(action.liquidities) }}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            showTable: false
        }
    },
    methods: {
        toggle() {
            this.showTable = !this.showTable
        }
    },
    computed: {
        liquidities() {
            return this.$store.getters.getActionList.map(a => a.liquidities).reduce((sum, current) => sum + current, 0)
        }
    },
    async mounted() {
        const res = await axios.get(this.$store.getters.get_api_url + 'referential/actions/'+ this.$route.params.scenarioId, {
            headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
        })

        this.$store.commit('modifyActionList', res.data)
        this.actions = res.data
    }
}
</script>

<style>
.overlay {
    z-index: 0;
    cursor: pointer;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(126, 125, 125, 0.5);
}
.liquidity-frame {
    z-index: 10;
    position: absolute;
    right: 10px;
    top: 70px;
    padding: 20px 5%;
    display: flex;
    justify-content: right;
}
.indicator-frame {
    position: relative;
    padding: 15px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    -webkit-box-shadow: 0px 0px 10px -3px #C7C7C7; 
    box-shadow: 0px 0px 10px -3px #C7C7C7;
}
.flex-container {
    cursor: pointer;
    display: flex;
    flex-direction: column;
}
.flex-container span {
    font-weight: bold;
    color: black;
    margin: 0 10px;
    font-size: 18px;
}
.liquidity-value {
    padding: 10px;
    min-width: 100px;
    background-color: rgb(243, 243, 243);
    text-align: right;
    border-radius: 5px;
    border: 1px solid lightgrey;
}

.action-list {
    font-size: 14px;
    margin-top: 20px;
    border-collapse: collapse;
}
.action-list td {
    padding: 10px 5px;
    border: 1px solid rgb(243, 245, 247);
}
.action-list tr td:nth-child(1) {
    text-align: left;
}
.action-list tr td:nth-child(2) {
    text-align: center;
}
.action-list tr td:nth-child(3) {
    text-align: center;
}
</style>
