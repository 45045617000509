<template>
    <div>
        <div class="menu">
            <div :class="{'menu-selection': !show_detailed_result}" @click="show_detailed_result=false">Données de simulation</div>
            <div :class="{'menu-selection': show_detailed_result, 'menu-disabled': computationResult == undefined}" @click="computationResult ? show_detailed_result=true : null">Résultat détaillé</div>
        </div>

        <div class="calculator-frame" >
            <div class="left-section" v-if="!show_detailed_result">
                <div class="section-element">
                    <h3>Données de simulation</h3>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Civilité</label>

                            <select v-model="sexe">
                                <option value="homme">Homme</option>
                                <option value="femme">Femme</option>
                            </select>
                        </div>

                        <div class="input-frame">
                            <label>Date de naissance</label>
                            <input type="date" v-model="birthdate">
                        </div>
                    </div>
                </div>

                <div class="section-element btn-section">
                    <div class="btn-send-computation btn-grow" @click="computeResult">Calculer</div>
                </div>
            </div>

            <div class="left-section" v-else>
                <div class="section-element">
                    <h3>Résulat détaillé</h3>

                    <table>
                        <tr>
                            <th>Age de la personne</th>
                            <td>{{ computationResult.age }} ans</td>
                        </tr>

                        <tr>
                            <th>Esperance de vie en nombre d'années</th>
                            <td>{{ computationResult.esperance_de_vie_nb_annee }} années</td>
                        </tr>

                        <tr>
                            <th>Âge d'esperance de vie</th>
                            <td>{{ computationResult.esperance_de_vie_age }} ans</td>
                        </tr>

                        <tr>
                            <th>Année d'esperance de vie</th>
                            <td>{{ computationResult.esperance_de_vie_annee }}</td>
                        </tr>
                    </table>

                    <h6>Source : {{computationResult.source}}</h6>

                    <p v-if="computationResult.message" style="color: red">{{ computationResult.message }}</p>
                </div>
            </div>

            <div class="right-section">
                <div class="section-element" v-if="!computationResult">
                    <h3>Résultats</h3>

                    <img src="../ressources/img/illustration.png"/>

                    <p>Lancez le calcul pour obtenir un résultat</p>
                </div>

                <div class="section-element" v-if="computationResult">
                    <h3>Espérance de vie</h3>
                    <h4>{{ computationResult.esperance_de_vie_age }} ans</h4>
                </div>
            </div>
        </div> 
    </div>
 </template>
 
 <script>
 import axios from 'axios'
 
 export default {
     data() {
         return {
            show_detailed_result: false,

            sexe: 'homme',
            birthdate: '1960-01-01',

            computationResult: undefined,
         }
     },
     computed: {
         requestBody() {
            const birth_year = new Date(this.birthdate).getFullYear();

            let body = {
                annee_de_naissance: birth_year,
                sexe: this.sexe,
            }
 
            return body
         },
     },
     methods: {
         async computeResult() {
            const res = await axios.post(this.$store.getters.get_api_url + 'simulators/life-expectancy/', this.requestBody, {
                 headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            });
 
            this.computationResult = res.data;
         }
     }
 }
 </script>
 
 <style src="./style.css" scoped></style>