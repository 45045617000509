<template>
    <div class="client-list-frame">
        <h1 class="title">Mes clients
            <div class="tooltip">
                <span class="material-symbols-outlined" @click="toggleModale">group_add</span>
                <span class="tooltiptext">Créer un nouveau partenaire</span>
            </div>
        </h1>

        <div class="filters-frame">
            <div class="filters">
                <div class="filter">
                    <label>Cabinet</label>
                    <input type="text" v-model="filters.society">
                </div>

                <div class="filter">
                    <label>Nom</label>
                    <input type="text" v-model="filters.lastname">
                </div>

                <div class="filter">
                    <label>Prénom</label>
                    <input type="text" v-model="filters.firstname">
                </div>

                <div class="filter">
                    <label>Code postal</label>
                    <input type="text" v-model="filters.zipcode">
                </div>

                <div class="filter">
                    <label>Status</label>
                    <select v-model="filters.status">
                        <option v-for="(value, key) in  status_list" :key="key" :value="key">{{ value }}</option>
                    </select>
                </div>

                <div class="filter">
                    <label>Facture des honoraires</label>
                    <select v-model="filters.hasFees">
                        <option v-for="(value, key) in  booleanChoices" :key="key" :value="value">{{ key }}</option>
                    </select>
                </div>

                <div class="filter">
                    <label>Intéressé par</label>
                    <select v-model="filters.interestedBy">
                        <option v-for="(value, key) in  interest_list" :key="key" :value="key">{{ value }}</option>
                    </select>
                </div>
            </div>

            <div class="btns-frame">
                <div class="btn" @click="getClients">Rechercher <span class="material-symbols-outlined">search</span></div>
                <div class="btn" @click="eraseFilters">Effacer les filtres <span class="material-symbols-outlined">filter_alt_off</span></div>
            </div>
        </div>

        <loader-spin v-if="showLoader"></loader-spin>
        <table id="clients-table" v-else>
            <tr>
                <th>Prénom</th>
                <th>Nom</th>
                <th>Société</th>
                <th>Date de création</th>
                <th>Actions</th>
            </tr>

            <tr v-for="client in clients" :key="client._id">
                <td>{{ client.firstname }}</td>
                <td>{{ client.lastname }}</td>
                <td>{{ client.name }}</td>
                <td>{{ dateToString(client.creationDate) }}</td>
                <td>
                    <router-link :to="'/conseillers/crm/' + client._id">
                        <div class="tooltip">
                            <span class="material-symbols-outlined">visibility</span>
                            <span class="tooltiptext">Voir plus</span>
                        </div>    
                    </router-link>
                </td>
            </tr>
        </table>

        <div class="pages-frame">
            <span class="material-symbols-outlined" :class="{'disabled-arrow': start_research_index == 0}" @click="updateSearchIndex('previous')">arrow_back_ios</span>
            <p>{{ start_research_index * 10 }} à {{ Math.min(start_research_index * 10 + 10, nbResult) }} sur {{ nbResult }}</p>
            <span class="material-symbols-outlined" :class="{'disabled-arrow': clients.length < 10}" @click="updateSearchIndex('next')">arrow_forward_ios</span>
        </div>
        
        <modale :show="showModale" width="60%" @toggle="toggleModale">
            <form class="form-add-new">
                <div class="form-section">
                    <label>Nom du cabinet :</label>
                    <input type="text" v-model="society.name">
                </div>

                <div class="form-section">
                    <label>Adresse :</label>
                    <input type="text" v-model="society.adress">
                </div>

                <div class="form-section">
                    <label>Code postal :</label>
                    <input type="text" v-model="society.zipcode">
                </div>
                
                <div class="form-section">
                    <label>Ville :</label>
                    <input type="text" v-model="society.city">
                </div>
                
                <div class="form-section">
                    <label>Prénom du dirigeant :</label>
                    <input type="text" v-model="director.firstname">
                </div>
                
                <div class="form-section">
                    <label>Nom du dirigeant :</label>
                    <input type="text" v-model="director.lastname">
                </div>
                
                <div class="form-section">
                    <label>Téléphone :</label>
                    <input type="text" v-model="director.phone1">
                </div>

                <div class="form-section">
                    <label>Email :</label>
                    <input type="text" v-model="director.email1">
                </div> 
            </form>

            <div class="btn-save" @click="saveNew">Enregistrer</div>
        </modale>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            start_research_index: 0,
            showLoader: false,
            nbResult: 0,
            clients: [],
            showModale: false,
            society: {
                name          : "",
                adress        : "",
                zipcode       : "",
                city          : "",
                creationDate : new Date()
            },
            director: {
                    societyId      : undefined,
                    firstname      : "",
                    lastname       : "",
                    phone1         : "",
                    email1         : "",
            },
            booleanChoices: {
                '--': undefined,
                oui: true,
                non: false
            },
            status_list: {
                1: 'Non contacté',
                2: '1er contact',
                3: 'Commande imminente',
                4: 'Cient',
                5: 'Client récurrent',
                6: 'Non interéssé',
            },
            interest_list: {
                timeSaving: 'Gagner du temps',
                interestedBuyPrice : 'Tarif',
                leaveCompetitor: 'Remplacer un concurrent',
                technicalSupport: 'Support technique',
                coConstruction: 'Co-construction'
            },
            filters: {
                society: undefined,
                firstname: undefined,
                lastame: undefined,
                zipcode: undefined,
                status: undefined,
                hasFees: undefined,
                interestedBy: undefined,
            },
            errMsg: ''
        }
    },
    methods: {
        toggleModale(){
            this.showModale = !this.showModale
        },
        updateSearchIndex(type) {
            if (type == 'next' && this.clients.length == 10) {
                this.start_research_index ++;
            } else if (type == 'previous' && this.start_research_index != 0) {
                this.start_research_index -= 1;
            }
        },
        async saveNew() {
            if (!this.society.name) {
                this.errMsg = "Veuillez renseigner le nom de la société";
                return;
            } else if (!this.society.adress) {
                this.errMsg = "Veuillez renseigner l'adresse de la société";
                return;
            } else if (!this.society.zipcode) {
                this.errMsg = "Veuillez renseigner le code postal de la société";
                return;
            } else if (!this.society.city) {
                this.errMsg = "Veuillez renseigner la ville de la société";
                return;
            } else if (!this.director.firstname) {
                this.errMsg = "Veuillez renseigner le prénom du partenaire";
                return;
            } else if (!this.director.lastname) {
                this.errMsg = "Veuillez renseigner le nom du partenaire";
                return;
            }

            const societyCreation = await axios.post(this.$store.getters.get_api_url +'crm/society/', this.society, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.director.societyId = societyCreation.data
            
            if (societyCreation.status != 201) {
                this.errMsg = 'Erreur création cabinet'
            } else {
                const directorCreation = await axios.post(this.$store.getters.get_api_url +'crm/director/', this.director, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })

                if (directorCreation.status != 201) {
                    this.errMsg = "Erreur création directeur"
                } else {
                    this.getClients()
                    this.toggleModale()
                }
            }
        },
        async getClients() {
            this.showLoader = true;
            const countResponse = await axios.post(this.$store.getters.get_api_url + "crm/society-research?count=true", this.filters, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            this.nbResult = countResponse.data

            const societiesResponse = await axios.post(this.$store.getters.get_api_url + "crm/society-research?start=" + this.start_research_index * 10 + "&max=10", this.filters, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            let societies = societiesResponse.data

            const directorsResponse = await axios.post(this.$store.getters.get_api_url +'crm/get-directors', { societyIds: societies.map(s => s._id) }, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            const directors = directorsResponse.data

            societies.forEach(society => {
                const director = directors.filter(d => d.societyId == society._id)[0]
                society.firstname = director?.firstname
                society.lastname = director?.lastname
            });

            this.clients = societies
            this.showLoader = false;
        },
        eraseFilters() {
            this.start_research_index = 0
            this.filters = {
                society: undefined,
                firstname: undefined,
                lastame: undefined,
                zipcode: undefined,
                status: undefined,
                hasFees: undefined,
                interestedBy: undefined,
            }

            this.getClients()
        },
    },
    computed: {
        pagesIndex() {
            let pages = []
            let index = 1
            for (let i = 0; i < this.nbResult; i = i + 10) {
                pages.push(index)
                index ++
            }

            return pages
        }
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                this.start_research_index = 0
            }
        },
        start_research_index() {
            this.getClients()
        },
        errMsg() {
            alert(this.errMsg)
        }
    },
    async mounted() {
        this.getClients()
    }
}
</script>

<style scoped>
.client-list-frame {
    margin: 20px auto;
    width: 1000px;
    position: relative;
}
.client-list-frame h1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.client-list-frame h1 span {
    cursor: pointer;
    font-size: 50px;
}
.client-list-frame h1 span:hover {
    color: orange;
}

.filters-frame {
    display: flex;
    align-items: center;
}
.filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.filter {
    margin: 3px;
    display: flex;
    flex-direction: column;
}
.filter label, 
.filter input, 
.filter select {
    font-size: 16px;
    padding: 3px;
}
.filter label {
    font-weight: bold;
}
.filter input, 
.filter select {
    border: 1px solid lightgrey;
    padding: 5px;
    border-radius: 5px;
}

.btns-frame {
    display: flex;
    flex-direction: column;
}
.btns-frame .btn {
    cursor: pointer;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    margin: 5px;
    background-color: rgba(0, 0, 83, 0.372);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.btns-frame .btn:hover {
    background-color: rgb(5, 0, 99);
    color: white;
}

/* Tableau des partenaires */
#clients-table {
    margin-top: 20px;
    width: 100%;
    border-collapse: collapse;
}

#clients-table th, 
#clients-table td {
    border: 1px solid rgb(243, 245, 247);
    padding: 10px;
    font-size: 14px;
}
#clients-table th {
    background-color: rgba(30,44,88, 0.8);
    color: white;
}
#clients-table th:first-child {
    border-top-left-radius: 15px;
}
#clients-table th:last-child {
    border-top-right-radius: 15px;
}
#clients-table td {
    background-color: white;
}
#clients-table tr:last-child td:first-child {
    border-bottom-left-radius: 15px;
}
#clients-table tr:last-child td:last-child {
    border-bottom-right-radius: 15px;
}
#clients-table tr td:nth-child(4),
#clients-table tr td:nth-child(5) {
    text-align: center;
}
#clients-table tr td:nth-child(5) a {
    color: black;
}
#clients-table tr td:nth-child(5) a:hover {
    color: orange;
}

/* Menu de navigation dans les pages de recherche à afficher */
.pages-frame {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pages-frame span:first-child:hover,
.pages-frame span:last-child:hover {
    cursor: pointer;
    color: orange;
}
.pages-frame span:nth-child(2) {
    cursor: default;
    border: 2px solid lightgrey;
    border-radius: 5px;
    width: 20px;
    height: 20px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}
.disabled-arrow {
    color: lightgrey;
}

/* Formulaire de création */
.form-add-new {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.form-add-new .form-section {
    flex-basis: 45%;
    margin: 2px;
    display: flex;
    flex-direction: column;
}

.form-add-new label, 
.form-add-new input,
.form-add-new select {
    padding: 5px;
    font-size: 14px;
}
.form-add-new label {
    font-weight: bold;
}
.form-add-new input,
.form-add-new select {
    border: 1px solid lightgrey;
    text-align: left;
    border-radius: 5px;
}
.btn-save {
    cursor: pointer;
    padding: 10px;
    text-align: center;
    width: 100px;
    margin: 10px auto;
    background-color: rgba(0, 0, 89, 0.447);
    color: white;
    border-radius: 5px;
}
.btn-save:hover {
    background-color: rgb(0, 0, 89);
}

/* tooltips */
.tooltip {
    position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden; /* Masqué par défaut */
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 2px 5px;
  border-radius: 2px;
  font-size: 12px;
  min-width: 130px;

  /* Positionnement */
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Place le tooltip au-dessus du bouton */
  left: 50%;
  transform: translateX(-50%);
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>