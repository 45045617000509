<template>
    <div class="container">
        <div class="side-left">
            <Incomes/>
        </div>

        <div class="side-right">
            <TaxReductions/>
            <DeductibleExpenses/>
        </div>
    </div>
</template>

<script>
import Incomes from '../sections/Incomes.vue'
import TaxReductions from '../sections/TaxReductions.vue'
import DeductibleExpenses from '../sections/DeductibleExpenses.vue'

export default {
    components: {
        Incomes,
        TaxReductions,
        DeductibleExpenses,
    },
}
</script>

<style scoped src="./style.css"></style>