<template>
    <div class="rh-table">
        <div class="category-title">{{ title }}</div>
        <p class="description">{{ description }}</p>

        <table>
            <tr>
                <th>Mission</th>
                <th>IP</th>
                <th>Nombre heures</th>
                <th>Date</th>
                <th>Actions</th>
            </tr>

            <tr v-for="demand in demand_list" :key="demand._id">
                <td>
                    <div class="tooltip">
                        <span>{{ categories[demand.category] }}</span>
                        <span class="tooltiptext">{{ demand.label }}</span>
                    </div>
                </td>
                <td>{{ demand.employee?.firstname }} {{ demand.employee?.lastname }}</td>
                <td>{{ demand?.nb_hours }} heures</td>
                <td>{{ dateToString(demand?.date) }}</td>
                <td class="actions-td">
                    <div class="btn-container">
                        <div class="tooltip" v-if="!demand.is_confirmed && !is_waiting">
                            <span @click="confirmData(demand._id)" class="material-symbols-outlined confirm-btn">check_circle</span>
                            <span class="tooltiptext">Valider la mission</span>
                        </div>

                        <div class="tooltip" v-if="!demand.is_confirmed && !is_waiting">
                            <span @click="cancelData(demand._id)" class="material-symbols-outlined cancel-btn">cancel</span>
                            <span class="tooltiptext">Refuser la mission</span>
                        </div>

                        <div class="tooltip" v-if="!demand.is_confirmed && !is_waiting">
                            <span class="material-symbols-outlined edit-btn" @click="toggleModificationModale(demand._id)">edit_square</span>
                            <span class="tooltiptext">Modifier</span>

                            <modale :show="demandToModify == demand._id" @toggle="toggleModificationModale(demand._id)">
                                <DemandForm
                                    :categories="categories"
                                    @demandCreated="demandModifiedEmit"
                                    :demandData="demand"
                                />
                            </modale>
                        </div>
                        
                        <div class="tooltip" v-if="is_waiting">
                            <span class="material-symbols-outlined await-btn" >hourglass_pause</span>
                            <span class="tooltiptext">En attente de confirmation</span>
                        </div>

                        <div class="tooltip" v-if="demand.status == 'confirmed'">
                            <span class="material-symbols-outlined confirmed-icon" >done_all</span>
                            <span class="tooltiptext">Validé par {{ demand.manager?.firstname }} {{ demand.manager?.lastname }}</span>
                        </div>

                        <div class="tooltip" v-if="demand.status == 'cancelled'">
                            <span class="material-symbols-outlined refused-icon">person_cancel</span>
                            <span class="tooltiptext">Refusé par {{ demand.manager?.firstname }} {{ demand.manager?.lastname }}</span>
                        </div>

                        <div class="tooltip" v-if="is_waiting">
                            <span class="material-symbols-outlined delete-btn" @click="deleteDemand(demand._id)">delete_forever</span>
                            <span class="tooltiptext">Supprimer</span>
                        </div>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import axios from 'axios';
import DemandForm from './DemandForm.vue';

export default {
    components: {
        DemandForm
    },
    props: {
        categories: {
            type: Object,
            required: true
        },
        title : {
            type: String,
            required: true
        },
        demand_list : {
            type: Array,
            required: true
        },
        description : {
            type: String,
            required: false
        },
        is_waiting : {
            type: Boolean,
            required: false
        },
    },
    data() {
        return {
            demandToModify: undefined,
        }
    },
    methods: {
        async confirmData(demandId) {
            await axios.put(this.$store.getters.get_api_url + 'rh-management/confirm-demand/' + demandId, {}, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.$emit('confirmation')
        },
        async cancelData(demandId) {
            await axios.put(this.$store.getters.get_api_url + 'rh-management/cancel-demand/' + demandId, {}, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            
            this.$emit('confirmation')
        },
        auth_confirmation(employee_position) {
            const position = this.$store.getters.getAdminPosition
            if (position == 'director' || position == 'president') {
                return true
            } else if (position == 'engineer2' && employee_position == 'engineer') {
                return true
            } else if (position == 'engineer3' && ['engineer2', 'engineer'].includes(employee_position)) {
                return true
            } else {
                return false
            }
        },
        async deleteDemand(demandId) {
            await axios.delete(this.$store.getters.get_api_url + 'rh-management/demand/' + demandId, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            });

            this.$emit('confirmation')
        },
        toggleModificationModale(demandId) {
            if (this.demandToModify == demandId) {
                this.demandToModify = undefined;
            } else {
                this.demandToModify = demandId;
            }
        },
        demandModifiedEmit() {
            this.$emit('confirmation');
            this.demandToModify = undefined;
        }
    }
}
</script>

<style scoped>

.rh-table {
    flex-basis: 48%;
    padding: 20px 0;
    position: relative;
}
.category-title {
    font-size: 28px;
    font-weight: bold;
    color: rgb(0, 0, 83);
}
.separator {
    height: 1px;
    margin: 10px 0;
    background-color: lightgrey;
}

table {
    width: 100%;
    margin: 0px auto;
    border-collapse: collapse;
    table-layout: fixed;
}
td, th {
    font-size: 12px;
    border: 1px solid lightgrey;
}
th {
    padding: 5px;
    background-color: rgba(0, 0, 73, 0.225);
    color: black;
}

td {
    padding: 5px;
    background-color: white;
}

.actions-td {
    text-align: center;
}

.btn-container {
    display: flex;
    justify-content: center;
}

.confirm-btn {
    cursor: pointer;
    color: green;
}
.confirm-btn:hover {
    color: lightgreen;
}

.cancel-btn {
    cursor: pointer;
    color: red;
}
.cancel-btn:hover {
    color: lightcoral;
}

.edit-btn {
    cursor: pointer;
    color: orange;
}
.edit-btn:hover {
    color: rgb(252, 186, 62);
}

.await-btn {
    cursor: default;
    color: orange;
}

.delete-btn {
    cursor: pointer;
    color: red;
}
.delete-btn:hover {
    color: lightcoral;
}

.confirmed-icon {
    cursor: default;
    color: green;
}

.refused-icon {
    cursor: default;
    color: red;
}

.tooltip {
    position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden; /* Masqué par défaut */
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 2px 5px;
  border-radius: 2px;
  font-size: 8px;

  /* Positionnement */
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Place le tooltip au-dessus du bouton */
  left: 50%;
  transform: translateX(-50%);
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

</style>