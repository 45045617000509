<template>
    <div class="add-btn" @click="toggleModale">
        <span class="material-symbols-outlined">docs_add_on</span>
    </div>
    
    <overlay :show="showModale" @toggle="toggleModale">
        <Person v-if="type == 'person'" :role="category" @closeModale="toggleModale"/>
        <Property v-if="type == 'property'" @closeModale="toggleModale"/>
        <Asset v-if="type == 'asset'" @closeModale="toggleModale"/>
        <Society v-if="type == 'society'" @closeModale="toggleModale"/>
        <Movable v-if="type == 'movable'" @closeModale="toggleModale"/>
        <Insurance v-if="type == 'insurance'" @closeModale="toggleModale"/>
        <Gift v-if="type == 'gift'" @closeModale="toggleModale"/>
        <Debt v-if="type == 'debt'" @closeModale="toggleModale"/>
        <Will v-if="type == 'will'" @closeModale="toggleModale"/>
        <DeductibleExpense v-if="type == 'deductible_expense'" @closeModale="toggleModale"/>
        <TaxReduction v-if="type == 'tax_reduction'" @closeModale="toggleModale"/>
        <Income v-if="type == 'income'" @closeModale="toggleModale"/>
        <Reward v-if="type == 'reward'" @closeModale="toggleModale"/>
    </overlay>
</template>

<script>
import Person from '../forms/Person.vue';
import Property from '../forms/Property.vue';
import Asset from '../forms/Asset.vue';
import Society from '../forms/Society.vue';
import Movable from '../forms/Movable.vue';
import Insurance from '../forms/Insurance.vue';
import Gift from '../forms/Gift.vue';
import Debt from '../forms/Debt.vue';
import Will from '../forms/Will.vue';
import DeductibleExpense from '../forms/DeductibleExpense.vue';
import TaxReduction from '../forms/TaxReduction.vue';
import Income from '../forms/Income.vue';
import Reward from '../forms/Rewards.vue';

export default {
    props: ['type', 'category'],
    components: {
        Person,
        Property,
        Asset,
        Society,
        Movable,
        Insurance,
        Gift,
        Debt,
        Will,
        DeductibleExpense,
        TaxReduction,
        Income,
        Reward,
    },
    data() {
        return {
            showModale: false
        }
    },
    methods: {
        toggleModale() {
            this.showModale = !this.showModale
        }
    },
}
</script>

<style scoped>
.add-btn {
    cursor: pointer;
    color: rgb(0, 0, 76);
}
.add-btn:hover {
    color: orange;
}
</style>