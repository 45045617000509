<template>
    <form class="add-form">
        <div class="form-section">
            <label for="category">Catégorie</label>
            <select id="category" v-model="body.type">
                <option v-for="(value, key) in $store.getters.incomeTypes" :key="key" :value="key">{{ value }}</option>
            </select>
        </div>

        <div class="form-section" v-if="['dividend', 'art62', 'salary', 'bic1', 'bic2', 'bnc', 'ba'].includes(body.type) && $store.getters.numberSocieties > 0">
            <label for="owner">Société liée</label>
            <select id="owner" v-model="body.linked_society">
                <option v-for="society in $store.getters.getAllSocieties" :key="society._id" :value="society._id">{{ $store.getters.societyTypes[society.type] }} - {{ society.label }}</option>
            </select>
        </div>

        <div class="form-section" v-if="body.linked_society">
            <label for="category">Poste</label>
            <select id="category" v-model="body.job_status">
                <option v-for="(value, key) in job_status_list" :key="key" :value="key">{{ value }}</option>
            </select>
        </div>

        <div class="form-section">
            <label for="category">Intitulé du poste</label>
            <input type="text" v-model="body.job_label">
        </div>

        <div class="form-section">
            <label for="value">{{ formValueLabel }}</label>
            <currency-input v-model="body.net_value"/>
        </div>

        <div class="form-section">
            <label for="owner">Propriétaire</label>
            <select id="owner" v-model="body.person_id">
                <option v-for="(value, index) in $store.getters.findUsers" :key="index" :value="value">{{ $store.getters.findOwnerName(value) }}</option>
            </select>
        </div>

        <div class="form-section" v-if="['property_income', 'lmnp', 'lmp'].includes(body.type)">
            <label for="owner">Bien immobilier lié</label>
            <select id="owner" v-model="body.linked_property">
                <option v-for="property in $store.getters.getAllProperties" :key="property._id" :value="property._id">{{ $store.getters.getPropertyLabel(property._id) }}</option>
            </select>
        </div>

        <div class="form-section" v-if="['financial_income'].includes(body.type)">
            <label for="owner">Actif liée</label>
            <select id="owner" v-model="body.linked_asset">
                <option v-for="asset in $store.getters.getAllAssets" :key="asset._id" :value="asset._id">{{ $store.getters.assetTypesList[asset.type] }} - {{ $store.getters.getAssetOwner(asset.detention, asset.isIndivision) }} - {{ toEuro(asset.value) }}</option>
            </select>
        </div>

        <div class="swich-form-section" v-if="!['alimony', 'financial_income'].includes(body.type)">
            <label for="owner">{{ formMicroLabel }}</label>
            <switch-ux :value="body.isMicro" @toggle="newValue => body.isMicro = newValue"/>
        </div>

        <div class="form-section" v-if="!['financial_income', 'dividend', 'alimony'].includes(body.type) && !body.isMicro">
            <label for="value">Montant des charges réelles</label>
            <currency-input v-model="body.charges"/>
        </div>

        <div class="btn-submit grow-effect" @click="submit">Enregistrer</div>
        <p class="errorMsg">{{ errorMsg }}</p>
    </form>
</template>

<script>
export default {
    props: ['import'],
    data() {
        return {
            isNew: true,
            errorMsg: "",
            body: {
                _id: undefined,
                clientId: undefined,
                scenarioId: undefined,
                type: "salary",
                job_status: "salary",
                job_label: "",
                net_value: 0,
                person_id: undefined,
                isMicro: true,
                charges: 0,
                linked_property: undefined,
                linked_society: undefined,
                linked_asset: undefined,
                isIpCreation: false
            },
        }
    },
    watch: {
        'body.type': {
            handler() {
                if (['retirement', 'alimony'].includes(this.body.type)) {
                    this.body.linked_property = undefined
                    this.body.linked_society = undefined
                    this.body.linked_asset = undefined

                } else if (['property_income', 'lmnp', 'lmp'].includes(this.body.type)) {
                    this.body.linked_society = undefined
                    this.body.linked_asset = undefined

                } else if (['dividend', 'art62', 'salary', 'bic1', 'bic2', 'bnc', 'ba'].includes(this.body.type)) {
                    this.body.linked_property = undefined
                    this.body.linked_asset = undefined

                } else if (['financial_income'].includes(this.body.type)) {
                    this.body.linked_property = undefined
                    this.body.linked_society = undefined
                }
            }
        },
        'body.linked_property': {
            handler() {
                if (this.body.linked_property != undefined) {
                    this.body.linked_society = undefined
                    this.body.linked_asset = undefined
                }
            }
        },
        'body.linked_society': {
            handler() {
                if (this.body.linked_society != undefined) {
                    this.body.linked_property = undefined
                    this.body.linked_asset = undefined
                }
            }
        },
        'body.linked_asset': {
            handler() {
                if (this.body.linked_asset != undefined) {
                    this.body.linked_property = undefined
                    this.body.linked_society = undefined
                }
            }
        },
    },
    methods: {
        submit() {
            if (this.isOriginalScenario) {
                this.body.isIpCreation = false
            } else if (this.isNew) {
                this.body.isIpCreation = true
            }
            
            if (this.body.type == undefined) {
                this.errorMsg = "Précisez le type de revenus"

            } else if (this.body.person_id == undefined) {
                this.errorMsg = "Précisez le propriétaire des revenus"

            } else {
                this.errorMsg = ""

                if (this.isNew) {
                    this.body.addToStore = true
                    this.$store.dispatch('create_income', this.body)

                } else {
                    this.$store.dispatch('modify_income', this.body)
                }  

                this.$emit('closeModale')
            }
        },
    },
    computed: {
        isOriginalScenario() {
            const routeArray = this.$route.fullPath.split('/')

            if (routeArray[3] == 'profile' || this.$route.query.isOriginal) {
                return true
            } else {
                return false
            }
        },
        formValueLabel() {
            const TRAD = {
                art62: 'Montant net imposable',
                salary: 'Montant net imposable',
                lmnp: 'Montant brut de charges',
                lmp: 'Montant brut de charges',
                bic1: 'Montant brut de charges',
                bic2: 'Montant brut de charges',
                bnc: 'Montant brut de charges',
                ba: 'Montant brut de charges',
                retirement: 'Montant net imposable',
                property_income: 'Montant brut de charges',
                dividend: "Dividendes reçus",
                financial_income: 'Montant net imposable',
                alimony: 'Montant net imposable',
            }

            let label = TRAD[this.body.type]

            if (!label) {
                // Valeur par défaut
                label = 'Montant net imposable'
            }

            return label
        },
        formMicroLabel() {
            const TRAD = {
                art62: 'Charges forfaitaires (10%)',
                salary: 'Charges forfaitaires (10%)',
                lmnp: 'Régime micro-BIC',
                lmp: 'Régime micro-BIC',
                bic1: 'Régime micro-BIC',
                bic2: 'Régime micro-BIC',
                bnc: 'Régime micro-BNC',
                ba: 'Régime micro-BA',
                retirement: 'Charges forfaitaires (10%)',
                property_income: 'Régime micro-foncier (30%)',
                financial_income: 'Montant net imposable',
                dividend: "Éligible abattement 40%",
            }

            let label = TRAD[this.body.type]
            
            if (!label) {
                // Valeur par défaut
                label = 'Frais forfaitaires'
            }

            return label
        },
        job_status_list() {
            const society = this.$store.getters.getAllSocieties.find(s => s._id == this.body.linked_society);
            
            if (society) {
                return this.$store.getters.jobStatus[society.type];
            } else {
                return [];
            }
        }
    },
    mounted() {
        if (this.import) {
            this.isNew = false
            this.body = JSON.parse(JSON.stringify(this.import))
        } 

        if (this.isNew) {
            this.body.clientId = this.$route.params.clientId
            this.body.scenarioId = this.$route.params.scenarioId
        }
    }
}
</script>


<style src="./style.css" scoped></style>