<template>
    <div id="navbar">
        <img src="../assets/logo-horizontal-blanc.png" alt="logo">

        <div class="menu" v-if="$store.getters.get_token">
            <router-link to="/client">Client</router-link>
            <router-link to="/gestion">Gestion</router-link>
            <router-link to="/calculettes">Simulateurs</router-link>
            <div class="burger-menu-container">
                <span class="material-symbols-outlined burger-btn">menu</span>

                <div class="burger-menu">
                    <router-link to="/librairie">LBF Librairie</router-link>
                    <router-link to="/referential/">Référentiel</router-link>
                    <router-link to="/conseillers/crm">Conseillers</router-link>
                    <router-link to="/administrateurs" v-if="['director', 'president'].includes($store.getters.getAdminPosition)">Administrateurs</router-link>
                </div>
            </div>
            
        </div>

        <div class="administrator" @click="toggleParameters" v-if="$store.getters.get_token">
            {{ $store.getters.getAdminInitials }}
        </div>
        <div class="overlay" v-if="showParameters" @click="toggleParameters"></div>
        <div class="parameters" v-if="showParameters">
            <h3 @click="togglePassword">Gestion du mot de passe</h3>
            <h3 @click="logout">Deconnexion</h3>
        </div>

        <modale :show="showPassword" @toggle="togglePassword">
            <form class="password-modification">
                <section>
                    <label>Nouveau mot de passe</label>
                    <input type="password" v-model="password1">
                </section>

                <section>
                    <label>Confirmation du mot de passe</label>
                    <input type="password" v-model="password2">
                </section>

                <div class="save-btn" @click="updatePassword">Enregistrer</div>
            </form>

            <p>{{errorMsg}}</p>
        </modale>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data(){
        return {
            showParameters: false,
            showPassword: false,
            password1: "",
            password2: "",
            errorMsg: "",
        }
    },
    methods: {
        toggleParameters() {
            this.showParameters = !this.showParameters
        },
        togglePassword() {
            this.showPassword = !this.showPassword
            if (this.showPassword) {
                this.showParameters = false
            }
        },
        logout() {
            this.$store.commit('setAuth', {
                token: null, 
                adminId: null,
            })

            localStorage.removeItem('token')
            localStorage.removeItem('adminId')
            this.showParameters = false
            this.$router.replace('/connexion')
        },
        async updatePassword() {

            if (this.password1 == this.password2) {
                const body = {
                    password: this.password1,
                    _id: this.$store.getters.getAdminId
                }

                await axios.put(this.$store.getters.get_api_url + 'admins/modify-password/', body, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })

                this.showPassword = false
            } else {
                this.errorMsg = "Les deux mots de passe ne sont pas identiques. "
            }
            
        }
    }
}
</script>

<style scoped>
#navbar {
    background-color: #0a1c6b;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

img {
    height: 40px;
    margin-left: 5%;
}

.menu {
    display: flex;
    justify-content: right;
    align-items: center;
    flex-basis: 100%;
}

.menu a {
    color: white;
    font-size: 18px;
    text-decoration: none;
    margin: 0 20px;
}
.menu a:hover {
    color: orange;
}

.menu .router-link-active {
    color: rgb(253, 165, 1);
}

.burger-menu-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.burger-menu-container .burger-btn {
    cursor: pointer;
    color: white;
    font-size: 30px;
}
.burger-menu-container .burger-btn:hover {
    color: orange;
}
.burger-menu-container:hover .burger-menu,
.burger-menu-container .burger-menu:hover {
    visibility: visible;
}
.burger-menu-container .burger-menu {
    visibility: hidden;
    z-index: 1000;
    position: absolute;
    left: 0;
    top: 30px;
    display: flex;
    flex-direction: column;
}
.burger-menu-container .burger-menu a {
    cursor: pointer;
    padding: 10px;
    font-size: 15px;
    color: white;
    background-color: #0a1c6ba5
}
.burger-menu-container .burger-menu a:hover {
    background-color: orange;
    color: white;
}
.burger-menu-container .burger-menu .router-link-active {
    color: rgb(253, 165, 1);
}

.administrator {
    cursor: pointer;
    margin-right: 20px;
    color: black;
    font-weight: bold;
    background-color: white;
    padding: 15px;
    border-radius: 50%;
}

.overlay {
    z-index: 100;
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(211, 211, 211, 0.614);
}
.parameters {
    z-index: 10001;
    position: absolute;
    top: 0px;
    right: 0px;
    min-height: 200px;
    background-color: white;
}
.parameters h3 {
    cursor: pointer;
    padding: 20px;
    min-width: 150px;
}
.parameters h3:hover {
    background-color: rgba(255, 166, 0, 0.478);
}

.password-modification {
    font-size: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
}
.password-modification section {
    display: flex;
    flex-direction: column;
}

.save-btn {
    cursor: pointer;
    margin: 10px auto;
    padding: 15px;
    border-radius: 50px;
    font-size: 18px;
    font-weight: bold;
    background-color: orange;
}

@media screen and (max-width: 800px) {
    #navbar {
        align-items: flex-start;
        flex-direction: column;
    }

    img {
        align-self: center;
        height: 20px;
    }

    .administrator {
        cursor: pointer;
        position: absolute;
        right: -5px;
        padding: 10px;
        font-size: 10px;
    }

    .menu a {
        font-size: 10px;
        margin: 5px 10px;
    }
}
</style>