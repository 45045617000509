<template>
   <div>
        <div class="menu">
            <div :class="{'menu-selection': !show_detailed_result}" @click="show_detailed_result=false">Données de simulation</div>
            <div :class="{'menu-selection': show_detailed_result, 'menu-disabled': computationResult == undefined}" @click="computationResult ? show_detailed_result=true : null">Résultat détaillé</div>
        </div>

        <div class="calculator-frame">
            <div class="left-section" v-if="!show_detailed_result">
                <div class="section-element">
                    <h3>Acquisition du bien</h3>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Type de biens</label>

                            <select v-model="body.property_type">
                                <option v-for="(value, key) in asset_types" :key="key" :value="key">{{ value }}</option>
                            </select>
                        </div>

                        <div class="input-frame">
                            <label>Mode d'acquisition</label>
                            <select v-model="body.is_bought">
                                <option :value="true">Acquisition à titre onéreux</option>
                                <option :value="false">Acquisition à titre gratuit</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Prix d'acquisition</label>
                            <currency-input v-model="body.initial_value"/>
                        </div>

                        <div class="input-frame">
                            <label>Date d'acquisition</label>
                            <input type="date" v-model="body.acquisition_date">
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Frais d'acquisition (agence, notaire etc...)</label>
                            <currency-input v-model="body.acquisition_fees"/>
                        </div>
                    </div>
                </div>

                <div class="section-element">
                    <h3>Conservation du bien</h3>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Montant des travaux réalisés (avec justificatifs)</label>
                            <currency-input v-model="body.work_value"/>
                        </div>

                        <div class="input-frame" v-if="body.property_type == 'location_lmnp'">
                            <label>Valeur des amortissements déduits du revenu imposable</label>
                            <currency-input v-model="body.amortization"/>
                        </div>
                    </div>
                </div>

                <div class="section-element">
                    <h3>Vente du bien</h3>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Prix de vente</label>
                            <currency-input v-model="body.current_value"/>
                        </div>

                        <div class="input-frame">
                            <label>Date de vente du bien</label>
                            <input type="date" v-model="body.selling_date">
                        </div>
                    </div>
                </div>

                <div class="section-element btn-section">
                    <div class="btn-send-computation btn-grow" @click="computeResult">Calculer</div>
                </div>
            </div>

            <div class="left-section" v-else>
                <div class="section-element">
                    <h3>Résultat détaillé</h3>

                    <table>
                        <tr>
                            <th>Prix de vente</th>
                            <td>{{ toEuro(computationResult.plus_value_realisee.prix_vente_net) }}</td>
                            <td></td>
                        </tr>

                        <tr>
                            <th>Prix d'achat</th>
                            <td>-{{ toEuro(body.initial_value) }}</td>
                            <td></td>
                        </tr>

                        <tr>
                            <th>Frais d'acquisition</th>
                            <td>-{{ toEuro(computationResult.frais_acquisition.frais_deductibles) }}</td>
                            <td>
                                <span v-if="computationResult.frais_acquisition.application_forfait">Les frais d'acquisition forfaitaires de 7,5% sont appliqués.</span>
                            </td>
                        </tr>

                        <tr>
                            <th>Travaux déductibles</th>
                            <td>-{{ toEuro(computationResult.travaux_deductibles.montant_deductible) }}</td>
                            <td>
                                <span v-if="computationResult.travaux_deductibles.application_forfait">Le forfait travaux de 15% est appliqué.</span>
                            </td>
                        </tr>

                        <tr v-if="body.property_type == 'location_lmnp'">
                            <th>Amortissements déduits</th>
                            <td>+{{ toEuro(body.amortization) }}</td>
                            <td></td>
                        </tr>

                        <tr class="highlight">
                            <th>Plus-value réalisée</th>
                            <td>={{ toEuro(computationResult.plus_value_realisee.plus_value_realisee) }}</td>
                            <td></td>
                        </tr>

                        <tr>
                            <th>Assiette de l'impôt sur le revenu</th>
                            <td>{{ toEuro(computationResult.impot_sur_revenu.assiette) }}</td>
                            <td>Exonération de {{ Math.round(10000 * computationResult.impot_sur_revenu.exoneration_duree_detention) / 100}} % après {{ computationResult.duree_detention.duree_detention }} ans</td>
                        </tr>

                        <tr>
                            <th>Impôt sur le revenu</th>
                            <td>{{ toEuro(computationResult.impot_sur_revenu.impot_revenu) }} </td>
                            <td>Taux forfaitaire de 19%</td>
                        </tr>

                        <tr v-if="computationResult.impot_sur_revenu.surtaxe.surtaxe > 0">
                            <th class="result-title">Surtaxe</th>
                            <td>{{ toEuro(computationResult.impot_sur_revenu.surtaxe.surtaxe) }}</td>
                            <td>Une surtaxe est appliquée aux plus-values supérieures à 50.000€.</td>
                        </tr>

                        <tr>
                            <th>Assiette des prélèvements sociaux</th>
                            <td>{{ toEuro(computationResult.prelevements_sociaux.assiette) }}</td>
                            <td>Exonération de {{ Math.round(10000 * computationResult.prelevements_sociaux.exoneration_duree_detention) / 100}} % après {{ computationResult.duree_detention.duree_detention }} ans</td>
                        </tr>

                        <tr>
                            <th>Prélèvements sociaux</th>
                            <td>{{ toEuro(computationResult.prelevements_sociaux.prelevements_sociaux) }}</td>
                            <td>Taux forfaitaire de 17,20%</td>
                        </tr>

                        <tr class="highlight">
                            <th class="result-title">Total à payer</th>
                            <td>{{ toEuro(computationResult.prelèvement_total) }}</td>
                            <td>D'autres impôts globaux comme la CEHR ou la CDHR peuvent s'appliquer pour certains contribuables</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="right-section">
                <div class="section-element" v-if="!computationResult">
                    <h3>Résultats</h3>

                    <img src="../ressources/img/illustration.png"/>

                    <p>Lancez le calcul pour obtenir un résultat</p>
                </div>

                <div class="section-element" v-if="computationResult">
                    <h3>Impôt à payer</h3>
                    <h4>{{ toEuro(computationResult.prelèvement_total) }}</h4>
                </div>

                <div class="section-element" v-if="computationResult">
                    <h3>Taux de prélèvement</h3>
                    <h4>{{ toPercent(computationResult.taux_de_prelevements) }}</h4>
                </div>
            </div>
        </div> 
   </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            show_detailed_result: false,
            asset_types: {
                residence_principale: "Résidence principale",
                location_nue: "Bien en location nue",
                location_lmnp: "Bien en LMNP",
            },
            body: {
                acquisition_date : undefined,
                property_type    : "location_nue",
                is_bought        : true,
                selling_date     : undefined,
                initial_value    : 100000,
                acquisition_fees : 0,
                work_value       : 0,
                current_value    : 150000,
                selling_fees     : 0,
                amortization     : 0,
            },
            computationResult: undefined,
        }
    },
    watch: {
        'body.property_type': {
            handler() {
                if (this.body.property_type == 'main_property') {
                    this.body.is_main_property = true
                } else {
                    this.body.is_main_property = false
                }
            }
        },
    },
    computed: {
        simulatorPayload() {
            return {
                date_acquisition: this.body.acquisition_date,
                date_vente: this.body.selling_date,
                valeur_acquisition: this.body.initial_value,
                est_achat : this.body.is_bought,
                frais_acquisition: this.body.acquisition_fees,
                travaux_deductibles: this.body.work_value,
                amortissements: this.body.amortization,
                prix_vente: this.body.current_value,
                frais_vente: this.body.selling_fees,
                type_bien_immobilier: this.body.property_type,
            }
        }
    },
    methods: {
        async computeResult() {
            const res = await axios.post(this.$store.getters.get_api_url + 'simulators/real-estate-gain', this.simulatorPayload, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.computationResult = res.data
        }
    }
}
</script>

<style src="./style.css" scoped></style>