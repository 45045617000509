<template>
    <div class="form-wrapper">
        <div class="form-box">
            <h4>Informations générales</h4>

            <div class="form-container">

                <div class="form-section">
                    <label for="label">Label</label>
                    <input id="label" type="text" v-model="body.label"/>
                </div>

                <div class="form-section">
                    <label for="type">Type de bien<span class="mandadory">*</span></label>
                    <select id="type" v-model="body.type" :disabled="!isOriginalScenario && !isNew">
                        <option v-for="(value, key) in $store.getters.movableTypes" :key="key" :value="key">{{ value }}</option>
                    </select>
                </div>

                <div class="form-section">
                    <label>Valeur du bien<span class="mandadory">*</span></label>
                    <currency-input v-model="body.value" :disabled="!isNew"/>
                </div>
            

                <div class="form-section form-section-full">
                    <label for="detention">
                        Détention complexe<span class="mandadory">*</span>
                        <span class="info-tooltip">
                            <span class="material-symbols-outlined">help</span>
                            <span class="tooltip-text">Cette option permet de saisir des situations de détention complexes comme des indivisions ou des quote-parts de démembrement.</span>
                        </span>
                    </label>
                    
                    <select id="owner" v-model="body.isIndivision">
                        <option :value="true">OUI</option>
                        <option :value="false">NON</option>
                    </select>
                </div>

                <div class="form-section" v-if="!body.isIndivision">
                    <label for="owner">Propriétaire<span class="mandadory">*</span></label>
                    <select id="owner" v-model="owner">
                        <option v-for="(value, index) in $store.getters.findOwners" :key="index" :value="value">{{ $store.getters.findOwnerName(value) }}</option>
                    </select>
                </div>

                <div class="form-section" v-if="!body.isIndivision">
                    <label for="rights">Droits<span class="mandadory">*</span></label>
                    <select id="rights" v-model="right" :disabled="!isOriginalScenario && !isNew">
                        <option v-for="(value, key) in $store.getters.rights" :key="key" :value="key">{{ value }}</option>
                    </select>
                </div>

                <detention-parts :detention="body.detention" @updated="updateDetention" v-if="body.isIndivision"/>
            </div>
        </div>

        <div class="btn-container">
            <div class="btn-submit" @click="submit">Enregistrer</div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['import'],
    data() {
        return {
            isNew: true,
            owner: undefined,
            right: 'pp',
            errorMsg: "",
            value_update: 0,
            body: {
                _id: undefined,
                clientId: undefined,
                scenarioId: undefined,
                type: 'voiture',
                label: '',
                value: 0,
                isIndivision: false,
                detention: [],
                isIpCreation: false,
            },
        }
    },
    watch: {
        owner() {
            this.body.detention = []

            if (this.owner == "commun") {
                this.body.detention.push({ owner_id : this.$store.getters.userId, right : this.right, part : 0.5 })
                this.body.detention.push({ owner_id : this.$store.getters.spouseId, right : this.right, part : 0.5 })
            } else {
                this.body.detention.push({ owner_id : this.owner, right : this.right, part : 1 })
            }
        },
        right() {
            let new_detention = []
            this.body.detention.forEach(part => {
                new_detention.push({
                    owner_id: part.owner_id,
                    right: this.right,
                    part: part.part
                })
            })

            this.body.detention = new_detention
        },
        errorMsg() {
            if (this.errorMsg != "") {
                alert(this.errorMsg);
            }
        },
    },
    computed: {
        isOriginalScenario() {
            const routeArray = this.$route.fullPath.split('/')

            if (routeArray[3] == 'profile' || this.$route.query.isOriginal) {
                return true
            } else {
                return false
            }
        },
    },
    methods: {
        updateValue(value) {
            this.value_update = value
        },
        updateDetention(detention) {
            this.body.detention = detention
        },
        submit() {
            if (this.isOriginalScenario) {
                this.body.isIpCreation = false
            } else if (this.isNew) {
                this.body.isIpCreation = true
            }

            if (this.body.type == undefined) {
                this.errorMsg = "Précisez le type d'actif"

            } else if (this.body.value == 0) {
                this.errorMsg = "Renseignez une valeur supérieure à 0€"

            } else if (this.body.detention.length < 1) {
                this.errorMsg = "Renseignez au moins un propriétaire"

            } else {
                if (this.isNew) {
                    this.errorMsg = ""
                    this.body.addToStore = true
                    this.$store.dispatch('create_movable', this.body)

                } else {
                    this.body.value += this.value_update
                    this.$store.dispatch('action_modify_movable', this.body)
                }
                this.$emit('closeModale')
            }
        },
    },
    mounted() {
        if (this.import) {
            this.isNew = false
            this.body = JSON.parse(JSON.stringify(this.import))

            if (!this.body.isIndivision) {
                if (this.body.detention.length > 1) {
                    this.owner = 'commun'
                } else {
                    this.owner = this.body.detention[0].owner_id
                }
            }
        }

        if (this.isNew) {
            this.body.clientId = this.$route.params.clientId
            this.body.scenarioId = this.$route.params.scenarioId
        }
    }
}
</script>

<style src="./newStyle.css" scoped></style>