<template>
    <div class="rank" v-if="show">

        <!-- Bénéfifiaires -->
        <div class="rank-label">
            <p>
                <span>Bénéficiaires</span>
                <span v-if="isDismembered && index == 0"> de l'usufruit</span>
                <span v-else-if="isDismembered && index == 1"> de la nue-propriété</span>
                <span v-else> de rang {{index + 1}}</span>
            </p>
            
            <div class="delete-rank-btn" @click="deleteRank">
                <span class="material-symbols-outlined">delete_forever</span>
            </div>
        </div>

        <table class="beneficiaries-table">
            <tr v-for="person_data in result_data" :key="person_data._id">
                <td>{{ person_data.firstname }} {{ person_data.lastname }} - {{ $store.getters.allRoles[person_data.role] }}</td>

                <td>
                    <input type="checkbox" v-model="person_data.is_beneficiary">
                </td>

                <td v-if="repartition">
                    <percent-input v-model="person_data.percentage"  :disabled="!person_data.is_beneficiary"></percent-input>
                </td>
            </tr>
        </table>

        <!-- <multi-select
            :elements="beneficiariesList"
            :values="beneficiaries"
            @closed="(result)=>{ beneficiaries = result }"
        /> -->

        <div class="form-container">
            <!-- représentation -->
            <div class="form-section">
                <label>Représentation</label>
                <select id="owner" v-model="representation">
                    <option :value="true">OUI</option>
                    <option :value="false">NON</option>
                </select>
            </div>

            <!-- Repartition -->
            <div class="form-section">
                <label>Repartition</label>
                <select id="owner" v-model="repartition">
                    <option :value="true">OUI</option>
                    <option :value="false">NON</option>
                </select>
            </div>
        </div>
        

        <!-- <table class="repartition_detail" v-if="repartition">
            <tr v-for="beneficiaryDetail in repartition_detail" :key="beneficiaryDetail.beneficiary_id">
                <td>{{ $store.getters.findPersonName(beneficiaryDetail.beneficiary_id) }}</td>
                <td><input type="number" v-model="beneficiaryDetail.percentage"></td>
            </tr>
        </table> -->
    </div>
</template>

<script>
export default {
    props: [
        'isDismembered', 
        'index',
        'rank',
        'beneficiariesList'
    ],
    data() {
        return {
            show: false,
            result_data: [],
            representation: false,
            repartition: true,
        }
    },
    watch: {
        rank: {
            deep: true,
            handler() {
                this.representation = this.rank.representation
                this.repartition = this.rank.repartition
            }
        },
        representation() {
            this.updateRank()
        },
        repartition() {
            this.updateRank()
        },
        result_data: {
            deep: true,
            handler() {
                this.updateRank()
            }
        }
    },
    methods: {
        createResultObject() {
            let result = [];
            
            this.beneficiariesList.forEach(person => {
                const is_beneficiary = this.rank.beneficiaries.includes(person._id);
                const representation_detail = this.rank.repartition_detail ? this.rank.repartition_detail[person._id] : undefined;

                result.push({
                    _id: person._id,
                    firstname: person.firstname,
                    lastname: person.lastname,
                    role: person.role,
                    is_beneficiary: is_beneficiary,
                    percentage: representation_detail ? representation_detail.percentage : undefined,
                });
            });

            this.result_data = result;
        },
        deleteRank() {
            this.$emit('deleteRank')
        },
        updateRank() {
            this.$emit('updateRank', {
                beneficiaries: this.result_data
                    .filter(data => data.is_beneficiary)
                    .map(data => data._id),
                representation: this.representation,
                repartition: this.repartition,
                repartition_detail: this.result_data
                    .filter(data => data.percentage)
                    .map(data => {
                        return {
                            beneficiary_id: data._id,
                            percentage: data.percentage,
                        }
                    }),
            })
        }
    },
    mounted() {
        this.createResultObject()
        this.beneficiaries = this.rank.beneficiaries
        this.representation = this.rank.representation
        this.repartition = this.rank.repartition
        if(this.rank.repartition_detail) {
            this.repartition_detail = this.rank.repartition_detail
        }

        this.show = true
    }
}
</script>

<style src="../forms/newStyle.css" scoped></style>

<style scoped>
.rank {
    padding: 0 10px;
    border-radius: 5px;
}
.rank-label {
    font-weight: bold;
}
.delete-rank-btn {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    color: grey;
}
.delete-rank-btn:hover {
    color: red;
}

.beneficiaries-table {
    width: 100%;
}
.beneficiaries-table td {
    position: relative;
    padding: 3px;
    font-size: 13px;
}
.beneficiaries-table td:nth-child(2) {
  text-align: center;
}

.beneficiaries-table td:nth-child(3) input {
    border: 1px solid lightgrey;
    border-radius: 5px;
    font-family: "verdana";
    font-size: 13px;
    padding: 5px;
}
</style>