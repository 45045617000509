<template>
    <div class="couple">
        <div class="label-top">
            Situation familiale
        </div>
        <div class="couple-container">
            <span>Situation familiale : {{ situations[$store.state.marital_contract?.situation] }}</span>
            <span v-if="$store.state.marital_contract?.situation == 'married'">Régime matrimonial : {{ $store.getters.maritalContracts.married[$store.state.marital_contract?.regmat] }}</span>
            <span v-if="$store.state.marital_contract?.situation == 'pacs'">Régime pacsimonial : {{ $store.getters.maritalContracts.pacs[$store.state.marital_contract?.regmat] }}</span>
        </div>

        <div class="btn-couple" @click="toggleModale">Modifier</div>
        

        <overlay :show="showModale" @toggle="toggleModale">
            <Couple @toggleModale="toggleModale"/>
        </overlay>

    </div>
</template>

<script>
import Couple from '../forms/Couple.vue';

export default {
    components: {
        Couple
    },
    data() {
        return {
            showModale: false,
            situations: {
                free_union: 'En concubinage',
                pacs: "Pacsés",
                married: "Mariés",
                alone: "Célibataire",
                widowed: "Veuf"
            },
        }
    },
    methods: {
        toggleModale() {
            this.showModale = !this.showModale
        }
    }
}
</script>

<style scoped>

.couple {
    width: 35%;
    min-width: 400px;
    display: flex;
    position: relative;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    padding: 0;
    padding: 40px 0 20px 0;
    margin: 20px;
    margin-left: 6.5%;
}

.label-top {
    position: absolute;
    top: 0;
    border-radius: 20px 0 15px 0;
    font-size: 20px;
    font-weight: bold;
    background-color: rgb(1, 1, 84);
    color: white;
    padding: 10px;
}
.couple-container {
    display: flex;
    flex-direction: column;
    padding: 10px;

}
.couple-container span {
    color: #0a1c6b;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
    padding: 10px;
}

.btn-couple {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #fb9e4b;
    color: white;
    font-weight: bold;
    padding: 5px;
    border-radius: 10px 0 10px 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-couple:hover {
    background-color: #0a1c6b;
}
</style>

<style scoped src="./style.css"></style>