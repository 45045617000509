<template>
    <div>
        <div class="menu">
            <div :class="{'menu-selection': !show_detailed_result}" @click="show_detailed_result=false">Données de simulation</div>
            <div :class="{'menu-selection': show_detailed_result, 'menu-disabled': computationResult == undefined}" @click="computationResult ? show_detailed_result=true : null">Résultat détaillé</div>
        </div>

        <div class="calculator-frame">
            <div class="left-section" v-if="!show_detailed_result">
                <div class="section-element">
                    <h3>Famille</h3>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Calcul pour un couple</label>
                            <select v-model="simplified_datas.is_couple">
                                <option :value="true">OUI</option>
                                <option :value="false">NON</option>
                            </select>
                        </div>

                        <div class="input-frame" v-if="simplified_datas.is_couple">
                            <label>Type d'union</label>
                            <select v-model="simplified_datas.couple_situation">
                                <option value="married">Mariage</option>
                                <option value="pacs">PACS</option>
                                <option value="free_union">Concubinage</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Nombre d'enfants</label>
                            <input type="number" v-model="simplified_datas.nb_children">
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Date de naissance du client</label>
                            <input type="date" v-model="simplified_datas.user_birthdate">
                        </div>

                        <div class="input-frame" v-if="simplified_datas.is_couple">
                            <label>Date de naissance du conjoint</label>
                            <input type="date" v-model="simplified_datas.spouse_birthdate">
                        </div>
                    </div>
                    
                </div>

                <div class="section-element">
                    <h3>Patrimoine</h3>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Montant du patrimoine net du client</label>
                            <currency-input v-model="simplified_datas.wealth_value_user"/>
                        </div>

                        <div class="input-frame" v-if="simplified_datas.is_couple">
                            <label>Montant du patrimoine net du conjoint</label>
                            <currency-input v-model="simplified_datas.wealth_value_spouse"/>
                        </div>
                    </div>
                </div>

                <div class="section-element">
                    <h3>Données de simulation</h3>

                    <div class="form-section" v-if="simplified_datas.couple_situation == 'married' && simplified_datas.is_couple">
                        <div class="input-frame">
                            <label>Choix du conjoint survivant</label>
                            <select v-model="simplified_datas.spouse_choice">
                                <option value="100%US">Tout l'usufruit</option>
                                <option value="QDPP">La quotité disponible en pleine propriété</option>
                                <option value="1/4PP">1/4 en pleine propriété</option>
                                <option value="3/4US+1/4PP">1/4 en pleine propriété et 3/4 de l'usufruit</option>
                                <option value="O%">Renonciation</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Date du décès théorique</label>
                            <input type="date" v-model="simplified_datas.death_date">
                        </div>
                    </div>
                </div>

                <div class="section-element btn-section">
                    <div class="btn-send-computation btn-grow" @click="computeResult">Calculer</div>
                </div>
            </div>

            <div class="left-section" v-else>
                <div class="section-element">
                    <h3>Premier décès</h3>

                    <table>
                        <tr class="highlight">
                            <th>Héritier - succession 1</th>
                            <th>Héritage reçu</th>
                            <th>DMTG</th>
                        </tr>

                        <tr v-for="inheritor in computationResult.first_death_result.formated_inheritors" :key="inheritor._id">
                            <td>{{ inheritor.full_name }}</td>
                            <td>{{ toEuro(inheritor.inheritance_value) }}</td>
                            <td>{{ toEuro(inheritor.inheritance_tax) }}</td>
                        </tr>
                    </table>
                </div>

                <div class="section-element" v-if="computationResult.second_death_result">
                    <h3>Second décès</h3>

                    <table>
                        <tr class="highlight">
                            <th>Héritier - succession 2</th>
                            <th>Héritage reçu</th>
                            <th>DMTG</th>
                        </tr>

                        <tr v-for="inheritor in computationResult.second_death_result?.formated_inheritors" :key="inheritor._id">
                            <td>{{ inheritor.full_name }}</td>
                            <td>{{ toEuro(inheritor.inheritance_value) }}</td>
                            <td>{{ toEuro(inheritor.inheritance_tax) }}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="right-section">
                <div class="section-element" v-if="!computationResult">
                    <h3>Résultats</h3>

                    <img src="../ressources/img/illustration.png"/>

                    <p>Lancez le calcul pour obtenir un résultat</p>
                </div>

                <div class="section-element" v-if="computationResult">
                    <h3>Impôt à payer</h3>
                    <h4>{{ toEuro(computationResult.inheritance_cost) }}</h4>
                </div>

                <div class="section-element" v-if="computationResult">
                    <h3>Frais de notaire</h3>
                    <h4>{{ toEuro(computationResult.total_notary_cost) }}</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            show_detailed_result: false,

            simplified_datas: {
                is_couple: false,
                couple_situation: 'married',
                nb_children: 0,
                user_birthdate: undefined,
                spouse_birthdate: undefined,
                wealth_value_user: 0,
                wealth_value_spouse: 0,
                spouse_choice: '100%US',
                death_date: undefined,
                
            },
            computationResult: undefined,
        }
    },
    computed: {
        body() {
            let body = {
                marital_contract: {
                    situation                  : 'alone',
                    regmat                     : undefined,
                    full_attribution           : false,
                    preciput                   : false,
                    acquest_society            : false,
                    inegal_repartition         : false,
                    inegal_repartition_detail  : undefined,
                    preciput_detail            : undefined,
                    ddv_userforspouse          : false,
                    ddv_spouseforuser          : false,
                },
                persons: [],
                properties: [],
                financial_assets: [],
                societies: [],
                movable_assets: [],
                insurances: [],
                gifts: [],
                debts: [],
                wills: [],
                rewards: [],
            }

            // Couple
            body.persons.push({
                _id: 'user1',
                role: 'user',
                firstname: 'Client',
                lastname: "",
                birthdate: this.simplified_datas.user_birthdate,
                is_dead: false,
                is_handicaped: false,
            })

            if (this.simplified_datas.is_couple) {
                body.persons.push({
                    _id: 'spouse1',
                    role: 'spouse',
                    firstname: 'Conjoint',
                    lastname: "",
                    birthdate: this.simplified_datas.spouse_birthdate,
                    is_dead: false,
                    is_handicaped: false,
                })

                body.marital_contract.situation = this.simplified_datas.couple_situation
            }

            // Heritiers
            for (let i = 1; i <= this.simplified_datas.nb_children; i++) {
                body.persons.push({
                    _id: 'child' + i,
                    role: 'child',
                    firstname: 'Enfant ' + i,
                    lastname: "",
                    birthdate: '1990-01-01',
                    is_dead: false,
                    is_handicaped: false,
                    parent1: 'user1',
                    parent2: 'spouse1'
                })
            }

            // Patrimoine
            if (this.simplified_datas.wealth_value_user > 0) {
                body.financial_assets.push({
                    _id: 'user-wealth',
                    category: 'bank',
                    type: 'csl',
                    value: this.simplified_datas.wealth_value_user,
                    detention: [
                        { owner_id: 'user1', part: 1, right: 'pp'}
                    ]
                })
            }   

            if (this.simplified_datas.wealth_value_spouse > 0) {
                body.financial_assets.push({
                    _id: 'spouse-wealth',
                    category: 'bank',
                    type: 'csl',
                    value: this.simplified_datas.wealth_value_spouse,
                    detention: [
                        { owner_id: 'spouse1', part: 1, right: 'pp'}
                    ]
                })
            }

            return {
                datas: body,
                spouseChoice: this.simplified_datas.spouse_choice,
                death_date: this.simplified_datas.death_date,
            }
        }
    },
    methods: {
        async computeResult() {
            if (this.simplified_datas.nb_children <= 0) {
                return ;
            } else if (this.simplified_datas.user_birthdate == undefined) {
                return;
            } else if (this.simplified_datas.is_couple && this.simplified_datas.spouse_birthdate == undefined) {
                return;
            } else if (this.simplified_datas.wealth_value_user + this.simplified_datas.wealth_value_spouse <= 0) {
                return;
            } else {
                const res = await axios.post(this.$store.getters.get_api_url + 'simulators/legacy', this.body, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })

                this.computationResult = res.data
            }
        }
    }
}
</script>


<style src="./style.css" scoped></style>