<template>
    <div class="list-section">
        <h1 class="section-title">
            {{ label }} - {{ toEuro(totalWealthValue) }}
            <add-btn type="asset"></add-btn>
        </h1>

        <table class="table-elements">
            <list-component class="list-element"
                v-for="asset in list_assets"
                :key="asset._id"
                :object="asset"
                type="asset"
            />
        </table>
    </div>
</template>

<script>

export default {
    props: ['isLiquidities'],
    data() {
        return {}
    },
    computed: {
        label() {
            if (this.isLiquidities) {
                return "Liquidités bancaires"

            } else {
                return 'Patrimoine financier'
            }
        },
        list_assets() {
            if (this.isLiquidities) {
                const allLiquidities = this.$store.getters.getAllAssets.filter(a => this.$store.getters.liquidityTypes.includes(a.type))

                if (this.$store.getters.get_selected_vue == 'all-wealth') {
                    return allLiquidities
                } else if (this.$store.getters.get_selected_vue == 'family-wealth') {
                    const society_ids = this.$store.getters.getAllSocieties.map(s => s._id)
                    return allLiquidities.filter(asset => {
                        // Vérifie si tous les owner_id sont dans la liste ids
                        const allOwnersInIds = asset.detention.every(owner => society_ids.includes(owner.owner_id));
                        // Conserve le bien si ce n'est pas le cas
                        return !allOwnersInIds;
                    });
                    
                } else {
                    // Patrimoine d'une société
                    return allLiquidities.filter( a => a.detention.map(d => d.owner_id).includes(this.$store.getters.get_selected_vue) )
                }

            } else {
                const allAssets = this.$store.getters.getAllAssets.filter(a => !this.$store.getters.liquidityTypes.includes(a.type))

                if (this.$store.getters.get_selected_vue == 'all-wealth') {
                    return allAssets
                } else if (this.$store.getters.get_selected_vue == 'family-wealth') {
                    const society_ids = this.$store.getters.getAllSocieties.map(s => s._id)
                    return allAssets.filter(asset => {
                        // Vérifie si tous les owner_id sont dans la liste ids
                        const allOwnersInIds = asset.detention.every(owner => society_ids.includes(owner.owner_id));
                        // Conserve le bien si ce n'est pas le cas
                        return !allOwnersInIds;
                    });
                    
                } else {
                    // Patrimoine d'une société
                    return allAssets.filter( a => a.detention.map(d => d.owner_id).includes(this.$store.getters.get_selected_vue) )
                }
            }
        },
        totalWealthValue() {
            if (this.isLiquidities) {
                return this.$store.getters.getTotalLiquidityWealth

            } else {
                return this.$store.getters.getTotalFinancialWealth
            }
        }
    }
}
</script>

<style scoped src="./style.css"></style>

