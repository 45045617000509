<template>
    <div class="view-page">

        <div class="page-title">
            <span class="material-symbols-outlined person-icon">account_box</span>
            <h1>{{user.firstname}} {{user.lastname}}</h1>
        </div>
        
        <div class="contact">
            <h1>Contact</h1>

            <div>
                <span class="material-symbols-outlined">location_on</span>
                <span>15 rue Violet, 75015 Paris</span>
            </div>

            <div>
                <span class="material-symbols-outlined">mail</span>
                <span>basile.diot@gmail.com</span>
            </div>

            <div>
                <span class="material-symbols-outlined">phone_in_talk</span>
                <span>06 95 15 19 63</span>
            </div>
        </div>

        <div class="actions">
            <div class="tooltip">
                <span class="material-symbols-outlined" @click="openProfile">login</span>
                <span class="tooltiptext">Accéder au profil</span>
            </div>
            
            <div class="tooltip">
                <span class="material-symbols-outlined" @click="deleteClient">delete_forever</span>
                <span class="tooltiptext">Supprimer le client</span>
            </div>
        </div>
        
        <div class="scenarios">
            <h1>Recommandations</h1>

            <table>
                <tr>
                    <th>Nom du scenario</th>
                    <th>Date de création</th>
                    <th>Actions</th>
                </tr>

                <tr v-for="reco in recommandationsIP" :key="reco._id">
                    <td> {{ recommendationsTypes[reco.type] }} </td>
                    <td> {{ dateToString(new Date()) }} </td>
                    <td class="scenario-btns">
                        <span class="material-symbols-outlined setting-btn" @click="toggleModale">manufacturing</span>
                        <span class="material-symbols-outlined open-btn" @click="openScenarioOverview(reco._id)">login</span>
                        <span class="material-symbols-outlined delete-btn" @click="deleteRecommendation(reco._id)">delete_forever</span>
                    </td>
                </tr>
            </table>
        </div>

        <div class="scenarios">
            <h1>Préconisations du conseiller</h1>

            <table>
                <tr>
                    <th>Nom du scenario</th>
                    <th>Date de création</th>
                    <th>Actions</th>
                </tr>

                <tr v-for="reco in recommandationsAdvisor" :key="reco._id">
                    <td> {{ recommendationsTypes[reco.type] }} </td>
                    <td> {{ dateToString(new Date()) }} </td>
                    <td class="scenario-btns">
                        <span style="cursor: pointer" class="material-symbols-outlined" @click="toggleModale">manufacturing</span>
                        <span style="cursor: pointer" class="material-symbols-outlined" @click="openScenarioOverview(reco._id)">login</span>
                        <span class="material-symbols-outlined delete-btn" @click="deleteRecommendation(reco._id)">delete_forever</span>
                    </td>
                </tr>
            </table>
        </div>

        <modale :show="showModale" width="500px" @toggle="toggleModale">
            <div class="form-section">
                <label>Partenaire</label>
                <searchable-select v-model="style" :options="$store.getters.partnerStylesOptions"/>
            </div>
        </modale>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            showModale: false,
            style: undefined,
            persons: [],
            originalScenarioId: undefined,
            recommendations: [],
            recommendationsTypes: {
                legacy: "Recommandation transmission",
                retirement: "Recommandation retraite",
                taxes_optimisation: "Recommandation fiscalité",
                wealth_development: "Recommandation développement du patrimoine",
                new_incomes: "Recommandation revenus complémentaires",
            }
        }
    },
    methods: {
        openProfile() {
            this.$router.replace('/client/' + this.$route.params.clientId + '/profile/' + this.originalScenarioId + '/couple')
        },
        async deleteClient() {
            const valid = confirm('Supprimer ce client ?')
            if (valid) {
                await axios.delete(this.$store.getters.get_api_url + 'clients/client/' + this.$route.params.clientId, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })
                this.$router.replace('/client')
            }
        },
        openScenarioOverview(recoId) {
            this.$router.replace('/client/' + this.$route.params.clientId + '/recommendation/' + recoId + '/overview/')
        },
        async getRecommendations() {
            const res = await axios.get(this.$store.getters.get_api_url + 'clients/recommendation/' + this.$route.params.clientId, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            });

            this.recommendations = res.data
        },
        async getClientStyle() {
            const res = await axios.get(this.$store.getters.get_api_url + 'clients/find-client/' + this.$route.params.clientId, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            
            this.style = res.data.client_style
        },
        async deleteRecommendation(id) {
            const confirmation = confirm('Êtes vous sûr de vouloir supprimer cette recommandation ?');
            if (!confirmation) return;
            
            await axios.delete(this.$store.getters.get_api_url + 'clients/recommendation/' + id, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            });
            this.getRecommendations()
        },
        updateStyle() {
            axios.put(this.$store.getters.get_api_url + 'clients/client', {
                _id: this.$route.params.clientId,
                client_style : this.style,
            }, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
        },
        toggleModale() {
            this.showModale = !this.showModale
        },
    },
    computed: {
        user() {
            let result = this.persons.find(person => person.role == 'user')

            if (result) {
                return result

            } else {
                return {}
            }
        },
        recommandationsIP() {
            return this.recommendations.filter(r => !r.isClientRecommandation)
        },
        recommandationsAdvisor() {
            return this.recommendations.filter(r => r.isClientRecommandation)
        },
    },
    watch: {
        style() {
            this.updateStyle()
        }
    },
    async mounted() {
        this.getClientStyle()
        const res = await axios.get(this.$store.getters.get_api_url + 'clients/scenario-original/' + this.$route.params.clientId, {
            headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
        })

        this.originalScenarioId = res.data._id

        this.getRecommendations()
        
        const persons_response = await axios.get(this.$store.getters.get_api_url + 'clients/person/' + this.originalScenarioId, {
            headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
        })

        this.persons = persons_response.data
    }
}
</script>

<style scoped>
.view-page {
    padding: 20px 5%;
}  
.page-title {
    color: rgb(30,44,88);
    display: flex;
    align-items: center;
}
.person-icon {
    font-size: 50px;
}
.page-title h1 {
    margin: 0 10px;
    padding: 15px 0;
    border-bottom: solid 1px lightgrey;
}

.contact {
    margin: 20px 0;
    padding: 70px 10px 20px 10px;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: white;
    display: inline-block;
    border-radius: 20px;
}
.contact h1 {
    position: absolute;
    padding: 10px 20px;
    margin: 0;
    font-size: 25px;
    top: 0;
    left: 0;
    background-color: rgb(2, 2, 91);
    color: white;
    border-radius: 20px 0 20px 0;
}
.contact div {
    padding: 10px;
    display: flex;
    align-items: center;
}
.contact div span {
    margin-left: 10px;
}

.actions {
    margin-left: 10px;
    padding: 10px;
    border-radius: 10px;
    background-color: white;
    display: flex;
    display: inline-block;
    flex-direction: column;
}
.actions span {
    cursor: pointer;
    font-size: 35px;
    padding: 5px 0;
}
.actions span:hover {
    color: orange;
}

.btn-bilan {
    position: absolute;
    right: 20px;
    top: 20px;
    background-color: rgba(7, 2, 99, 0.989);
    color: white;
}
.btn-bilan:hover {
    background-color: rgba(7, 0, 135, 0.989);
    color: white;
}

.btn-infos {
    background-color: rgba(255, 166, 0, 0.989);
}
.btn-infos:hover {
    background-color: rgba(238, 178, 65, 0.989);
}
.btn-delete {
    background-color: rgb(166, 0, 0);
    color: white;
}
.btn-delete:hover {
    background-color: rgb(221, 32, 32);
}

.scenarios {
    display: flex;
    flex-direction: column;
    position: relative;
}

.scenarios h1 {
    color: rgb(30,44,88);
    border-bottom: solid 1px rgb(229, 229, 229);
}

.scenarios table {
    font-size: 17px;
    border-collapse: collapse;
    border-radius: 20px;
}
.scenarios table th, 
.scenarios table td {
    border: 1px solid rgb(243, 245, 247);
    padding: 8px; 
    text-align: center;
}

.scenarios tr th:first-child,
.scenarios tr td:first-child {
    width: 70%;
    text-align: left;
}
.scenarios tr td:last-child {
    display: flex;
    justify-content: space-evenly;
}

.scenarios th {
    background-color: rgb(30,44,88);
    color: white;
}
.scenarios td {
    background-color: white;
    color: rgb(93, 93, 93);
}

/* Angles arrondis */
.scenarios table tr:first-child th:first-child {
    border-top-left-radius: 10px;
}
.scenarios table tr:first-child th:last-child {
    border-top-right-radius: 10px;
}
.scenarios table tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}
.scenarios table tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}

/* Buttons */
.setting-btn:hover {
    cursor: pointer;
    color: orange;
}

.open-btn:hover {
    cursor: pointer;
    color: green;
}

.delete-btn:hover {
    cursor: pointer;
    color: red;
}

.scenario-btns {
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-access {
    cursor: pointer;
    margin-left: 10px;
    padding: 2px 5px;
    border-radius: 2px;
    background-color: rgb(255, 170, 0);
    color: white;
}
.btn-access:hover {
    background-color: rgb(1, 1, 85);
}

.form-section {
    display: flex;
    flex-direction: column;
    margin: 20px;
}
.form-section label, 
.form-section input, 
.form-section select {
    font-size: 18px;
}

.form-section label {
    font-weight: bold;
}
.form-section input, 
.form-section select {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid lightgrey;
}

.tooltip {
    position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden; /* Masqué par défaut */
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 2px 5px;
  border-radius: 2px;
  font-size: 12px;
  min-width: 130px;

  /* Positionnement */
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Place le tooltip au-dessus du bouton */
  left: 50%;
  transform: translateX(-50%);
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>