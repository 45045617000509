<template>
    <div>
        <div class="menu">
            <div :class="{'menu-selection': !show_detailed_result}" @click="show_detailed_result=false">Données de simulation</div>
            <div :class="{'menu-selection': show_detailed_result, 'menu-disabled': computationResult == undefined}" @click="computationResult ? show_detailed_result=true : null">Résultat détaillé</div>
        </div>

        <div class="calculator-frame">
            <div class="left-section" v-if="!show_detailed_result">
                <div class="section-element">
                    <h3>Données d'investissement</h3>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Versement initial</label>
                            <currency-input v-model="body.versement_initial"/>
                        </div>

                        <div class="input-frame">
                            <label>Versement périodique</label>
                            <currency-input v-model="body.versement_periodique"/>
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Période de capitalisation</label>
                            <select v-model="body.type_capitalisation">
                                <option v-for="(value, key) in capitalizationTypes" :value="key" :key="key">{{value}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Taux de rendement annuel</label>
                            <percent-input v-model="body.taux_annuel"/>
                        </div>

                        <div class="input-frame">
                            <label>Durée en mois</label>
                            <input type="number" v-model="body.nb_mois">
                        </div>
                    </div>
                    
                </div>

                <div class="section-element btn-section">
                    <div class="btn-send-computation btn-grow" @click="computeResult">Calculer</div>
                </div>
            </div>

            <div class="left-section" v-else>
                <div class="section-element">
                    <h3>Projection financière annuelle</h3>

                    <line-chart
                        :chartId="'financial-projection'"
                        :labels="labels"
                        :datasets="datasets"
                        :startZero="false"
                    />
                </div>

                <div class="section-element" v-if="computationResult.second_death_result">
                    <h3>Second décès</h3>

                    
                </div>
            </div>

            <div class="right-section">
                <div class="section-element" v-if="!computationResult">
                    <h3>Résultats</h3>

                    <img src="../ressources/img/illustration.png"/>

                    <p>Lancez le calcul pour obtenir un résultat</p>
                </div>

                <div class="section-element" v-if="computationResult">
                    <h3>Valeur finale</h3>
                    <h4>{{ toEuro(computationResult.valeur_finale) }}</h4>
                </div>

                <div class="section-element" v-if="computationResult">
                    <h3>Versement total</h3>
                    <h4>{{ toEuro(computationResult.versementTotal) }}</h4>
                </div>

                <div class="section-element" v-if="computationResult">
                    <h3>Plus-value</h3>
                    <h4>{{ toEuro(computationResult.gainTotal) }}</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            show_detailed_result: false,

            capitalizationTypes: {
                bimensuelle: "Capitalisation par quinzaine",
                mensuelle: "Capitalisation mensuelle",
                annuelle: "Capitalisation annuelle",
            },
            body: {
                versement_initial: 100000,
                type_capitalisation: "mensuelle", // bimensuelle, mensuelle, annuelle
                nb_mois: 120,
                taux_annuel: 0.05,
                versement_periodique: 100,
            },
            computationResult: undefined,
        }
    },
    computed: {
        labels() {
            return this.computationResult.capitalizationTable.map((p, index) => `Période n°${index}`)
        },
        datasets() {
            return [
                {
                    label: 'Portefeuille',
                    data: this.computationResult.capitalizationTable.map(p => p.valeur_fin_de_periode),
                    borderColor: 'rgb(240, 139, 139)',
                    pointBackgroundColor: "red",
                    pointRadius: "2",
                },
                {
                    label: 'Versements',
                    data: this.computationResult.capitalizationTable.map(p => p.versement_total),
                    borderColor: 'rgb(160, 187, 196)',
                    backgroundColor: 'rgba(146, 217, 241, 0.803)',
                    pointBackgroundColor: "gray",
                    pointRadius: "2",
                },
            ]
        }
    },
    methods: {
        async computeResult() {
            const res = await axios.post(this.$store.getters.get_api_url + 'simulators/investment-capitalization', this.body, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            });

            this.computationResult = res.data;
        }
    }
}
</script>


<style src="./style.css" scoped></style>