<template>
    <div class="ifi-result-page">
        <div class="result-frame">
            <div class="form-section">
                <label>Date de simulation</label>
                <select v-model="simulation_year">
                    <option v-for="(year, index) in years" :key="index" :value="year">1er janvier {{ year }}</option>
                </select>
            </div>

            <h1 class="ifi-result">IFI estimé : {{toEuro(ifi_result.ifi_reel?.impot) }}</h1>

            <h2>Patrimoine soumis à l'IFI</h2>

            <table>
                <tr>
                    <th>Actif entrant dans l'IFI</th>
                    <th>Valeur vénale</th>
                    <th>Valeur IFI</th>
                </tr>
                
                <tr v-for="asset in ifi_result.actif_brut?.liste_des_actifs" :key="asset._id">
                    <td>{{ $store.getters.getAllAssetLabel(asset._id) }}</td>
                    <td>{{ toEuro(asset.valeur_detenue) }}</td>
                    <td>{{ toEuro(asset.valeur_ifi_detenue) }}</td>
                </tr>

                <tr v-for="society in ifi_result.societes_infos?.detail_du_calcul" :key="society._id">
                    <td>{{ $store.getters.getAllAssetLabel(society._id) }}</td>
                    <td>{{ toEuro(society.valeur_detenue) }}</td>
                    <td>{{ toEuro(society.valeur_ifi_detenue) }}</td>
                </tr>

                <tr v-for="asset in ifi_result.actif_brut?.liste_des_assurances" :key="asset._id">
                    <td>{{ $store.getters.getInsuranceLabel(asset._id) }}</td>
                    <td>{{ toEuro(asset.valeur_detenue) }}</td>
                    <td>{{ toEuro(asset.valeur_ifi_detenue) }}</td>
                </tr>

                <tr>
                    <th colspan="2">Total</th>
                    <th>{{ toEuro(ifi_result.actif_brut?.actif_brut_total) }}</th>
                </tr>
            </table>

            <table>
                <tr>
                    <th>Passif déductible de l'IFI</th>
                    <th>Capital restant dû</th>
                    <th>Valeur déductible de l'IFI</th>
                </tr>

                <tr v-for="debt in ifi_result.passif_deductible?.detail_dettes" :key="debt._id">
                    <td>{{ $store.getters.getDebtLabel(debt._id) }}</td>
                    <td>{{ toEuro(debt.crd) }}</td>
                    <td>{{ toEuro(debt.valeur_deductible) }}</td>
                </tr>

                <tr>
                    <td>IFI théorique</td>
                    <td></td>
                    <td>{{ toEuro(ifi_result.ifi_theorique?.impot) }}</td>
                </tr>

                <tr v-if="ifi_result.ifi_reel?.plafonnement_du_passif?.reduction > 0">
                    <th colspan="2">Réintégration pour plafonnement des dettes</th>
                    <th>{{ toEuro(ifi_result.ifi_reel?.plafonnement_du_passif?.reduction) }}</th>
                </tr>

                <tr>
                    <th colspan="2">Total</th>
                    <th>{{ toEuro(ifi_result.ifi_reel?.plafonnement_du_passif?.total_deductible_apres_plafonnement) }}</th>
                </tr>
            </table>

            <table>
                <tr>
                    <th>IFI à payer ({{ ifi_result.date_de_simulation }})</th>
                    <th>Montant</th>
                </tr>

                <tr>
                    <td>Actif brut imposable</td>
                    <td>{{ toEuro(ifi_result.actif_brut?.actif_brut_total) }}</td>
                </tr>

                <tr>
                    <td>Passif déductible</td>
                    <td>- {{ toEuro(ifi_result.ifi_reel?.plafonnement_du_passif?.total_deductible_apres_plafonnement) }}</td>
                </tr>

                <tr>
                    <th>Assiette imposable</th>
                    <th>{{ toEuro(ifi_result.ifi_reel?.assiette_ifi) }}</th>
                </tr>

                <tr>
                    <th>Impôt sur la fortune immobilière estimé</th>
                    <th>{{ toEuro(ifi_result.ifi_reel?.impot) }}</th>
                </tr>
            </table>
        </div>

        <div class="btn-detail" @click="download_detail">Détail du calcul</div>
        <loader-spin v-if="pdf_downloading"></loader-spin>

        <!-- <div>
            {{ simulation_data }}

            <p>résultat</p>
            {{ ifi_result }}
        </div> -->
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            ifi_result: {},
            years: [2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039],
            simulation_year: 2026,
            pdf_downloading: false,
        }
    },
    computed: {
        simulation_data() {
            const body = {
                ...this.$store.getters.ifiDatas,
                simulation_year: this.simulation_year,
            };

            return body;
        },
    },
    methods: {
        async computeIfi() {
            const ifi_res = await axios.post(this.$store.getters.get_api_url + 'simulators/ifi-tax', this.simulation_data, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            });
            
            this.ifi_result = ifi_res.data;
        },
        assetType(type) {
            let result = this.$store.getters.propertyTypesList[type]
            
            if (!result) {
                result = "Unités de compte immobilières"
            }

            return result
        },
        async download_detail() {
            try {
                this.pdf_downloading = true;
                const pdf_res = await axios.post(
                    this.$store.getters.get_api_url + 'pdf-generator/ifi-report/', 
                    this.simulation_data, 
                    {
                        headers: { 
                            authorization: 'Bearer ' + this.$store.getters.get_token 
                        },
                        responseType: 'arraybuffer',
                    }
                );

                if (pdf_res.status == 201) {
                    const doc = await axios.get(this.$store.getters.get_api_url + 'documents/simulation_reports/ifi.pdf', { 
                        responseType: 'blob',
                        headers: {
                            authorization : 'Bearer ' + this.$store.getters.get_token
                        }
                    })

                    const blob = new Blob([doc.data], { type: 'application/pdf' })
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = 'Rapport-IFI.pdf'
                    link.click()
                    URL.revokeObjectURL(link.href)
                }

            } catch (err) {
                alert('Erreur lors du téléchargement du PDF : ' + err.message);
            }

            this.pdf_downloading = false;
        },
    },
    watch: {
        simulation_year() {
            this.computeIfi();
        }
    },
    mounted() {
        this.computeIfi();
    }
}
</script>

<style scoped>
.ifi-result-page {
    padding: 20px;
    margin: 10px auto;
    width: 80%;
}
.uc-real-estate-section {
    width: 50%;
    display: flex;
    justify-content: space-between;
}
.uc-real-estate-section input, label {
    font-size: 22px;
}
h1.ifi-result {
    text-align: center;
    color: rgb(2, 2, 93);
    font-size: 50px;
}
.detail-title {
    cursor: pointer;
    background-color: rgb(249, 183, 59);
    color: rgb(2, 2, 93);
    padding: 5px 20px;
    border-radius: 10px 10px 0 0;
}
.detail-title-rounded {
    border-radius: 10px;
}
.detail-content {
    padding: 20px;
    border-radius: 0 0 10px 10px;
    border: 1px solid rgb(244, 183, 70)
}
.detail-content h3 {
    color: rgb(2, 2, 93);
}

table {
    table-layout: fixed;
    width: 95%;
    margin: 10px auto;
    border-spacing : 0;
}

th, td {
    border: solid 0.5px #eee9e0;
}

th {
    padding: 10px;
    text-align: left;
    background-color: #f7efdf;
    color: rgb(92, 91, 91);
}

td {
    text-align: center;
    padding: 5px;
}

table.balance-sheet td, th {
    border: none;
    text-align: left;

}

.btn-detail {
    display: inline-block;
    margin: 10px auto;
    cursor: pointer;
    text-align: center;
    padding: 20px;
    background-color: rgb(0, 0, 67);
    color: white;
    border-radius: 5px;
}
.btn-detail:hover {
    background-color: rgba(0, 0, 67, 0.559);
}
</style>