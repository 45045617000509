<template>
    <div class="admin-frame">

        <modale :show="showModale" @toggle="toggleModale">
            <form class="add-admin-form">
                <section>
                    <label>Prénom</label>
                    <input type="text" v-model="body.firstname">
                </section>

                <section>
                    <label>Nom</label>
                    <input type="text" v-model="body.lastname">
                </section>

                <section>
                    <label>Adresse email</label>
                    <input type="email" v-model="body.email">
                </section>

                <section>
                    <label>Actif</label>
                    <select v-model="body.is_active">
                        <option :value="true">OUI</option>
                        <option :value="false">NON</option>
                    </select>
                </section>

                <section>
                    <label>Mot de passe</label>
                    <input type="password" v-model="body.password">
                </section>

                <section>
                    <label>Confirmez le mot de passe</label>
                    <input type="password" v-model="password_confirmation">
                </section>

                <div class="save-btn" @click="createAdmin">
                    <span class="material-symbols-outlined">add_circle</span>
                </div>
            </form> 
        </modale>

        <div class="admin-page">
            <div class="add-btn" @click="toggleModale">
                <span class="material-symbols-outlined">person_add</span>
            </div>
            <h2>Administrateurs ({{ activeAdminList.length }})</h2>

            <table>
                <tr>
                    <th>Prénom</th>
                    <th>Nom</th>
                    <th>Poste</th>
                    <th>Actif</th>
                    <th>Modifier</th>
                </tr>

                <tr v-for="admin in activeAdminList" :key="admin._id">
                    <td>{{ admin.firstname }}</td>
                    <td>{{ admin.lastname }}</td>
                    <td>{{ positions[admin.position] }}</td>
                    <td><input type="checkbox" v-model="admin.is_active" disabled></td>
                    <td @click="current_edit = admin._id">
                        <span class="material-symbols-outlined" style="cursor: pointer">border_color</span>
                        <modale :show="current_edit == admin._id" @toggle="current_edit = undefined">
                            <form class="add-admin-form">
                                <section>
                                    <label>Prénom</label>
                                    <input type="text" v-model="admin.firstname">
                                </section>

                                <section>
                                    <label>Nom</label>
                                    <input type="text" v-model="admin.lastname">
                                </section>

                                <section>
                                    <label>Adresse email</label>
                                    <input type="email" v-model="admin.email">
                                </section>

                                <section>
                                    <label>Poste</label>
                                    <select v-model="admin.position">
                                        <option v-for="(position, key) in positions" :key="key" :value="key">{{ position }}</option>
                                    </select>
                                </section>

                                <section>
                                    <label>Actif</label>
                                    <select v-model="admin.is_active">
                                        <option :value="true">OUI</option>
                                        <option :value="false">NON</option>
                                    </select>
                                </section>

                                <div class="save-btn" @click="updateAdmin(admin)">
                                    <span class="material-symbols-outlined">add_circle</span>
                                </div>
                            </form> 
                        </modale>
                    </td>
                </tr>
            </table>


            <h2>Administrateurs inactifs ({{ inactiveAdminList.length }})</h2>

            <table>
                <tr>
                    <th>Prénom</th>
                    <th>Nom</th>
                    <th>Poste</th>
                    <th>Actif</th>
                    <th>Modifier</th>
                </tr>

                <tr v-for="admin in inactiveAdminList" :key="admin._id">
                    <td>{{ admin.firstname }}</td>
                    <td>{{ admin.lastname }}</td>
                    <td>{{ positions[admin.position] }}</td>
                    <td><input type="checkbox" v-model="admin.is_active" disabled></td>
                    <td @click="current_edit = admin._id">
                        <span class="material-symbols-outlined" style="cursor: pointer">border_color</span>
                        <modale :show="current_edit == admin._id" @toggle="current_edit = undefined">
                            <form class="add-admin-form">
                                <section>
                                    <label>Prénom</label>
                                    <input type="text" v-model="admin.firstname">
                                </section>

                                <section>
                                    <label>Nom</label>
                                    <input type="text" v-model="admin.lastname">
                                </section>

                                <section>
                                    <label>Adresse email</label>
                                    <input type="email" v-model="admin.email">
                                </section>

                                <section>
                                    <label>Poste</label>
                                    <select v-model="admin.position">
                                        <option v-for="(position, key) in positions" :key="key" :value="key">{{ position }}</option>
                                    </select>
                                </section>

                                <section>
                                    <label>Actif</label>
                                    <select v-model="admin.is_active">
                                        <option :value="true">OUI</option>
                                        <option :value="false">NON</option>
                                    </select>
                                </section>

                                <div class="save-btn" @click="updateAdmin(admin)">
                                    <span class="material-symbols-outlined">add_circle</span>
                                </div>
                            </form> 
                        </modale>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            showModale: false,
            body: {
                firstname      : "",
                lastname       : "",
                email          : "",
                is_active      : true,
                is_super_admin : false,
                password       : "",
            },
            password_confirmation: "",
            errMsg: "",
            adminList: [],
            current_edit: undefined,
            positions: {
                analist: "Chargé d'étude",
                engineer: "Ingénieur patrimonial",
                engineer2: "Ingénieur patrimonial confirmé",
                engineer3: "Ingénieur patrimonial senior",
                director: "Directeur IP",
                sales: "Commercial",
                president: "Président",
                other: "Autre",
            }
        }
    },
    watch: {
        errMsg() {
            if (this.errMsg != "") {
                alert(this.errMsg);
            }
        }
    },
    methods: {
        toggleModale() {
            this.showModale = !this.showModale
        },
        async createAdmin() {
            if (this.body.firstname == '') {
                this.errMsg = "Veuillez renseigner un prénom."
            } else if (this.body.lastname == '') {
                this.errMsg = "Veuillez renseigner un nom de famille."
            } else if (this.body.email == '') {
                this.errMsg = "Veuillez renseigner une adresse email."
            } else if (this.body.password == '' || this.password_confirmation == '') {
                this.errMsg = "Veuillez renseigner un mot de passe."
            } else if (this.body.password != this.password_confirmation) {
                this.errMsg = "La confirmation du mot de passe n'est pas identique au mot de passe choisi. "
            }  else {
                await axios.post(this.$store.getters.get_api_url + 'admins/signup/', this.body, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })
                
                this.toggleModale()
                this.getAllAdmin()
            }
        },
        async updateAdmin(admin) {
            if (admin.firstname == '') {
                this.errMsg = "Veuillez renseigner un prénom."
            } else if (admin.lastname == '') {
                this.errMsg = "Veuillez renseigner un nom de famille."
            } else if (admin.email == '') {
                this.errMsg = "Veuillez renseigner une adresse email."
            } else {
                await axios.put(this.$store.getters.get_api_url + 'admins/modify/', admin, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })

                this.current_edit = undefined
                this.getAllAdmin()
            }
        },
        async getAllAdmin() {
            const res = await axios.get(this.$store.getters.get_api_url + 'admins/all/', {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            this.adminList = res.data
        }
    },
    computed: {
        activeAdminList() {
            return this.adminList.filter(a => a.is_active)
        },
        inactiveAdminList() {
            return this.adminList.filter(a => !a.is_active)
        },
    },
    beforeMount() {
        this.getAllAdmin()
    }
}
</script>

<style scoped>
.admin-frame {
    position: relative;
}
.admin-page {
    position: absolute;
    top: 15%;
    right: 10%;
    left: 10%;
    margin: 100px auto;
    background-color: white;
}
.admin-page h2 {
    padding: 10px 5%;
    color: rgb(0, 0, 99);
    font-size: 32px;
}

.add-btn {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 15px;
    background-color: rgb(249, 156, 50);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border-radius: 50px;
    color: white;
    font-weight: bold;
}
.add-btn:hover {
    background-color: rgb(252, 168, 72);
}

.add-admin-form {
    padding: 10px 25px;
    display: flex;
    flex-direction: column;
    font-size: 25px;
}
.add-admin-form section {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
}
.add-admin-form label, 
.add-admin-form input, 
.add-admin-form select {
    font-size: 18px;
}
.add-admin-form input, 
.add-admin-form select {
    border-radius: 5px;
    padding: 5px;
    border: 1px solid lightgrey;
}
.save-btn {
    cursor: pointer;
    display: inline-block;
    margin: 20px auto 20px auto
}
.save-btn span {
    font-size: 55px;
}
.save-btn span:hover {
    color: orange;
}

.error-msg {
    color: red;
}

table {
    margin: 20px auto;
    width: 90%;
    border-collapse: collapse;
}
td, th {
    border: 1px lightgray solid;
    padding: 5px;
    text-align: center;
    font-size: 16px;
    color: rgb(82, 82, 82);
}
th {
    background-color: #0a1c6b;
    color: white;
    padding: 10px;
}
</style>