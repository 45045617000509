<template>
    <div class="auth-page">
        <div class="img-container">
            <img src="../assets/vue-montparnasse.jpg" alt="Vue montparnasse">
        </div>

        <div class="auth-container">

            <img src="../assets/logo-lbf.png" alt="Logo LBF">

            <div class="auth-box">
                <div class="auth-box-form">
                    <section>
                        <label for="email">Adresse email :</label>
                        <input id="email" type="email" v-model="email">
                    </section>

                    <section>
                        <label for="password">Mot de passe :</label>
                        <div class="password-input-frame">
                            <input id="password" :type="passwordReadingMode" v-model="password">

                            <span class="material-symbols-outlined" @click="toggleVisibility" v-if="show_password">visibility</span>
                            <span class="material-symbols-outlined" @click="toggleVisibility" v-else>visibility_off</span>
                        </div>
                        
                    </section>

                    <div class="btn-container">
                        <div class="connexion-btn" @click="login">
                            <span class="material-symbols-outlined">input</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            email: "",
            password: "",
            errMsg: "",
            show_password: false,
        }
    },
    methods: {
        async login() {
            this.errMsg = ''
            try {
                const res = await axios.post(this.$store.getters.get_api_url + 'admins/login/', {
                    email: this.email,
                    password: this.password,
                })

                axios.get(this.$store.getters.get_api_url + 'admins/getone/' + res.data.adminId, {
                    headers: { authorization : 'Bearer ' + res.data.token }
                }).then(admin => {
                    this.$store.commit('setAdmin', admin.data)
                })

                localStorage.setItem('token', res.data.token)
                localStorage.setItem('adminId', res.data.adminId)

                this.$store.commit('setAuth', res.data)

                this.$router.replace('/client')
            } catch(err) {
                if (err.response?.data?.message) {
                    this.errMsg = err.response.data.message
                } else {
                    this.errMsg = "Erreur lors de l'authentification"
                }
            } 
        },
        toggleVisibility() {
            this.show_password = !this.show_password;
        }
    },
    watch: {
        errMsg() {
            this.$store.commit('changeErrorMsg', this.errMsg)
        }
    },
    computed: {
        passwordReadingMode() {
            if (this.show_password) {
                return "text"
            } else {
                return 'password'
            }
        }
    }
    
}
</script>

<style scoped>
.auth-page {
    display: flex;
    justify-content: space-between;
    height: 95vh;
}

.img-container {
    position: relative;
    flex-grow: 2;
    overflow: hidden;
}

.img-container img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}

.auth-container {
    flex-grow: 2;
    min-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.auth-container img {
    position: absolute;
    right: -300px;
    bottom: 0px;
    height: 700px;
}

.auth-box {
    position: relative;
    -webkit-box-shadow: 0px 0px 10px -3px #0a0a0a; 
    box-shadow: 0px 0px 10px -3px #C7C7C7;
    background-color: rgba(255, 255, 255, 0.903);
    border-radius: 10px;
    width: 80%;
    padding: 10px;
    min-width: 300px;
}

.auth-box-form {
    padding: 0px;
}

.auth-box-form section {
    display: flex;
    flex-direction: column;
    margin: 10px 20px;
}

.password-input-frame {
    display: flex;
    align-items: center;
}
.password-input-frame input {
    flex-grow: 1;
}
.password-input-frame span {
    cursor: pointer;
    margin: 3px;
}
.password-input-frame span:hover {
    color: orange;
}

.auth-box-form label,
.auth-box-form input {
    font-family: 'verdana';
    font-size: 16px;
    padding: 5px;
}

.auth-box-form label {
    font-weight: bold;
}
.auth-box-form input {
    border: 1px solid lightgrey;
    border-radius: 5px;
}

.btn-container {
    display: flex;
    justify-content: center;
}
.connexion-btn {
    cursor: pointer;
    background-color: rgb(255, 136, 0);
    height: 40px;
    width: 40px;
    border-radius: 50px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.connexion-btn:hover {
    background-color: rgb(249, 156, 50);
}

.input-options {
    margin: 20px;
    font-size: 18px;
}
.error-msg {
    color: red;
    font-size: 14px;
}


</style>