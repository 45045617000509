<template>
    <div class="client-frame">

        <section class="contact-frame">
            <h2 class="inframe-title">Informations du cabinet</h2>
            <img :src="logoUrl" class="logo" alt="logo-partenaire">

            <div class="contact-display">
                <div class="contact-column">
                    <div class="row">
                        <span class="material-symbols-outlined">store</span>
                        <p>{{ society.name }}</p>
                    </div>

                    <div class="row">
                        <span class="material-symbols-outlined">share_location</span>
                        <p>{{ society.adress }}, {{ society.zipcode }} {{ society.city }}</p>
                    </div>
                </div>

                <div class="contact-column">
                    <div class="row">
                        <span class="material-symbols-outlined">account_circle</span>
                        <p>{{ director.firstname }} {{ director.lastname }}</p>
                    </div>

                    <div class="row">
                        <span class="material-symbols-outlined">smartphone</span>
                        <p>{{ director.phone1 }} / {{ director.phone2 }}</p>
                    </div>

                    <div class="row">
                        <span class="material-symbols-outlined">stacked_email</span>
                        <p>{{ director.email1 }} / {{ director.email2 }}</p>
                    </div>
                </div>

                <modale :show="showContactModale" @toggle="toggleContactModale" width="70%">
                    <form class="modale-form">
                        <h3><span class="material-symbols-outlined">group</span>Dirigeant de la société</h3>
                        <div class="form-sect">
                            <label for="name">Nom du cabinet</label>
                            <input id="name" type="text" v-model="society.name">
                        </div>

                        <div class="form-sect">
                            <label for="director-firstname">Prénom du dirigeant</label>
                            <input id="director-firstname" type="text" v-model="director.firstname">
                        </div>

                        <div class="form-sect">
                            <label for="director-lastname">Nom du dirigeant</label>
                            <input id="director-lastname" type="text" v-model="director.lastname">
                        </div>

                        <h3><span class="material-symbols-outlined">contact_mail</span>Contact</h3>
                        <div class="form-sect form-sect-half">
                            <label>Téléphone</label>
                            <input type="text" v-model="director.phone1">
                        </div>

                        <div class="form-sect form-sect-half">
                            <label>Téléphone 2</label>
                            <input type="text" v-model="director.phone2">
                        </div>

                        <div class="form-sect form-sect-half">
                            <label>Adresse email</label>
                            <input type="email" v-model="director.email1">
                        </div>

                        <div class="form-sect form-sect-half">
                            <label>Adresse email 2</label>
                            <input type="email" v-model="director.email2">
                        </div>

                        <h3><span class="material-symbols-outlined">share_location</span>Adresse</h3>
                        <div class="form-sect form-sect-full">
                            <label>Adresse</label>
                            <input type="text" v-model="society.adress">
                        </div>

                        <div class="form-sect form-sect-half">
                            <label>Code postal</label>
                            <input type="text" v-model="society.zipcode">
                        </div>

                        <div class="form-sect form-sect-half">
                            <label>Ville</label>
                            <input type="text" v-model="society.city">
                        </div>

                        <div class="btn-wrapper">
                            <div class="save-form-btn" @click="saveModifications">Enregistrer</div>
                        </div>
                    </form>
                </modale>
            </div>

            <div class="actions-frame">
                <span class="material-symbols-outlined" @click="deleteSociety">delete_forever</span>
                <span class="material-symbols-outlined" @click="toggleContactModale">edit_square</span>
            </div>
        </section>
    
        <section class="contact-events">
            <div class="header">
                <h2 class="inframe-title">Activité commerciale</h2>
                <span class="material-symbols-outlined" @click="toggleModale">forms_add_on</span>

                <modale :show="showModale" width="60%" @toggle="toggleModale">
                    <form class="new-event-form" @submit.prevent="saveNewEvent">
                        <div class="header">
                            <select v-model="newEvent.type">
                                <option v-for="(type, key) in $store.getters.eventTypes" :key="key" :value="key">{{ type }}</option>
                            </select>

                            <input type="text" v-model="newEvent.title">

                            <input type="date" v-model="newEvent.date">
                        </div>
                        
                        <text-editor
                            id="add-text" 
                            v-model="newEvent.text"
                            height="500"
                        />
                        
                    </form>

                    <div class="btn-wrapper">
                        <div class="save-form-btn" @click="saveNewEvent">Enregistrer</div>
                    </div>
                    
                </modale>
            </div>
            
            <div class="content">
                <table class="contact-historic">
                    <tr v-for="event in ordered_events" :key="event._id" @click="selectEvent(event)">
                        <td>
                            <span class="material-symbols-outlined" v-if="event.type == 'call'">smartphone</span>
                            <span class="material-symbols-outlined" v-else-if="event.type == 'email'">forward_to_inbox</span>
                            <span class="material-symbols-outlined" v-else-if="event.type == 'meeting'">groups</span>
                            <span class="material-symbols-outlined" v-else-if="event.type == 'visio'">videocam</span>
                            <span class="material-symbols-outlined" v-else-if="event.type == 'event'">event_available</span>
                            <span class="material-symbols-outlined" v-else>unknown_med</span>
                        </td>

                        <td>{{ event.title }}</td>
                        <td>{{ dateToString(event.date) }}</td>
                        <td @click="deleteEvent(event._id)"><span class="material-symbols-outlined">backspace</span></td>
                    </tr>
                </table>

                <div class="business-detail">
                    <EventDetail
                        class="business-detail"
                        :event="selectedEvent"
                    />
                </div>
            </div>

        </section>
            
        <section>
            <h2 class="inframe-title">Connaissance client</h2>
            <div class="kyc-section">

                <div class="column-datas">
                    <h4>Fonctionnement actuel</h4>
                    <form class="datas-forms">
                        <div class="datas-sect">
                            <label>Stade de la relation commerciale</label>
                            <select class="client-status" v-model="society.status">
                                <option v-for="(status, key) in status_list" :key="key" :value="key">{{ status }}</option>
                            </select>
                        </div>

                        <div class="datas-sect">
                            <label>Big Expert ou equivalent</label>
                            <switch-ux :value="society.useConcurrent" @toggle="newValue => society.useConcurrent = newValue"/>
                        </div>

                        <div class="datas-sect">
                            <label>Facturation d'honoraires</label>
                            <switch-ux :value="society.hasFees" @toggle="newValue => society.hasFees = newValue"/>
                        </div>

                        <div class="datas-sect">
                            <label>Status</label>
                            <select class="client-status" v-model="society.jobStatus">
                                <option v-for="(status, key) in jobStatusList" :key="key" :value="key">{{ status }}</option>
                            </select>
                        </div>

                        <div class="datas-sect">
                            <label>Association</label>
                            <select class="client-status" v-model="society.asso">
                                <option v-for="(asso, key) in asso_list" :key="key" :value="key">{{ asso }}</option>
                            </select>
                        </div>

                        <div class="datas-sect">
                            <label>Origine</label>
                            <select class="client-status" v-model="society.source">
                                <option v-for="(source, key) in sources_list" :key="key" :value="key">{{ source }}</option>
                            </select>
                        </div>
                        
                    </form>
                </div>

                <div class="column-datas">
                    <h4>Arguments qui intéressent ce client : </h4>

                    <form class="datas-forms">
                        <div class="datas-sect">
                            <label>Gagner du temps</label>
                            <switch-ux :value="society.timeSaving" @toggle="newValue => society.timeSaving = newValue"/>
                        </div>

                        <div class="datas-sect">
                            <label>Tarification</label>
                            <switch-ux :value="society.interestedBuyPrice" @toggle="newValue => society.interestedBuyPrice = newValue"/>
                        </div>

                        <div class="datas-sect">
                            <label>Sortir d'un service concurrent</label>
                            <switch-ux :value="society.leaveCompetitor" @toggle="newValue => society.leaveCompetitor = newValue"/>
                        </div>

                        <div class="datas-sect">
                            <label>Support technique</label>
                            <switch-ux :value="society.technicalSupport" @toggle="newValue => society.technicalSupport = newValue"/>
                        </div>

                        <div class="datas-sect">
                            <label>Co-construction</label>
                            <switch-ux :value="society.coConstruction" @toggle="newValue => society.coConstruction = newValue"/>
                        </div>
                    </form>
                </div>

                <div class="column-datas">
                    <h4>Commandes réalisées : <span class="material-symbols-outlined" @click="toggleAddReco">add_box</span></h4>

                    <table>
                        <tr v-for="reco in recommendations" :key="reco._id">
                            <td>{{ reco.clientName }}</td>
                            <td>{{ dateToString(reco.date) }}</td>
                        </tr>
                    </table>

                    <modale :show="showAddReco" @toggle="toggleAddReco" width="40%">
                        <form @submit.prevent="" class="add-reco-form">
                            <div class="datas-sect">
                                <label>Nom des clients</label>
                                <input type="text" v-model="newReco.clientName">
                            </div>

                            <div class="datas-sect">
                                <label>Date de commande</label>
                                <input type="date" v-model="newReco.date">
                            </div>

                            <div class="btn-wrapper">
                                <div class="save-form-btn" @click="saveNewReco">Enregistrer</div>
                            </div>
                        </form>
                    </modale>
                </div>
            </div>
        </section>

        <section class="partner-style">
            <h2 class="inframe-title">Paramétrer le style</h2>

            <div class="style-frames">
                <form class="color-choices">
                    <div>
                        <label>Nom à utiliser</label>
                        <input type="text" v-model="partnerStyle.firmLabel">
                    </div>

                    <div>
                        <label>Couleur principale</label>
                        <input type="color" v-model="partnerStyle.mainColor">
                    </div>

                    <div>
                        <label>Couleur secondaire</label>
                        <input type="color" v-model="partnerStyle.secondaryColor">
                    </div>

                    <div>
                        <label>Logo</label>
                        <input type="file" ref="fileInput" @change="handleFileDrop"/>
                    </div>

                    <img :src="logoUrl" class="logo-style" alt="logo-partenaire">

                    <div>
                        <label>Mentions légales</label>
                        <textarea v-model="partnerStyle.legalInfo"></textarea>
                    </div>

                    <div class="btn-wraper">
                        <div class="save-form-btn" @click="updateStyle">Enregistrer</div>
                    </div>
                </form>

                <div class="exemple-frame">
                    <StyleExemplePages
                        :mainColor="partnerStyle.mainColor"
                        :secondaryColor="partnerStyle.secondaryColor"
                        :logoUrl="logoUrl"
                        :legalInfos="partnerStyle.legalInfo"
                    />
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios'

import EventDetail from '../elements/EventDetail.vue'
import StyleExemplePages from './StyleExemplePages.vue';

export default {
    components: { EventDetail, StyleExemplePages },
    data() {
        return {
            data_loaded: false,
            status_list: {
                0: 'Cabinet non créé',
                1: 'Non contacté',
                2: '1er contact',
                3: 'Commande imminente',
                4: 'Cient',
                5: 'Client récurrent',
                6: 'Non interéssé',
            },
            jobStatusList: {
                broaker: 'Courtier en assurance',
                cif : 'Conseiller en Investissement Financier',
                accountant: 'Expert comptable',
                notary: 'Notaire',
                loan_broaker: 'Courtier en prêt',
                immo_agent: 'Agent immobilier',
            },
            asso_list: {
                '--': undefined,
                cncgp: 'CNCGP',
                anacofi: 'ANACOFI',
                cncef: 'CNCEF',
                ccgp: 'La compagnie des CGP'
            },
            sources_list: {
                vieplus: 'Vie Plus',
                cgpe: "CGP Entrepreneur",
                relation: 'Bouche à oreille',
                social_media: 'Réseaux sociaux',
                calls: "Prospection à froid",
                friend: "Amis et famille",
                internet: "Site internet",
            },
            events: [],
            selectedEvent: {},
            director: {},
            society: {},
            showModale: false,
            showContactModale: false,
            showAddReco: false,
            newEvent: {
                directorId : undefined,
                type       : "call",
                title      : "",
                text       : "",
                date       : new Date(),
            },
            recommendations: [],
            newReco: {
                societyId: undefined,
                clientName: '',
                date: new Date()
            },
            partnerStyle: {
                _id: undefined,
                societyId: "",
                mainColor: "#1F2B3D",
                secondaryColor: "#E56765",
                legalInfo: "",
                firmLabel: "",
                logoUrl: "",
            },
        }
    },
    computed: {
        ordered_events() {
            let events = this.events
            return events.sort((a, b) => new Date(b.date) - new Date(a.date))
        },
        kyc_body() {
            return {
                status: this.society.status,
                useConcurrent: this.society.useConcurrent,
                hasFees: this.society.hasFees,
                jobStatus: this.society.jobStatus,
                asso: this.society.asso,
                source: this.society.source,
                timeSaving: this.society.timeSaving,
                interestedBuyPrice: this.society.interestedBuyPrice,
                leaveCompetitor: this.society.leaveCompetitor,
                technicalSupport: this.society.technicalSupport,
                coConstruction: this.society.coConstruction,
            }
        },
        logoUrl() {
            return this.partnerStyle.logoUrl;
        }
    },
    watch: {
        kyc_body() {
            if (this.data_loaded) {
                this.saveModifications()
            }
        },
        logoUrl() {
            if (this.data_loaded) {
                this.updateStyle();
            }
        }
    },
    methods: {
        selectEvent(event) {
            this.selectedEvent = event
        },
        toggleModale() {
            this.showModale = !this.showModale
        },
        toggleContactModale() {
            this.showContactModale = !this.showContactModale
        },
        toggleAddReco() {
            this.showAddReco = !this.showAddReco
        },
        async saveNewEvent() {
            this.newEvent.directorId = this.director._id

            await axios.post(this.$store.getters.get_api_url + 'crm/event/', this.newEvent, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.selectedEvent = this.newEvent
            this.getEvents()
            this.toggleModale()
        },
        async deleteEvent(id) {
            if (confirm('Supprimer cet évènement ? ')) {
                await axios.delete(this.$store.getters.get_api_url + 'crm/event/' + id, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })
                this.getEvents()
            }
        },
        async saveNewReco() {
            this.newReco.societyId = this.society._id

            await axios.post(this.$store.getters.get_api_url + 'crm/recommendation/', this.newReco, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.getRecommendations()
            this.toggleAddReco()
        },
        async getEvents() {
            const eventsResponse = await axios.post(this.$store.getters.get_api_url +'crm/all-events/?directorId=' + this.director._id, {}, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            this.events = eventsResponse.data
        },
        async getRecommendations() {
            const recoResponse = await axios.get(this.$store.getters.get_api_url +'crm/recommendation/' + this.society._id, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            this.recommendations = recoResponse.data
        },
        async saveModifications() {
            await axios.put(this.$store.getters.get_api_url + 'crm/society/' + this.society._id, this.society, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            await axios.put(this.$store.getters.get_api_url + 'crm/director/' + this.director._id, this.director, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.showContactModale = false;
        },
        async deleteSociety() {
            if (confirm('Êtes vous sur de vouloir supprimer ce cabinet ? ')) {
                await axios.delete(this.$store.getters.get_api_url + 'crm/society/' + this.society._id, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })
                this.$router.replace({path: '/conseillers'})
            }
        },
        async handleFileDrop(event) {
            const file = event.target.files ? event.target.files[0] : null;

            if (!file) {
                console.error("❌ Aucun fichier détecté !");
                return;
            }

            const formData = new FormData();
            formData.append("image", file);

            try {
                const response = await axios.post(this.$store.getters.get_api_url + 'upload-images/upload/', formData, {
                    headers: {
                        authorization : 'Bearer ' + this.$store.getters.get_token,
                        "Content-Type": "multipart/form-data",
                    }
                });

                this.partnerStyle.logoUrl = response.data.imageUrl;
                console.log("✅ Image uploadée avec succès :", response.data.imageUrl);
            } catch (error) {
                console.error("❌ Erreur lors de l'upload :", error);
            }
        },
        async getPartnerStyle() {
            const res = await axios.get(this.$store.getters.get_api_url + 'crm/style/' + this.society._id, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            });
    
            if (res.status == 200 && res.data) {
                this.partnerStyle = res.data
                
            } else {
                this.createPartnerStyle();
            }
        },
        async createPartnerStyle() {
            const body = {
                societyId: this.society._id,
                societyName: this.society.name,
            };

            await axios.post(this.$store.getters.get_api_url + 'crm/create-style/', body, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            });

            this.getPartnerStyle()
        },
        async updateStyle() {

            await axios.put(this.$store.getters.get_api_url + 'crm/modify-style/', this.partnerStyle, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            });

            this.getPartnerStyle()
        },
    },
    async mounted() {
        const societyResponse = await axios.get(this.$store.getters.get_api_url +'crm/society/' + this.$route.params.id, {
            headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
        });
        this.society = societyResponse.data;

        const directorResponse = await axios.get(this.$store.getters.get_api_url +'crm/director/' + this.$route.params.id, {
            headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
        });
        this.director = directorResponse.data;

        await this.getPartnerStyle();
        
        this.getEvents();
        this.getRecommendations();

        this.selectedEvent = this.events[0] || {};
        this.data_loaded = true;
    }
}
</script>

<style scoped>
.client-frame {
    position: relative;
    padding: 40px;
}
.logo {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 100px;
}
.inframe-title {
    background-color: rgba(10,28,107,.7);
    color: white;
    display: inline-block;
    margin: 0 0 10px 0;
    padding: 15px;
    border-radius: 15px 0 15px 0;
}

.client-frame section {
    position: relative;
    border-radius: 15px;
    margin: 20px 0;
    padding-bottom: 10px;
    background-color: white;
    box-shadow: 0 3px 4px rgb(0 0 0 / 5%)
}

.contact-frame {
    position: relative;
    display: inline-block;
    min-width: 60%;
}

.contact-display {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}
.contact-display .contact-column:first-child {
    border-right: 1px solid orange;
    padding-right: 10px;
}
.contact-display .contact-column:last-child {
    padding-left: 10px;
}

.contact-column {
    flex-basis: 50%;
}

.contact-column .row {
    display: flex;
    align-items: center;
    padding: 0 5px;
}

.contact-column .row span{
    font-size: 30px;
    color: rgb(1, 1, 90);
    margin-right: 10px
}

.contact-frame .actions-frame {
    position: absolute;
    bottom: 0;
    width: 40px;
    right: -80px;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.contact-frame .actions-frame span {
    cursor: pointer;
    font-size: 30px;
    margin: 5px 0;
}
.contact-frame .actions-frame span:first-child:hover {
    color: red;
}
.contact-frame .actions-frame span:hover {
    color: orange;
}

form.modale-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 10px auto;
}
form.modale-form h3 {
    color: rgb(0, 0, 80);
    flex-basis: 100%;
    display: flex;
    align-items: center;
}
form.modale-form h3 span {
    margin-right: 10px;
}
form.modale-form .form-sect {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 10px;
}
form.modale-form .form-sect-full {
    flex-basis: 100%;
}
form.modale-form .form-sect-half {
    flex-basis: 45%;
}

form.modale-form .form-sect label,
form.modale-form .form-sect input,
form.modale-form .form-sect select {
    width: 100%;
    font-family: "verdana", sans-serif;
    font-size: 14px;
    padding: 10px;
    color: rgb(61, 61, 61);
}
form.modale-form .form-sect label {
    font-weight: bold;
}
form.modale-form .form-sect input,
form.modale-form .form-sect select {
    border-radius: 5px;
    border: 1px solid lightgrey;
}

.btn-wrapper {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    padding: 5px;
}
.save-form-btn {
    cursor: pointer;
    background-color: rgba(0, 0, 80, 0.421);
    color: white;
    padding: 10px;
    border-radius: 10px;
}
.save-form-btn:hover {
    background-color: rgb(0, 0, 80);
}

/* Historique des contacts */
.contact-events .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.contact-events .header span {
    cursor: pointer;
    font-size: 45px;
    margin-right: 10px;
}
.contact-events .header span:hover {
    color: orange;
}

.contact-events .content {
    display: flex;
    align-items: flex-start;
}
table.contact-historic {
    min-width: 400px;
    border-collapse: collapse;
}
table.contact-historic tr {
    cursor: pointer;
}
table.contact-historic tr:hover {
    background-color: rgba(0, 0, 84, 0.257);
}
table.contact-historic tr td:last-child span {
    color: black;
}
table.contact-historic tr td:last-child span:hover {
    color: red;
}
table.contact-historic td span {
    margin: 5px;
    color: rgb(0,0,90);
}
.contact-events .content .business-detail {
    flex-grow: 1;
}
.datas-forms {
    display: flex;
    justify-content: space-evenly;
}

.new-event-form {
    margin: 20px;
}
.new-event-form .header {
    display: flex;
    width: 100%;
    margin: 5px 0;
}
.new-event-form .header input,
.new-event-form .header select {
    padding: 10px;
    border: 1px solid lightgrey;
}

.new-event-form .header select:first-child {
    border-radius: 5px 0 0 5px;
    border-right: none;
}
.new-event-form .header input:last-child {
    border-radius: 0 5px 5px 0;
    border-left: none;
}
.new-event-form .header input:nth-child(2) {
    flex-grow: 1;
}
.new-event-form textarea {
    min-height: 400px;
}

/* Connaissance client */
.kyc-section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px;
}

.kyc-section .column-datas {
    flex-basis: 30%;
    min-width: 400px;
}

.kyc-section .column-datas h4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 22px;
}
.kyc-section .column-datas h4 span:hover {
    cursor: pointer;
    color: orange;
}

.datas-forms {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.datas-sect {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
}

.datas-sect label {
    font-size: 14px;
    font-weight: bold;
    margin-right: 5px
}

.datas-sect input,
.datas-sect select {
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 5px;
    flex-grow: 1;
}

.add-reco-form {
    padding: 20px;
}

/* Style du partenaire */
.style-frames {
    display: flex;
    align-items: center;
}
.style-frames .color-choices {
    flex-basis: 40%;
    min-width: 400px;
    max-width: 600px;
    padding: 20px;
}

.style-frames .color-choices div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 10px 0;
}

.style-frames .color-choices label {
    font-weight: bold;
}
.style-frames .color-choices input,
.style-frames .color-choices select,
.style-frames .color-choices textarea {
    padding: 5px;
    border: 1px solid lightgrey;
    border-radius: 5px;
}
.style-frames .color-choices textarea,
.style-frames .color-choices input[type="text"] {
    flex-grow: 1;
    flex-basis: 100%;
}

.style-frames .color-choices textarea {
    font-size: 12px;
    min-height: 100px;
}

.logo-style {
    height: 80px
}

.style-frames .exemple-frame {
    flex-grow: 1;
}
</style>