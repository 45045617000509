<template>
    <div class="form-wrapper">
        <div class="form-box">
            <h4>Informations générales</h4>

            <div class="form-container">
                <div class="form-section">
                    <label for="category">Catégorie<span class="mandadory">*</span></label>
                    <select id="category" v-model="body.type" :disabled="!isOriginalScenario && !isNew">
                        <option v-for="(value, key) in $store.getters.deductionTypes" :key="key" :value="key">{{ value }}</option>
                    </select>
                </div>

                <div class="form-section">
                    <label for="value">Valeur<span class="mandadory">*</span></label>
                    <currency-input v-model="body.value" :disabled="!isOriginalScenario && !isNew"/>
                </div>
            </div>
        </div>

        <div class="btn-container">
            <div class="btn-submit" @click="submit">Enregistrer</div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['import'],
    data() {
        return {
            isNew: true,
            errorMsg: "",
            body: {
                _id: undefined,
                clientId: undefined,
                scenarioId: undefined,
                type: "legal_alimony",
                value: 0,
                isIpCreation: false,
            }
        }
    },
    watch: {
        errorMsg() {
            if (this.errorMsg != "") {
                alert(this.errorMsg);
            }
        },
    },
    methods: {
        submit() {
            if (this.isOriginalScenario) {
                this.body.isIpCreation = false
            } else if (this.isNew) {
                this.body.isIpCreation = true
            }

            if (this.body.type == undefined) {
                this.errorMsg = "Précisez le type de charge déductible"

            } else {
                this.errorMsg = ""

                if (this.isNew) {
                    this.body.addToStore = true
                    this.$store.dispatch('create_deductible_expense', this.body)
                } else {
                    this.$store.dispatch('modify_deductible_expense', this.body)
                }  

                this.$emit('closeModale')
            }
        },
    },
    computed: {
        isOriginalScenario() {
            const routeArray = this.$route.fullPath.split('/')

            if (routeArray[3] == 'profile' || this.$route.query.isOriginal) {
                return true
            } else {
                return false
            }
        },
    },
    mounted() {

        if (this.import) {
            this.isNew = false
            this.body = JSON.parse(JSON.stringify(this.import))

        } else {
            this.body.clientId = this.$route.params.clientId
            this.body.scenarioId = this.$route.params.scenarioId
        }
    }
}
</script>

<style src="./newStyle.css" scoped></style>