<template>
    <div class="list-section">
        <h1 class="section-title">
            Réductions d'impôt
            
            <add-btn type="tax_reduction"></add-btn>
        </h1>

        <table class="table-elements">
            <list-component class="list-element"
                v-for="tax_reduction in $store.getters.getAllTaxReductions"
                :key="tax_reduction._id"
                :object="tax_reduction"
                type="tax_reduction"
            />
        </table>
    </div>
</template>

<script>

export default {
    data() {
        return {}
    }
}
</script>

<style scoped src="./style.css"></style>