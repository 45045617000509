const { getAge, toEuro } = require('../assets/functions.js')

export default {
    get_api_url(state) {
        return state.api_url
    },
    get_selected_vue(state) {
        return state.selectedVue
    },
    is_loaded_scenario(state) {
        return state.is_loaded_scenario
    },
    // Admin
    get_token(state) {
        return state.auth.token
    },
    getAdminInitials(state) {
        if (Object.keys(state.admin).length > 0) {
            const firstname_initial = state.admin.firstname.split('')[0]
            const lastname_initial = state.admin.lastname.split('')[0]
            const initials = firstname_initial + lastname_initial
            return initials.toUpperCase()
        } else {
            return '-'
        }
    },
    getAdminId(state) {
        return state.auth.adminId
    },
    getAdminPosition(state) {
        return state.admin.position
    },
    // Client
    clientId(state) {
        const user = state.persons.find(p => p.role == 'user')
        return user?.clientId
    },
    scenarioId(state) {
        const user = state.persons.find(p => p.role == 'user')
        return user?.scenarioId
    },
    hasSpouse(state) {
        let hasSpouse = false
        state.persons.forEach(person => {
            if (person.role == 'spouse')
                hasSpouse = true
        })

        return hasSpouse
    },
    getPersonSpouse: (state, getters) => (id) => {
        if (getters.spouseId == id) {
            return getters.userId
        } else {
            return getters.spouseId
        }
    },
    getContract(state) {
        return JSON.parse(JSON.stringify(state.marital_contract))
    },
    getAllPersons(state) {
        const people = state.persons.map(p => {
            let r = {};
            r._id = p._id;
            r.name = p.firstname + ' ' + p.lastname
            return r;
        });

        const societies = state.societies.map(s => {
            let r = {};
            r._id = s._id;
            r.name = s.type.toUpperCase() + ' - ' + s.label
            return r;
        });

        return [...people, ...societies]
    },
    getPersons(state) {
        return state.persons
    },
    getParents(state) {
        return state.persons.filter(person => person.role == 'user' || person.role == 'spouse')
    },
    getChildren(state) {
        return state.persons.filter(person => person.role == 'child')
    },
    getPersonChildren: (state) => (id) => {
        let result = []

        state.persons.forEach(person => {
            if (person.role == 'child' && [person.parent1, person.parent2].includes(id)) {
                result.push(person._id)
            }
        })

        return result
    },
    getGrandChildren(state) {
        return state.persons.filter(person => person.role == 'grandchild')
    },
    getOthers(state) {
        return state.persons.filter(person => ['parent', 'sibling', 'nephew', 'other'].includes(person.role))
    },
    getAllWealth(state) {
        return [
            ...state.financial_assets.map(asset => ({...asset, class: 'financial_assets'})), 
            ...state.movable_assets.map(asset => ({...asset, class: 'movable_assets'})), 
            ...state.societies.map(asset => ({...asset, class: 'societies'})), 
            ...state.properties.map(asset => ({...asset, class: 'properties'})),
        ]
    },
    getAllProperties(state) {
        return state.properties
    },
    getAllCommonProperties(state, getters) {

        const common_properties = state.properties.filter(property => {
            const owners = property.detention.filter(part => part.right == 'pp').map(part => part.owner_id)
            if (owners.includes(getters.userId) && owners.includes(getters.spouseId) && !property.isIndivision) {
                return true
            } else {
                return false
            }
        })

        let common_societies = state.societies.filter(s => {
            const owners = s.detention.filter(part => part.right == 'pp').map(part => part.owner_id)
            if (owners.includes(getters.userId) && owners.includes(getters.spouseId)) {
                return true
            } else {
                return false
            }
        })

        common_societies.map(society => {
            if (society.wealthValueMode) {
                society.value = getters.getSocietyValue(society._id)
                return society
            } else {
                return society
            }
        })

        const common_assets = state.financial_assets.filter(property => {
            const owners = property.detention.filter(part => part.right == 'pp').map(part => part.owner_id)
            if (owners.includes(getters.userId) && owners.includes(getters.spouseId) && !property.isIndivision) {
                return true
            } else {
                return false
            }
        })

        const common_movable = state.movable_assets.filter(property => {
            const owners = property.detention.filter(part => part.right == 'pp').map(part => part.owner_id)
            if (owners.includes(getters.userId) && owners.includes(getters.spouseId) && !property.isIndivision) {
                return true
            } else {
                return false
            }
        })

        return [...common_properties, ...common_assets, ...common_movable, ...common_societies]
    },
    getPropertyLabel: (_state, getters) => (_id) => {
        const property = getters.getAllProperties.find(p => p._id == _id);

        if (property.label && property.label != '') {
            return property.label

        } else {
            return `${getters.propertyTypesList[property.type]} - ${toEuro(property.value)}`
        }
    },
    getAllAssetLabel: (_state, getters) => (_id) => {
        const property = getters.getAllProperties.find(p => p._id == _id);
        const asset = getters.getAllAssets.find(a => a._id == _id);
        const movable = getters.getAllMovable.find(a => a._id == _id);
        const society = getters.getAllSocieties.find(a => a._id == _id);

        if (property != undefined) {
            if (property.label && property.label != '') {
                return property.label;
    
            } else {
                return getters.propertyTypesList[property.type];
            }
        } else if (asset != undefined) {
            if (asset.label && asset.label != '') {
                return asset.label;
    
            } else {
                return getters.assetTypesList[asset.type];
            }
        } else if (movable != undefined) {
            if (movable.label && movable.label != '') {
                return movable.label;
    
            } else {
                return getters.movableTypes[movable.type];
            }
        } else if (society != undefined) {
            return getters.findSocietyName(_id);
        }
    },
    getAllAssets(state) {
        return state.financial_assets
    },
    getAllMovable(state) {
        return state.movable_assets
    },
    getAllSocieties(state) {
        return state.societies
    },
    getSocietyIdsList(state) {
        const ids = state.societies.map(s => s._id)
        return ids
    },
    getSocietyValue: (state, getters) => (_id) => {
        const society = state.societies.filter(s => s._id == _id)[0]

        if (!society.wealthValueMode) {
            return society.value

        } else {
            // Actif
            const properties = state.properties.filter(property => property.detention.map(part => part.owner_id).includes(_id))
            const assets = state.financial_assets.filter(asset => asset.detention.map(part => part.owner_id).includes(_id))
            const movable = state.movable_assets.filter(movable_asset => movable_asset.detention.map(part => part.owner_id).includes(_id))

            const assets_list = [...properties, ...assets, ...movable]

            let total_assets_value = 0
            assets_list.forEach(asset => {
                asset.detention.forEach(part => {
                    if (part.owner_id == _id && part.right != 'us') {
                        total_assets_value += part.part * asset.value
                    }
                })
            })

            const societies = state.societies.filter(asset => asset.detention.map(part => part.owner_id).includes(_id))

            societies.forEach(society => {
                let society_value = society.value

                if (society.wealthValueMode) {
                    society_value = getters.getSocietyValue(society._id)
                }

                society.detention.forEach(part => {
                    if (part.owner_id == _id && part.right != 'us') {
                        total_assets_value += part.part * society_value
                    }
                })

            })

            // Passif
            const debts_list = state.debts.filter(debt => debt.owner1 == _id)
            const cca_list = state.financial_assets.filter(asset => asset.linkedSociety == _id)

            const all_debts_list = [...debts_list.map(d => d.remaining_value), ...cca_list.map(d => d.value)]
            const total_debts_value = all_debts_list.reduce((sum, current) => sum + current, 0)
            
            // Valeur
            return Math.max(total_assets_value - total_debts_value, 0)
        }
    },
    getAllInsurances(state) {
        return state.insurances
    },
    getAllLifeInsurances(state) {
        return state.insurances.filter(i => ['life', 'lux'].includes(i.type))
    },
    getPersonLifeInsurances: (state, getters) => (id) => {
        return getters.getAllLifeInsurances.filter(i => i.owner1 == id)
    },
    getInsuranceLabel: (_state, getters) => (id) => {
        const insurance = getters.getAllInsurances.find(i => i._id == id);

        if (insurance.label) {
            return `${ insurance.label } - ${ toEuro(insurance.value) }`
        } else {
            return `${ getters.insuranceTypes[insurance.type] } - ${ toEuro(insurance.value) }`
        }
    },
    getAllDebts(state) {
        return state.debts
    },
    getDebtLabel: (_state, getters) => (id) => {
        const debt = getters.getAllDebts.find(d => d._id == id);

        if (debt.label) {
            return `${ debt.label }`
        } else {
            return `${ getters.debtTypes[debt.type] }`
        }
    },
    getAllCommonDebts(state) {
        return state.debts.filter(d => d.owner1 && d.owner2)
    },
    getAllInheritors(state) {
        let inheritors = []
        state.persons.forEach(person => {
            if (person.role != 'user' && person.role != "spouse") {
                inheritors.push(person)
            }
        })
        return inheritors
    },
    getAllGifts(state) {
        return state.gifts
    },
    getAllWills(state) {
        return state.wills
    },
    getAllRewards(state) {
        return state.rewards
    },
    getAllCommunityRewards(state) {
        return state.rewards.filter(r => r.type == 'reward')
    },
    getAllSpouseClaims(state) {
        return state.rewards.filter(r => r.type == 'debt')
    },
    getAllIncomes(state) {
        return state.incomes
    },
    getAllTaxReductions(state) {
        return state.tax_reductions
    },
    getAllDeductibleExpense(state) {
        return state.deductible_expenses
    },
    findOwnerName: (state) => (_id) => {
        let owner_name = 'commun'
        state.persons.forEach(person => {
            if (person._id == _id)
                owner_name = person.firstname + ' ' + person.lastname
        })

        state.societies.forEach(society => {
            if (society._id == _id)
                owner_name = society.type.toUpperCase() + ' - ' + society.label
        })
        return owner_name
    },
    findPersonName: (state) => (_id) => {
        let name = ''
        state.persons.forEach(person => {
            if (person._id == _id)
                name = person.firstname + ' ' + person.lastname
        })

        if (_id == 'commun') {
            name = 'Commun'
        }

        return name
    },
    findSocietyName: (state) => (_id) => {
        const society = state.societies.find(s => s._id == _id)

        return society?.type.toUpperCase() + ' - ' + society?.label.toUpperCase()
    },
    findSocietyTaxOption: (state, getters) => (_id) => {
        const society = state.societies.find(s => s._id == _id)

        return getters.taxOptions[society?.taxOption]
    },
    findSocietyAssets: (state, getters) => (_id) => {
        let result = []

        for (const asset of getters.getAllProperties) {
            if (asset.detention.map(d => d.owner_id).includes(_id)) {
                result.push(asset)
            }
        }

        return result
    },
    findPersonAge: (state) => (_id) => {
        const person = state.persons.find(person => person._id == _id)
        let age = getAge(person.birthdate)

        return age
    },
    findPersonRole: (state) => (_id) => {
        const ROLES = {
            spouse: 'Conjoint',
            child: 'Enfant',
            grandchild: 'Petit-enfant',
            parent: 'Parent',
            sibling: 'Frère/soeur',
            nephew: 'Neveu/Nièce',
            other: 'Tiers',
        }

        let role = undefined
        state.persons.forEach(person => {
            if (person._id == _id) {
                if (person.role == 'spouse') {
                    if (state.marital_contract.situation == 'married') {
                        role = 'Conjoint'

                    } else if (state.marital_contract.situation == 'pacs') {
                        role = 'Partenaire de Pacs'

                    } else {
                        role = 'Concubin'

                    }
                } else {
                    role = ROLES[person.role]
                }
            }
        })

        return role
    },
    getAssetOwner: (state, getters) => (detention, isIndivision) => {
        const detention_rights = detention.map(part => part.right)
        const detention_owners = detention.map(part => part.owner_id)

        if (!detention_rights.includes('us') && !detention_rights.includes('np')) {
            // Détention en pleine propriété
            if (detention_owners.length == 1) {
                const owner = getters.getAllPersons.find(person => person._id == detention_owners[0]);
                const part = detention[0].part == 1 ? "" : detention[0].part * 100 + '%';
                return `${owner?.name} ${part}`;

            } else if (!isIndivision && detention_owners.length == 2) {
                return 'Commun';

            } else if (isIndivision) {
                let result = '';

                for (let i = 0; i < detention.length; i++) {
                    const owner = getters.getAllPersons.find(person => person._id == detention[i].owner_id);
                    result = result + owner?.name + ' pour ' + detention[i].part * 100 + '%';

                    if (i != detention.length - 1) {
                        result = result + '; ';
                    }
                }

                return result
            }
        } else {
            // propriété démembrée
            let result = ''

            for (let i = 0; i < detention.length; i++) {
                let owner = state.persons.find(person => person._id == detention[i].owner_id)

                if (!owner) {
                    owner = getters.getAllSocieties.find(person => person._id == detention[i].owner_id)
                    result = result + owner?.label + ' ' + ' pour ' + detention[i].part * 100 + '% en ' + getters.rights[detention[i].right]
                } else {
                    result = result + owner?.firstname + ' ' + owner?.lastname + ' pour ' + detention[i].part * 100 + '% en ' + getters.rights[detention[i].right]
                }



                if (i != detention.length - 1) {
                    result = result + '; '
                }
            }

            return result
        }

        return ''
    },
    findUserBeneficiaries(state) {
        let listBeneficiaries = []

        state.persons.forEach(person => {
            if (person.role != 'user') {
                listBeneficiaries.push(person)
            }
        })

        return listBeneficiaries
    },
    findSpouseBeneficiaries(state) {
        let listBeneficiaries = []

        state.persons.forEach(person => {
            if (person.role != 'spouse') {
                listBeneficiaries.push(person)
            }
        })

        return listBeneficiaries
    },
    findOwners(state, getters) {
        let list_owners = []

        list_owners.push(getters.userId)

        if (getters.spouseId) {
            list_owners.push(getters.spouseId)

            if (['aquest_community', 'aquest_movable_community', 'universal_community'].includes(state.marital_contract.regmat) || state.marital_contract.acquest_society) {
                list_owners.push('commun')
            }
        }

        state.societies.forEach(society => {
            list_owners.push(society._id)
        })

        return list_owners
    },
    findDonors(state, getters) {
        // FindOwners a une condition de communauté dans le mariage qui ne s'applique pas aux donations
        let list_owners = []

        list_owners.push(getters.userId)

        if (getters.spouseId) {
            list_owners.push(getters.spouseId)
            list_owners.push('commun')
        }

        return list_owners
    },
    findUsers(_state, getters) {
        let list_owners = []

        list_owners.push(getters.userId)
        if (getters.spouseId) {
            list_owners.push(getters.spouseId)
        }

        return list_owners
    },
    userName(state) {
        let name = ''
        state.persons.forEach(person => {
            if (person.role == 'user') {
                name = person.firstname + ' ' + person.lastname
            }
        })

        return name
    },
    spouseName(state) {
        let name = ''
        state.persons.forEach(person => {
            if (person.role == 'spouse')
                name = person.firstname + ' ' + person.lastname
        })

        return name
    },
    userId(state) {
        const person = state.persons.find(person => person.role == 'user')
        return person?._id
    },
    spouseId(state) {
        const person = state.persons.find(person => person.role == 'spouse')
        return person?._id
    },
    getAge: (state) => (_id) => {
        let date = undefined
        state.persons.forEach(person => {
            if (person._id == _id) {
                date = new Date(person.birthdate)
            }
        })

        if (date != undefined) {
            let diff_ms = Date.now() - date.getTime();
            var age_dt = new Date(diff_ms);

            return Math.abs(age_dt.getUTCFullYear() - 1970);

        } else {
            return 0
        }
    },
    getBirthdate: (state) => (id) => {
        const person = state.persons.find(person => person._id == id)
        const array = person.birthdate.split('T')

        return array[0]
    },
    getCivility: (state) => (id) => {
        const person = state.persons.find(person => person._id == id)

        return person.civility
    },
    getActivityIncome: (state) => (id) => {
        const income = state.incomes.find(i => (i.type == 'salary' || i.type == 'art62') && i.person_id == id);
        return income?.net_value
    },
    usValue: (state, getters) => (_id) => {
        let us_value = 0
        state.persons.forEach(person => {
            if (person._id == _id) {
                const age = getters.getAge(_id)

                if (age < 21)
                    us_value = 0.9
                else if (age < 31)
                    us_value = 0.8
                else if (age < 41)
                    us_value = 0.7
                else if (age < 51)
                    us_value = 0.6
                else if (age < 61)
                    us_value = 0.5
                else if (age < 71)
                    us_value = 0.4
                else if (age < 81)
                    us_value = 0.3
                else if (age < 91)
                    us_value = 0.2
                else
                    us_value = 0.1
            }
        })
        return us_value
    },
    spouseChoices: (state) => (order) => {
        let has_ddv = undefined
        if (order == 1)
            has_ddv = state.marital_contract.ddv_userforspouse
        else if (order == 2)
            has_ddv = state.marital_contract.ddv_spouseforuser

        if (has_ddv) {
            return {
                '100%US': "Tout l'usufruit",
                'QDPP': "La quotité disponible en pleine propriété",
                '1/4PP': '1/4 en pleine propriété',
                '3/4US+1/4PP': "1/4 en pleine propriété et 3/4 de l'usufruit",
                'O%': 'Renonciation',
            }
        } else {
            return {
                '100%US': "Tout l'usufruit",
                '1/4PP': '1/4 en pleine propriété',
                'O%': 'Renonciation',
            }
        }
    },
    allowSpouseChoice(state) {
        if (state.marital_contract.situation == 'married')
            return true
        else
            return false
    },
    getMainPersons(state) {
        let result = []

        state.persons.forEach(person => {
            if (person.role == 'user' || person.role == 'spouse') {
                result.push(person._id)
            }
        })

        return result
    },
    getUserAssets(state, getters) {
        let list_assets = []

        const assets = [...state.properties, ...state.financial_assets, ...state.movable_assets, ...state.societies]

        assets.forEach(asset => {
            let isOwner = false
            asset.detention.forEach(part => {
                if (part.owner_id == getters.userId && part.right != 'us') {
                    isOwner = true
                }
            })
            if (isOwner) {
                list_assets.push(asset)
            }
        })

        return list_assets
    },
    getSpouseAssets(state, getters) {
        let list_assets = []

        const assets = [...state.properties, ...state.financial_assets, ...state.movable_assets, ...state.societies]

        assets.forEach(asset => {
            let isOwner = false
            asset.detention.forEach(part => {
                if (part.owner_id == getters.spouseId && part.right != 'us') {
                    isOwner = true
                }
            })
            if (isOwner) {
                list_assets.push(asset)
            }
        })

        return list_assets
    },
    getUserCareer(state) {
        return state.user_career
    },
    getSpouseCareer(state) {
        return state.spouse_career
    },
    hasWill: (state) => (_id) => {
        let result = false

        state.wills.forEach(will => {
            if (will.owner == _id) {
                result = true
            }
        })

        return result
    },
    findParents: (state, getters) => (role) => {
        if (role == "child") {
            return getters.findDonors

        } else if (['parent', 'sibling', 'other'].includes(role)) {
            return [getters.userId, getters.spouseId]

        } else if (role == 'grandchild') {
            let gc = []
            state.persons.forEach(person => {
                if (person.role == 'child') {
                    gc.push(person._id)
                }
            })
            return gc

        } else if (role == 'nephew') {
            let n = []
            state.persons.forEach(person => {
                if (person.role == 'sibling') {
                    n.push(person._id)
                }
            })
            return n

        } else {
            return []
        }
    },
    // Valeur du patrimoine par classe d'actif
    getTotalPropertyWealth(state, getters) {
        const properties = getters.getAllProperties

        let total = 0

        if (getters.get_selected_vue == 'all-wealth') {
            properties.forEach(asset => {
                const clean_detention = asset.detention.filter(p => p.right != 'us')
                total += clean_detention.map(p => p.part).reduce((sum, current) => sum + current, 0) * asset.value
            })
        } else if (getters.get_selected_vue == 'family-wealth') {
            properties.forEach(asset => {
                const clean_detention = asset.detention.filter(p => [getters.userId, getters.spouseId].includes(p.owner_id) && p.right != 'us')
                total += clean_detention.map(p => p.part).reduce((sum, current) => sum + current, 0) * asset.value
            })
        } else {
            // Patrimoine d'une société
            properties.forEach(asset => {
                const clean_detention = asset.detention.filter(p => [getters.get_selected_vue].includes(p.owner_id) && p.right != 'us')
                total += clean_detention.map(p => p.part).reduce((sum, current) => sum + current, 0) * asset.value
            })
        }

        return total
    },
    getTotalLiquidityWealth(state, getters) {
        const liquidities = state.financial_assets.filter(a => getters.liquidityTypes.includes(a.type))

        let total = 0

        if (getters.get_selected_vue == 'all-wealth') {
            liquidities.forEach(asset => {
                const clean_detention = asset.detention.filter(p => p.right != 'us')
                total += clean_detention.map(p => p.part).reduce((sum, current) => sum + current, 0) * asset.value
            })
        } else if (getters.get_selected_vue == 'family-wealth') {
            liquidities.forEach(asset => {
                const clean_detention = asset.detention.filter(p => [getters.userId, getters.spouseId].includes(p.owner_id) && p.right != 'us')
                total += clean_detention.map(p => p.part).reduce((sum, current) => sum + current, 0) * asset.value
            })
        } else {
            // Patrimoine d'une société
            liquidities.forEach(asset => {
                const clean_detention = asset.detention.filter(p => [getters.get_selected_vue].includes(p.owner_id) && p.right != 'us')
                total += clean_detention.map(p => p.part).reduce((sum, current) => sum + current, 0) * asset.value
            })
        }

        return total
    },
    getTotalFinancialWealth(state, getters) {
        const assets = state.financial_assets.filter(a => !getters.liquidityTypes.includes(a.type))

        let total = 0

        if (getters.get_selected_vue == 'all-wealth') {
            assets.forEach(asset => {
                const clean_detention = asset.detention.filter(p => p.right != 'us')
                total += clean_detention.map(p => p.part).reduce((sum, current) => sum + current, 0) * asset.value
            })
        } else if (getters.get_selected_vue == 'family-wealth') {
            assets.forEach(asset => {
                const clean_detention = asset.detention.filter(p => [getters.userId, getters.spouseId].includes(p.owner_id) && p.right != 'us')
                total += clean_detention.map(p => p.part).reduce((sum, current) => sum + current, 0) * asset.value
            })
        } else {
            // Patrimoine d'une société
            assets.forEach(asset => {
                const clean_detention = asset.detention.filter(p => [getters.get_selected_vue].includes(p.owner_id) && p.right != 'us')
                total += clean_detention.map(p => p.part).reduce((sum, current) => sum + current, 0) * asset.value
            })
        }

        return total
    },
    getTotalMovableWealth(state, getters) {
        const assets = getters.getAllMovable

        let total = 0

        if (getters.get_selected_vue == 'all-wealth') {
            assets.forEach(asset => {
                const clean_detention = asset.detention.filter(p => p.right != 'us')
                total += clean_detention.map(p => p.part).reduce((sum, current) => sum + current, 0) * asset.value
            })
        } else if (getters.get_selected_vue == 'family-wealth') {
            assets.forEach(asset => {
                const clean_detention = asset.detention.filter(p => [getters.userId, getters.spouseId].includes(p.owner_id) && p.right != 'us')
                total += clean_detention.map(p => p.part).reduce((sum, current) => sum + current, 0) * asset.value
            })
        } else {
            // Patrimoine d'une société
            assets.forEach(asset => {
                const clean_detention = asset.detention.filter(p => [getters.get_selected_vue].includes(p.owner_id) && p.right != 'us')
                total += clean_detention.map(p => p.part).reduce((sum, current) => sum + current, 0) * asset.value
            })
        }

        return total
    },
    getTotalSocietyWealth(state, getters) {
        const assets = getters.getAllSocieties.map(s => {
            let value = s.value
            if (s.wealthValueMode) {
                value = getters.getSocietyValue(s._id)
            }

            return {...s, value: value}
        })

        let total = 0

        if (getters.get_selected_vue == 'all-wealth') {
            assets.forEach(asset => {
                const clean_detention = asset.detention.filter(p => p.right != 'us')
                total += clean_detention.map(p => p.part).reduce((sum, current) => sum + current, 0) * asset.value
            })
        } else if (getters.get_selected_vue == 'family-wealth') {
            assets.forEach(asset => {
                const clean_detention = asset.detention.filter(p => [getters.userId, getters.spouseId].includes(p.owner_id) && p.right != 'us')
                total += clean_detention.map(p => p.part).reduce((sum, current) => sum + current, 0) * asset.value
            })
        } else {
            // Patrimoine d'une société
            assets.forEach(asset => {
                const clean_detention = asset.detention.filter(p => [getters.get_selected_vue].includes(p.owner_id) && p.right != 'us')
                total += clean_detention.map(p => p.part).reduce((sum, current) => sum + current, 0) * asset.value
            })
        }

        return total
    },
    getTotalInsuranceWealth(state, getters) {
        // Les sociétés ne peuvent pas avoir d'assurances
        const assets = state.insurances

        let total = 0

        if (getters.get_selected_vue == 'all-wealth' || getters.get_selected_vue == 'family-wealth') {
            assets.forEach(asset => {
                total += asset.value
            })
        }

        return total
    },
    getTotalDebtsValue(state, getters) {
        const society_ids = getters.getAllSocieties.map(s => s._id)
        const debts = getters.getAllDebts

        let total = 0

        if (getters.get_selected_vue == 'all-wealth') {
            debts.forEach(debt => {
                total += debt.remaining_value
            })
        } else if (getters.get_selected_vue == 'family-wealth') {
            debts.filter(d => !society_ids.includes(d.owner1)).forEach(debt => {
                total += debt.remaining_value
            })
        } else {
            // Dettes d'une société
            debts.filter(d => d.owner1 == getters.get_selected_vue).forEach(debt => {
                total += debt.remaining_value
            })

            const all_cca = getters.getAllAssets.filter(a => a.type == 'cca')

            all_cca.filter(cca => cca.linkedSociety == getters.get_selected_vue).forEach(cca => {
                total += cca.value
            })
        }

        return total
    },
    // Nombre d'éléments
    numberUsers(_state, getters) {
        return getters.getParents.length
    },
    numberChildren(_state, getters) {
        return getters.getChildren.length
    },
    numberGrandChildren(_state, getters) {
        return getters.getGrandChildren.length
    },
    numberOthers(_state, getters) {
        return getters.getOthers.length
    },
    numberSocieties(_state, getters) {
        return getters.getAllSocieties.length
    },
    numberProperties(_state, getters) {
        return getters.getAllProperties.length
    },
    numberAssets(_state, getters) {
        return getters.getAllAssets.length
    },
    numberMovables(_state, getters) {
        return getters.getAllMovable.length
    },
    numberInsurances(_state, getters) {
        return getters.getAllInsurances.length
    },
    numberDebts(_state, getters) {
        return getters.getAllDebts.length
    },
    numberGifts(_state, getters) {
        return getters.getAllGifts.length
    },
    numberWills(_state, getters) {
        return getters.getAllWills.length
    },
    numberIncomes(_state, getters) {
        return getters.getAllIncomes.length
    },
    numberTaxReductions(_state, getters) {
        return getters.getAllTaxReductions.length
    },
    numberTaxDeductions(_state, getters) {
        return getters.getAllDeductibleExpense.length
    },
    // Simulateurs
    legacyDatas: (state, getters) => (order) => {

        let stateCopy = JSON.parse(JSON.stringify(state))

        let datas = {
            marital_contract: stateCopy.marital_contract,
            persons: stateCopy.persons,
            properties: stateCopy.properties,
            financial_assets: stateCopy.financial_assets,
            societies: [],
            movable_assets: stateCopy.movable_assets,
            insurances: stateCopy.insurances,
            gifts: stateCopy.gifts,
            debts: stateCopy.debts,
            wills: stateCopy.wills,
            rewards: stateCopy.rewards
        }

        stateCopy.societies.forEach(society => {
            society.value = getters.getSocietyValue(society._id)
            datas.societies.push(society)
        })

        if (order == 2) {
            datas.persons.forEach(person => {
                if (person.role == "user")
                    person.role = 'spouse'
                else if (person.role == "spouse")
                    person.role = 'user'
            })

            datas.rewards.forEach(reward => {
                // Nécessité d'inverser le sens des récompenses également
                if (reward.direction == 'userToSpouse') {
                    reward.direction = 'spouseToUser'

                } else if (reward.direction == 'spouseToUser') {
                    reward.direction = 'userToSpouse'

                } else if (reward.direction == 'userToCommunity') {
                    reward.direction = 'spouseToCommunity'
                    
                } else if (reward.direction == 'spouseToCommunity') {
                    reward.direction = 'userToCommunity'
                    
                } else if (reward.direction == 'communityToUser') {
                    reward.direction = 'communityToSpouse'
                    
                } else if (reward.direction == 'communityToSpouse') {
                    reward.direction = 'communityToUser'
                    
                }
            })
        }

        return datas
    },
    ifiDatas(state) {
        let stateCopy = JSON.parse(JSON.stringify(state))

        let datas = {
            persons: stateCopy.persons.map(p => {
                return {
                    _id: p._id,
                    role: p.role,
                    firstname: p.firstname,
                    lastname: p.lastname,
                    birthdate: p.birthdate,
                    is_dead: p.is_dead,
                    joint_custody: p.joint_custody,
                }
            }),
            properties: stateCopy.properties.map(p => {
                return {
                    _id: p._id,
                    label: p.label,
                    type: p.type,
                    tenant_company: p.tenant_company,
                    value: p.ifi_value || p.value,
                    detention: p.detention,
                    opening_date: p.opening_date,
                    isfixedTermDismemberment: p.isfixedTermDismemberment,
                    dismembermentDuration: p.dismembermentDuration,
                }
            }),
            societies: stateCopy.societies.map(s => {
                return {
                    _id: s._id,
                    label: s.label,
                    type: s.type,
                    activity: s.activity,
                    wealthValueMode: s.wealthValueMode,
                    value: s.value,
                    detention: s.detention,
                    taxOption: s.taxOption,
                }
            }),
            assets: stateCopy.financial_assets.filter(a => a.type != 'cca').map(a => {
                return {
                    _id: a._id,
                    label: a.label,
                    type: a.type,
                    value: a.value,
                    detention: a.detention,
                    linkedSociety: a.linkedSociety,
                }
            }),
            shareholders_accounts: stateCopy.financial_assets.filter(a => a.type == 'cca').map(a => {
                return {
                    _id: a._id,
                    value: a.value,
                    detention: a.detention,
                    linkedSociety: a.linkedSociety,
                    opening_date: a.opening_date,
                    is_property_loan: a.cca_for_property,
                }
            }),
            movables: stateCopy.movable_assets.map(a => {
                return {
                    _id: a._id,
                    label: a.label,
                    type: a.type,
                    value: a.value,
                    detention: a.detention,
                }
            }),
            debts: stateCopy.debts.map(d => {
                return {
                    _id: d._id,
                    label: d.label,
                    type: d.type,
                    value: d.value,
                    owner1: d.owner1,
                    owner2: d.owner2,
                    refund_type: d.refund_type,
                    start_date: d.start_date,
                    end_date: d.end_date,
                    rate: d.rate,
                    insurance_rate: d.insurance_rate,
                    property_id: d.property_id,
                }
            }),
            incomes: stateCopy.incomes.map(i => {
                return {
                    _id: i._id,
                    type: i.type,
                    job_status: i.job_status,
                    net_value: i.net_value,
                    person_id: i.person_id,
                    linked_society: i.linked_society,
                }
            }),
            insurances: stateCopy.insurances.map(i => {
                return {
                    _id: i._id,
                    label: i.label,
                    type: i.type,
                    opening_date: i.opening_date,
                    value: i.value,
                    owner1: i.owner1,
                    real_estate_uc_percent: i.real_estate_uc_percent / 100,
                }
            }),
        }

        return datas
    },
    taxesDatas(state) {

        return {
            persons: {
                taxpayers: state.persons
                    .filter(p => 
                        p.role == 'user' 
                        || ( p.role == 'spouse' && ['married', 'pacs'].includes(state.marital_contract.situation) )
                    ).map(p => {
                        return {
                            id: p._id,
                            firstname: p.firstname,
                            lastname: p.lastname,
                            birthdate: p.birthdate,
                            is_widowed: state.marital_contract.situation == 'widowed' ? true : false,
                            lives_alone: false,
                            has_supported_adult_children: false,
                            has_dead_child: false,
                            has_military_disability_pension: false,
                            has_Work_accident_disability_pension: false,
                            has_cmi_card_with_disability: false,
                            has_adopted_child_with_exclusive_support: false,
                            is_senior_veteran_or_surviving_spouse: p.is_veteran,
                        }
                    }),
                children: state.persons
                    .filter(p => p.role == 'child')
                    .map(p => {
                        return {
                            id: p._id,
                            firstname: p.firstname,
                            lastname: p.lastname,
                            birthdate: p.birthdate,
                            is_attached: p.is_attached,
                            joint_custody: p.joint_custody,
                            lives_at_home: p.lives_at_home,
                            situation: p.situation,
                            is_student: p.is_student,
                            education_level: p.education_level,
                            has_cmi_card_with_disability: p.has_cmi_card_with_disability,
                        }
                    }),
                others: [
                    ...state.persons
                        .filter(p => p.role == 'grandchild')
                    ]
            },
            incomes: []
        }
    },
    investmentDatas: (state, getters) => (duration) => {
        let formated_properties = []
        const properties = state.properties.filter(p => ['location', 'scpi', 'parking', 'office', 'warehouse'].includes(p.type))
        properties.forEach(property => {

            // Valeur
            let sumCouple = 0
            property.detention.forEach(part => {
                if (part.owner_id == getters.userId) {
                    sumCouple += property.value * part.part

                } else if (part.owner_id == getters.spouseId) {
                    sumCouple += property.value * part.part
                }
            })

            if (sumCouple > 0) {
                let formated_property = JSON.parse(JSON.stringify(property))
                formated_property.value = sumCouple
                formated_properties.push(formated_property)
            }
        })

        return {
            duration: duration,
            insurances: state.insurances,
            properties: formated_properties,
        }
    },
    loanCapacityDatas: (state, getters) => (loan_data, coefficients) => {
        const TRAD_INCOME_TYPE = {
            art62: "gerance",
            salary: 'salaire',
            lmnp: 'bic_location',
            lmp: 'bic_location',
            bic1: 'bic_commerçant',
            bic2: "bic_artisan",
            bnc: 'bnc',
            ba: 'ba',
            retirement: 'retraite',
            property_income: 'foncier',
            dividend: "dividende",
            alimony: "pension",
        };

        const body = {
            revenus: state.incomes
                .filter(i => [getters.userId, getters.spouseId].includes(i.person_id))
                .map(i => {
                    return {
                        id: i._id,
                        type: TRAD_INCOME_TYPE[i.type],
                        net_annuel: i.net_value,
                    }
                }),
            charges: state.debts
                .filter(d => [d.owner1, d.owner2].includes(getters.userId) || [d.owner1, d.owner2].includes(getters.spouseId))
                .map(d => {
                    return {
                        id: d._id,
                        type: 'emprunt',
                        paiement_mensuel: d.monthly_payment || 0,
                    }
                }),
            emprunt: loan_data || {},
            coefficients: coefficients || {},
        }

        return body;
    },
    // Données partagées 
    allOwnersObject(state) {
        let result = {}

        const clients = state.persons.filter(person => ['user', 'spouse'].includes(person.role))

        clients.forEach(client => {
            result[client._id] = client.firstname + ' ' + client.lastname
        })

        state.societies.forEach(society => {
            result[society._id] = society.type.toUpperCase() + ' - ' + society.label
        })

        return result
    },
    maritalSituations() {
        return {
            free_union: 'En concubinage',
            pacs: "Pacsés",
            married: "Mariés",
            alone: "Célibataire",
            widowed: "Veuf",
            divorced: "Divorcé"
        }
    },
    maritalContracts() {
        return {
            married: {
                aquest_community: 'communauté réduite aux acquêts',
                aquest_movable_community: 'communauté de meubles et acquêts',
                universal_community: 'communauté universelle',
                acquest_participation: 'participation aux acquêts',
                separation: 'séparation des biens',
            },
            pacs: {
                separation: 'séparation des patrimoines',
                indivision: 'indivision'
            },
            free_union: {},
        }
    },
    allRoles() {
        return {
            user: 'Utilisateur',
            spouse: 'Conjoint',
            child: 'Enfant',
            grandchild: 'Petit-enfant',
            sibling: 'Frère/Soeur',
            parent: 'Parent',
            nephew: 'Neveu/Nièce',
            other: 'Tiers'
        }
    },
    civilities() {
        return {
            mr: 'Monsieur',
            mrs: 'Madame',
        }
    },
    eductionLevels() {
        return {
            EM: "Ecole maternelle",
            EP: "Ecole primaire",
            C: "Collège",
            L: "Lycée",
            ES: "Études supérieures",
        }
    },
    allTypes(_state, getters) {
        return { ...getters.propertyTypesList, ...getters.assetTypesList, ...getters.societyTypes, ...getters.movableTypes }
    },
    propertyCategories() {
        return {
            residential: 'Résidentiel',
            yield: 'Rendement',
            pro: 'Biens professionnels',
        }
    },
    propertyTypes() {
        return {
            residential: {
                main_property: 'Résidence principale',
                secondary: "Résidence secondaire",
            },
            yield: {
                location: "Bien locatif",
                scpi: "Parts de SCPI",
                parking: "Place de parking",
                land: "Terrain",
                agricultural_land: "Foncier agricole",
                forest: "Bois et forêts",
                gfa: "Part de GFA",
                gff: "Parts de GFF",
                gfv: "Parts de GFV",
            },
            pro: {
                office: "Bureaux",
                warehouse: "Entrepôt",
                shop: "Magasin",
                factory: "Usine",
                other: "Autre"
            },
        }
    },
    propertyTypesList(_state, getters) {
        const typeObject = getters.propertyTypes
        let result = {}
        Object.keys(typeObject).forEach(category => {
            Object.keys(typeObject[category]).forEach(type => {
                result[type] = typeObject[category][type]
            })
        })

        return result
    },
    assetCategories() {
        return {
            bank: 'Épargne bancaire',
            stock: 'Investissements financiers',
            retirement: 'Retraite',
            fiscal_fund: 'Fonds fiscaux'
        }
    },
    assetTypes() {
        return {
            bank: {
                cc: 'Compte courant',
                livreta: 'Livret A',
                ldds: "LDDS",
                csl: "Compte sur livret",
                cat: "Compte à terme",
                pel: "PEL",
                cel: "CEL",
                lep: "LEP",
                social_share: 'Parts sociales bancaires',
                pep: "plan épargne populaire",
                other: "Autre"
            },
            stock: {
                capi: "Contrat de capitalisation",
                cto: "Titres financiers",
                pea: "PEA",
                crypto: "Cryptomonnaies",
                goodwill: "Fonds de commerce",
                ei: "Entreprise individuelle",
                pee: "PEE",
                cca: "Compte courant d'associé",
            },
            retirement: {
                perin: "PER compte-titre",
                percol: "PER Collectif bancaire",
                pero: "PER Obligatoire bancaire",
                perco: "Plan d'Épargne Retraite Collectif"

            },
            fiscal_fund: {
                fcpi: "FCPI",
                fip: "FIP",
                fcpr: "FCPR",
                fpci: "FPCI",
                eltif: "ELTIF"
            }
        }
    },
    assetTypesList(_state, getters) {
        const typeObject = getters.assetTypes
        let result = {}
        Object.keys(typeObject).forEach(category => {
            Object.keys(typeObject[category]).forEach(type => {
                result[type] = typeObject[category][type]
            })
        })

        return result
    },
    liquidityTypes() {
        return ['cc', 'livreta', 'ldds', 'csl', 'cat', 'pel', 'cel', 'lep', 'social_share']
    },
    societyTypes() {
        return {
            sarl: 'SARL',
            sas: 'SAS',
            selarl: "SELARL",
            snc: "SNC",
            scp: "SCP",
            sa: "SA",
            sasu: "SASU",
            eurl: "EURL",
            sci: "SCI",
            gfa: "GFA Familial",
            other: "Autre"
        }
    },
    societyActivities() {
        return {
            commercial: "Commerciale",
            craft: "Artisanale",
            agricultural: "Agricole",
            liberal: "Libérale",
            industrial: "Industrielle",
            active_holding: "Holding animatrice",
            other: "Holding patrimoniale",
        }
    },
    taxOptions() {
        return {
            ir: "Translucidité",
            is: "Impôt sur les sociétés",
        }
    },
    movableTypes() {
        return {
            car: 'Voiture',
            furnitures: 'Meubles',
            art_objects: "Objet d'art",
            precious_stones: "Pierres précieuses",
            precious_metals: "Metaux précieux",
            plane: "Avion",
            boat: "Bateau",
            horse: "Chevaux",
            other: "Autre",
        }
    },
    insuranceTypes() {
        return {
            life: 'Assurance vie',
            lux: "Assurance vie Luxembourgeoise",
            pep: "Plan d'Épargne Populaire (PEP)",
            perin: 'PER Individuel',
            percol: 'PER Collectif (Pacte)',
            pero: 'PER Obligatoire',
            perp: 'PERP',
            madelin: 'Madelin',
            art83: "Article 83",
        }
    },
    debtTypes() {
        return {
            insured_loan: 'Emprunt à la consommation assuré',
            uninsured_loan: 'Emprunt à la consommation non assuré',
            property_loan: 'Emprunt immobilier',
            property_collateral_loan: 'Emprunt immobilier avec nantissement',
            professionnal_loan: 'Emprunt professionnel',
        }
    },
    refundTypes() {
        return {
            amortization: "Mensualité constante",
            infine: "In Fine",
        }
    },
    giftTypes() {
        return {
            property: 'Donation immobilière',
            financial: 'Donation financière',
            society: 'Donation de société',
            dutreil: 'Donation avec pacte Dutreil',
            movable: 'Donation mobilière',
            liquidity: 'Don familial de liquidités',
            manual: 'Don manuel',
        }
    },
    rights() {
        return {
            pp: 'Pleine propriété',
            us: 'Usufruit',
            np: 'Nue-propriété'
        }
    },
    incomeTypes() {
        return {
            art62: 'Remunération de gérant majoritaire',
            salary: 'Traitements et salaires',
            lmnp: 'Revenus de LMNP',
            lmp: 'Revenus de LMP',
            bic1: 'BIC - Activité de services',
            bic2: "BIC - Activité d'achat revente",
            bnc: 'Bénéfices non commerciaux',
            ba: 'Bénéfices agricoles',
            retirement: 'Pension de retraite',
            property_income: 'Revenus fonciers',
            dividend: "Dividendes reçus",
            financial_income: "Plus-value mobilière",
            alimony: "Pension alimentaire reçue",
        }
    },
    jobStatus() {
        return {
            sarl: {
                manager: 'Gérant',
                employee: 'Salarié',
                associate: "Associé non opérationnel",
            },
            sas: {
                president: 'Président',
                director: 'Directeur général',
                chairman_supervisory: "Président du conseil de surveillance",
                board_member: "Membre du directoire",
                employee: "Salarié",
                associate: "Associé non opérationnel",
            },
            selarl: {
                manager: 'Gérant',
                associate: 'associé libéral',
            },
            snc: {
                manager: 'Gérant',
                associate: "Associé en nom",
                employee: 'Salarié',
            },
            scp: {
                manager: 'Gérant',
                associate: "Associé non opérationnel",
                employee: 'Salarié',
            },
            sa: {
                president: 'Président',
                director: 'Directeur général',
                chairman_supervisory: "Président du conseil de surveillance",
                board_member: "Membre du directoire",
                employee: "Salarié",
                associate: "Associé non opérationnel",
            },
            sasu: {
                president: 'Président',
                director: 'Directeur général',
                employee: "Salarié",
                associate: "Associé non opérationnel",
            },
            eurl: {
                manager: 'Gérant',
                employee: 'Salarié',
                associate: "Associé non opérationnel",
            },
            sci: {
                manager: 'Gérant',
                employee: 'Salarié',
                associate: "Associé non opérationnel",
            },
            gfa: {
                manager: 'Gérant',
                employee: 'Salarié',
                associate: "Associé non opérationnel",
            },
        }
    },
    reductionTypes() {
        return {
            FCPI: 'FCPI',
            FIP: 'FIP',
            SOFICA: "Sofica",
            PINEL: 'Pinel',
            MALRAUX: 'Malraux',
            PEOPLE_IN_DIFFICULTY: "Organismes d'aide aux personnes en difficulté",
            GENERAL_INTEREST_ORGANIZATIONS: "Organisme d'intérêt général ou d'utilité publique",
            HOME_WORKER: "Emploi de salariés à domicile",
        }
    },
    deductionTypes() {
        return {
            alimony: 'Autre pension alimentaire',
            PER: 'Epargne retraite déductible',
        }
    },
    willTypes() {
        return {
            universal: "Legs universel",
            legs: "Legs"
        }
    },
    rewardTypes() {
        return {
            reward: "Récompense",
            debt: "Créance entre époux",
        }
    },
    rewardLabels() {
        return {
            construction_on_field: {
                label: "Constructuction sur un terrain",
                calculation_method: "max",
            },
            real_estate_acquisition: {
                label: "Financement d'une partie d'un bien",
                calculation_method: "max",
            },
            work_fund_necessary: {
                label: "Financement de travaux nécessaires",
                calculation_method: "max",
            },
            work_fund_unnecessary: {
                label: "Financement d'autres travaux",
                calculation_method: "max",
            },
            debt_payment_acquisition: {
                label: "Paiement d'une dette d'acquisition (hors RP)",
                calculation_method: "max",
            },
            debt_payment_other: {
                label: "Paiement d'une autre dette (ex: amende)",
                calculation_method: "expense_made",
            },
            dmtg_payment: {
                label: "Paiement d'impôt de donation ou succession",
                calculation_method: "max",
            },
            confusion: {
                label: "Confusion de fonds propres dans le patrimoine commun",
                calculation_method: "expense_made",
            },
            other: {
                label: "Autre créance",
                calculation_method: "value",
            },
        }
    },
    rewardDirections() {
        return [
            {name: "userToSpouse", type: 'debt', label: "Utilisateur doit à conjoint"},
            {name: "spouseToUser", type: 'debt', label: "Conjoint doit à utilisateur"},
            {name: "userToCommunity", type: 'reward', label: "Utilisateur doit à la communauté"},
            {name: "spouseToCommunity", type: 'reward', label: "Conjoint doit à la communauté"},
            {name: "communityToUser", type: 'reward', label: "Communauté doit à l'utilisateur"},
            {name: "communityToSpouse", type: 'reward', label: "Communauté doit à l'époux"},
        ]
    },
    getRewardDirectionLabel: (_state, getters) => (name) => {
        const direction = getters.rewardDirections.find(rd => rd.name == name)
        return direction.label
    },
    simulationRemunerationTypes() {
        return {
            'tns-rem-full': 'TNS - 100% Rémunération',
            'tns-dividend-full': 'TNS - 100% Dividendes',
            'tns-mix': 'TNS - Mixte rémunération / dividendes',
            'as-rem-full': 'AS - 100% Rémunération',
            'as-dividend-full': 'AS - 100% Dividendes',
            'as-mix': 'AS - Mixte rémunération / dividendes',
        }

    },
    eventTypes() {
        return {
            call: 'Appel',
            email: 'Email',
            meeting: 'Rendez-vous',
            visio: 'Visio',
            event: 'Evènement',
            action: "Action",
        }
    },
    socialStatus() {
        return {
            craftman: "Artisan",
            sale: "Commerçant",
            employee: "Assimilé salarié",
            doctor: "Médecin (hors chirurgien dentiste)",
        }
    },
    partnerStyles(state) {
        return state.partnerStyles;
    },
    partnerStylesOptions(state, getters) {
        let result = [];

        Object.keys(getters.partnerStyles).forEach(society_id => {
            result.push({
                key: society_id,
                label: getters.partnerStyles[society_id],
            })
        });

        return result;
    }
}