<template>
    <div class="list-section">
        <h1 class="section-title">
            Récompenses et créances entre époux
            
            <add-btn type="reward"></add-btn>
        </h1>

        <table class="table-elements">
            <list-component class="list-element"
                v-for="reward in $store.getters.getAllRewards"
                :key="reward._id"
                :object="reward"
                type="reward"
            />
        </table>
    </div>
</template>

<script>
export default {

    data() {
        return {}
    }
}
</script>

<style scoped src="./style.css"></style>