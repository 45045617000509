<template>
   <div>
        <div class="menu">
            <div :class="{'menu-selection': !show_detailed_result}" @click="show_detailed_result=false">Données de simulation</div>
            <div :class="{'menu-selection': show_detailed_result, 'menu-disabled': computationResult == undefined}" @click="computationResult ? show_detailed_result=true : null">Résultat détaillé</div>
        </div>

        <div class="calculator-frame">

            <div class="left-section" v-if="!show_detailed_result">
                <div class="section-element">
                    <h3>Revenus du foyer</h3>

                    <table>
                        <tr>
                            <th>Type de revenu</th>
                            <th>Montant annuel</th>
                        </tr>

                        <tr v-for="income in incomes" :key="income.id">
                            <td>
                                <select v-model="income.type">
                                    <option v-for="(value, key) in incomeTypes" :value="key" :key="key">{{ value }}</option>
                                </select>
                            </td>

                            <td><currency-input v-model="income.net_annuel"/></td>
                        </tr>

                        <tr class="add-btn-row">
                            <td colspan="2" @click="addIncome">Ajouter</td>
                        </tr>
                    </table>
                </div>

                <div class="section-element">
                    <h3>Charges du foyer</h3>

                    <table>
                        <tr>
                            <th>Type de charge</th>
                            <th>Montant mensuel</th>
                        </tr>

                        <tr v-for="charge in charges" :key="charge.id">
                            <td>
                                <select v-model="charge.type">
                                    <option v-for="(value, key) in chargesTypes" :value="key" :key="key">{{ value }}</option>
                                </select>
                            </td>

                            <td><currency-input v-model="charge.paiement_mensuel"/></td>
                        </tr>

                        <tr class="add-btn-row">
                            <td colspan="2" @click="addCharge">Ajouter</td>
                        </tr>
                    </table>
                </div>

                <div class="section-element">
                    <h3>Données de l'emprunt</h3>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Durée de l'emprunt en mois</label>
                            <input type="number" v-model="loan_data.nb_mois">
                        </div>

                        <div class="input-frame">
                            <label>Achat de la résidence principale</label>
                            <select v-model="loan_data.achat_rp">
                                <option :value="true">OUI</option>
                                <option :value="false">NON</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Taux d'emprunt</label>
                            <percent-input v-model="loan_data.taux"/>
                        </div>

                        <div class="input-frame">
                            <label>Coût de l'assurance</label>
                            <percent-input v-model="loan_data.taux_assurance"/>
                        </div>
                    </div>
                </div>

                <div class="section-element">
                    <h3>Coefficients applicables aux revenus</h3>

                    <table>
                        <tr>
                            <th>Type de charge</th>
                            <th>Montant mensuel</th>
                        </tr>

                        <tr v-for="(value, key) in coeffs" :key="key">
                            <td>{{ trad_coeff[key] }}</td>
                            <td><percent-input v-model="coeffs[key]"/></td>
                        </tr>
                    </table>
                </div>

                <div class="section-element btn-section">
                    <div class="btn-send-computation btn-grow" @click="computeResult">Calculer</div>
                </div>
            </div>

            <div class="left-section" v-else>
                <div class="section-element">
                    <h3>Détail du calcul</h3>

                    <table>
                        <tr>
                            <th>Taux d'emprunt total</th>
                            <td>{{ toPercent(computationResult.resultat.taux_annuel_total) }}</td>
                        </tr>

                        <tr>
                            <th>Durée de l'emprunt</th>
                            <td>{{ computationResult.resultat.duree }} mois</td>
                        </tr>

                        <tr>
                            <th>Mensualité maximale</th>
                            <td>{{ toEuro( computationResult.resultat.mensualite_max) }}</td>
                        </tr>

                        <tr>
                            <th>Taux d'endettement actuel</th>
                            <td>{{ toPercent(computationResult.resultat.taux_endettement_actuel) }}</td>
                        </tr>

                        <tr>
                            <th>Mensualité maximale restante</th>
                            <td>{{ toEuro( computationResult.resultat.mensualite_max_restante) }}</td>
                        </tr>

                        <tr>
                            <th>Capacité d'emprunt sur {{ computationResult.resultat.duree }} mois</th>
                            <td>{{ toEuro( computationResult.resultat.capacite_emprunt) }}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="right-section">
                <div class="section-element" v-if="!computationResult">
                    <h3>Résultats</h3>

                    <img src="../ressources/img/illustration.png"/>

                    <p>Lancez le calcul pour obtenir un résultat</p>
                </div>

                <div class="section-element" v-if="computationResult">
                    <h3>Capacité d'emprunt</h3>
                    <h4>{{ toEuro(computationResult.resultat.capacite_emprunt) }}</h4>
                </div>

                <div class="section-element" v-if="computationResult">
                    <h3>Taux d'endettement actuel</h3>
                    <h4>{{ toPercent(computationResult.resultat.taux_endettement_actuel) }}</h4>
                </div>
            </div>
        </div> 
   </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            show_detailed_result: false, 

            incomeTypes: {
                salaire: "Traitements et salaires",
                foncier: "Revenus fonciers",
                bic_location: "Revenus de location meublée",
                bic_artisan: "BIC artisans",
                bic_commerçant: "BIC commerçants",
                gerance: "Revenus de gérance (art 62)",
                dividende: "Dividendes",
                bnc: "Bénéfices non commerciaux",
                retraite: "Pension de retraite",
            },
            chargesTypes: {
                emprunt: "Emprunt",
                loyer_rp: "Loyer résidence principale",
                loyer: "Autre loyer",
            },
            incomes: [],
            charges: [],
            loan_data: {
                nb_mois: 240,
                taux: 2,
                taux_assurance: 0.5,
                achat_rp: false,
            },
            trad_coeff: {
                coeff_salaire: "Salaires",
                coeff_foncier: "Revenus fonciers",
                coeff_bic_location: "Location meublée",
                coeff_bic_artisan: "BIC artisans",
                coeff_bic_commerçants: "BIC commerçants",
                coeff_dividendes: "Dividendes",
                coeff_bnc: "Bénéfices non commerciaux",
                coeff_retraite: "Pensions de retraite",
                coeff_gerance: "Revenus de gérance",
            },
            coeffs: {
                coeff_salaire: 100,
                coeff_foncier: 80,
                coeff_bic_location: 80,
                coeff_bic_artisan: 50,
                coeff_bic_commerçants: 50,
                coeff_dividendes: 50,
                coeff_bnc: 50,
                coeff_retraite: 100,
                coeff_gerance: 100,
            },
            computationResult: undefined,
        }
    },
    computed: {
        simulatorPayload() {
            return {
                revenus: this.incomes,
                charges: this.charges,
                emprunt: {
                    nb_mois: this.loan_data.nb_mois,
                    taux: this.loan_data.taux / 100,
                    taux_assurance: this.loan_data.taux_assurance / 100,
                    achat_rp: this.loan_data.achat_rp,
                },
                coefficients: {
                    coeff_salaire: this.coeffs.coeff_salaire / 100,
                    coeff_foncier: this.coeffs.coeff_foncier / 100,
                    coeff_bic_location: this.coeffs.coeff_bic_location / 100,
                    coeff_bic_artisan: this.coeffs.coeff_bic_artisan / 100,
                    coeff_bic_commerçants: this.coeffs.coeff_bic_commerçants / 100,
                    coeff_dividendes: this.coeffs.coeff_dividendes / 100,
                    coeff_bnc: this.coeffs.coeff_bnc / 100,
                    coeff_retraite: this.coeffs.coeff_retraite / 100,
                    coeff_gerance: this.coeffs.coeff_gerance / 100,
                },
            }
        },
    },
    methods: {
        addIncome() {
            const maxId = Math.max(this.incomes.map(i => i.id)) || 1;
            this.incomes.push({
                id: maxId + 1,
                type: "salaire",
                net_annuel: 0,
            })
        },
        addCharge() {
            const maxId = Math.max(this.charges.map(i => i.id)) || 1;
            this.charges.push({
                id: maxId + 1,
                type: "emprunt",
                paiement_mensuel: 0,
            })
        },
        async computeResult() {
            const res = await axios.post(this.$store.getters.get_api_url + 'simulators/loan-capacity', this.simulatorPayload, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.computationResult = res.data
        }
    }
}
</script>

<style src="./style.css" scoped></style>

<style scoped>
.section-element table {
    border-collapse: collapse;
}
.section-element table th, 
.section-element table td
{
    padding: 5px;
    font-size: 14px;
}
.section-element table th {
    text-align: center;
    background-color: rgb(0, 0, 80);
    color: white;
}
.section-element table .add-btn-row {
    cursor: pointer;
    background-color: orange;
}
.section-element table .add-btn-row:hover {
    font-weight: bold;
}
</style>