<template>
    <div class="search-frame">
        <!-- Champ de recherche -->
        <input
            type="text"
            v-model="search"
            placeholder="Rechercher..."
            class="search-bar"
            @focus="dropdownOpen = true"
        />
    
        <!-- Liste déroulante -->
        <div v-if="dropdownOpen" class="dropdown">
            <div v-for="(option, index) in filteredOptions" :key="index" class="option" @click="selectOption(option)">
                {{ option.label }}
            </div>
    
            <!-- Message si aucune option trouvée -->
            <div v-if="filteredOptions.length === 0" class="not-found-msg">
                Aucun résultat trouvé
            </div>
        </div>
    </div>
</template>
    
<script>

export default {
    props: ['options', 'modelValue'],
    emits: ["update:modelValue"],
    data() {
        return {
            search: "",
            dropdownOpen: false,
        }
    },
    computed: {
        filteredOptions() {
            return this.options.filter(option => option.label
                .toLowerCase()
                .includes(this.search.toLowerCase())
            );
        }
    },
    methods: {
        selectOption(option) {
            this.$emit('update:modelValue', option.key);
            this.search = option.label;
            this.dropdownOpen = false;
        }
    },
    mounted() {
        const currentOption = this.options.find(o => o.key == this.modelValue);
        if (currentOption) {
            this.search = currentOption.label
        }
    }
}
</script>

<style scoped>
.search-frame {
    position: relative;
    display: flex;
    flex-wrap: wrap;
}
.search-bar {
    flex-grow: 1;
    flex-basis: 100%;
    font-size: 14px;
    font-weight: 200;
    text-align: left;
    border-radius: 5px 5px 0 0;
}
.dropdown {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.option {
    cursor: pointer;
    box-sizing: border-box;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 200;
    background-color: rgba(0, 0, 0, 0.42);
    width: 100%;
    border-top: none;
    color: white;
}
.option:hover {
    background-color: lightgrey;
}
.option:last-child {
    border-radius: 0 0 5px 5px;
}
</style>