<template>
    <div class="list-section">
        <h1 class="section-title">
            Revenus
            
            <add-btn type="income"></add-btn>
        </h1>

        <table class="table-elements">
            <list-component class="list-element"
                v-for="income in $store.getters.getAllIncomes"
                :key="income._id"
                :object="income"
                type="income"
            />
        </table>
    </div>
</template>

<script>

export default {
    data() {
        return {}
    }
}
</script>

<style scoped src="./style.css"></style>