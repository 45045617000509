<template>
    <div class="list-section">
        <h1 class="section-title">
            Dettes - {{ toEuro($store.getters.getTotalDebtsValue) }}

            <add-btn type="debt"></add-btn>
        </h1>
        <table class="table-elements">
            <list-component class="list-element"
                v-for="debt in list_debts"
                :key="debt._id"
                :object="debt"
                type="debt"
            />

            <list-component class="list-element"
                v-for="cca in list_cca"
                :key="cca._id"
                :object="cca"
                type="cca"
            />
        </table>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    computed:{
        formated_cca_list() {
            const cca_list = this.$store.getters.getAllAssets.filter(asset => asset.type == 'cca')
            let formated_cca_list = []

            cca_list.forEach(cca => {
                formated_cca_list.push({
                    _id: cca._id,
                    type: 'cca',
                    value: cca.value,
                    owner1: cca.linkedSociety,
                    insurance_owner1: 0,
                    isIpCreation: cca.isIpCreation
                })
            });

            return formated_cca_list
        },
        list_debts() {
            const society_ids = this.$store.getters.getAllSocieties.map(s => s._id)

            if (this.$store.getters.get_selected_vue == 'all-wealth') {
                return this.$store.getters.getAllDebts

            } else if (this.$store.getters.get_selected_vue == 'family-wealth') {
                return this.$store.getters.getAllDebts.filter(d => !society_ids.includes(d.owner1))
                
            } else {
                // Dettes des sociétés
                return this.$store.getters.getAllDebts.filter(d => d.owner1 == this.$store.getters.get_selected_vue)
            }
        },
        list_cca() {
            const society_ids = this.$store.getters.getAllSocieties.map(s => s._id)

            if (this.$store.getters.get_selected_vue == 'all-wealth') {
                return this.formated_cca_list
                
            } else if (this.$store.getters.get_selected_vue == 'family-wealth') {
                return this.formated_cca_list.filter(d => !society_ids.includes(d.owner1))
                
            } else {
                // Dettes des sociétés
                return this.formated_cca_list.filter(d => d.owner1 == this.$store.getters.get_selected_vue)
            }
        },
    }
}
</script>

<style scoped src="./style.css"></style>