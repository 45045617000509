<template>
    <div class="list-section">
        <h1 class="section-title">
            Parts de sociétés - {{ toEuro($store.getters.getTotalSocietyWealth) }}
            <add-btn type="society"></add-btn>
        </h1>
        <table class="table-elements">
            <list-component class="list-element"
                v-for="asset in list_assets"
                :key="asset._id"
                :object="asset"
                type="society"
            />
        </table>
    </div>
</template>

<script>

export default {
    data() {
        return {}
    },
    computed: {
        list_assets() {
            const allAssets = this.$store.getters.getAllSocieties

            if (this.$store.getters.get_selected_vue == 'all-wealth') {
                return allAssets
            } else if (this.$store.getters.get_selected_vue == 'family-wealth') {
                const society_ids = this.$store.getters.getAllSocieties.map(s => s._id)
                return allAssets.filter(asset => {
                    // Vérifie si tous les owner_id sont dans la liste ids
                    const allOwnersInIds = asset.detention.every(owner => society_ids.includes(owner.owner_id));
                    // Conserve le bien si ce n'est pas le cas
                    return !allOwnersInIds;
                });
                
            } else {
                // Patrimoine d'une société
                return allAssets.filter( a => a.detention.map(d => d.owner_id).includes(this.$store.getters.get_selected_vue) )
            }
        },
    },
}
</script>


<style scoped src="./style.css"></style>