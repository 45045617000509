<template>
    <table class="form-table">
        <tr>
            <td>Propriétaire</td>
            <td>Part</td>
            <td>Droit de propriété</td>
            <td @click="addIndivisionPart">
                <span class="material-symbols-outlined add-btn">add_circle</span>
            </td>
        </tr>
        <tr v-for="(part, index) in detention_parts" :key="index">
            <td>
                <select v-model="detention_parts[index].owner_id">
                    <option v-for="person in $store.getters.getAllPersons" :key="person._id" :value="person._id">{{ person.name }}</option>
                </select>
            </td>

            <td>
                <percent-input v-model="detention_parts[index].part" style="text-align: center;"></percent-input>
            </td>

            <td>
                <select v-model="detention_parts[index].right" style="text-align: center;">
                    <option v-for="(value, key) in $store.getters.rights" :key="key" :value="key">{{ value }}</option>
                </select>
            </td>

            <td @click="deleteIndivisionPart(index)">
                <span class="material-symbols-outlined delete-indivision-part">backspace</span>
            </td>
        </tr>
    </table>
</template>

<script>
export default {
    props: ['detention'],
    data() {
        return {
            detention_parts: [],
            receivedDatas: false
        }
    },
    methods: {
        addIndivisionPart() {
            this.detention_parts.push({ owner_id : undefined, right : this.right, part : 0 })
        },
        deleteIndivisionPart(index) {
            this.detention_parts.splice(index, 1)
        },
    },
    watch: {
        detention_parts: {
            deep: true,
            handler() {
                this.$emit('updated', this.detention_parts.map(part => {
                let copy = JSON.parse(JSON.stringify(part))
                copy.part = copy.part / 100
                return copy
            }))
            }
        }
    },
    beforeUpdate() {
        if (this.detention && !this.receivedDatas) {
            this.detention_parts = this.detention.map(part => {
                let copy = JSON.parse(JSON.stringify(part))
                copy.part = copy.part * 100
                return copy
            })
            this.receivedDatas = true
        }
    },
    beforeMount() {
        if (Object.keys(this.detention).length > 0 && !this.receivedDatas) {
            this.detention_parts = this.detention.map(part => {
                let copy = JSON.parse(JSON.stringify(part))
                copy.part = copy.part * 100
                return copy
            })
            this.receivedDatas = true
        }
    }
}
</script>

<style scoped>
.form-table {
    position: relative;
    width: 100%;
    table-layout: fixed;
    background-color: white;
    border-collapse: collapse;
}

.form-table tr:nth-child(1) td {
    /* Prmeière ligne */
    font-weight: bold;
    text-align: center;
    font-size: 12px;
    background-color: rgba(1, 1, 98, 0.432);
    color: white;
}

td {
    position: relative;
    border: lightgrey 1px solid;
    padding: 5px;
    font-size: 12px;
}

tr td:nth-child(1) {
  width: 40%;
}
tr td:nth-child(2) {
  width: 15%;
}
tr td:nth-child(3) {
  width: 30%;
}
tr td:nth-child(4) {
  width: 15%;
  min-width: 40px;
  text-align: center;
}

select, input {
    border: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

select {
    white-space: nowrap; /* Empêche le texte de s'étaler sur plusieurs lignes */
    overflow: hidden; /* Cache le débordement */
    text-overflow: ellipsis; /* Affiche des points de suspension si le texte est trop long */
}

.delete-indivision-part {
    cursor: pointer;
    color: rgb(178, 2, 2);
}

.add-btn {
    cursor: pointer;
    text-align: center;
}
.add-btn:hover {
    color: orange;
}





</style>