<template>
    <div class="pages-container">
        <div class="page page1">
            <div class="rounded-background" :style="{'background-color': mainColor}"></div>
            <div class="decoration-bar"></div>
            <img :src="logoUrl" class="main-logo">
            <div class="text-frame">
                <h1>Lorem Ipsum</h1>
                <h2>Lorem Ipsum</h2>
            </div>

            <div class="triangle1"></div>
            <div class="triangle2" :style="{'background-color': secondaryColor}"></div>
        </div>

        <div class="page page2">
            <img :src="logoUrl" class="header-logo">
            <div class="triangle1" :style="{'background-color': secondaryColor}"></div>
            <div class="triangle2" :style="{'background-color': mainColor}"></div>
            <h1>Lorem Ipsum</h1>
        </div>

        <div class="page page3">
            <img :src="logoUrl" class="header-logo">
            <h1>Titre 1</h1>
            <h2 :style="{'color': mainColor}">Titre 2</h2>
            <h3 :style="{'color': secondaryColor}">Titre 3</h3>
            <h4>Titre 4</h4>
            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dicta ipsam voluptatem dignissimos tempora ipsa modi veniam ducimus! Esse quisquam minus ut. Tempora iusto quia libero asperiores at dignissimos omnis iste voluptatum ullam, deserunt distinctio laboriosam eligendi corporis ratione dolorem pariatur. Eaque, corporis autem. Odio quis, consequuntur eos, explicabo repellendus odit minima iure ex, pariatur voluptate nihil qui harum! Velit aut, laborum soluta alias cupiditate sunt molestias similique nemo obcaecati pariatur eaque, ipsam, voluptas ullam? Iste, excepturi enim iusto pariatur provident perspiciatis. Dolor quam asperiores earum. Cupiditate, fuga esse fugit ut nobis veniam error voluptatibus magnam beatae ea architecto, ipsam soluta?</p>
            <div class="legal-infos">{{ legalInfos }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'mainColor',
        'secondaryColor',
        'logoUrl',
        'legalInfos'
    ]
}
</script>

<style scoped>
.pages-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

.page {
    margin: 20px 10px;
    height: 400px;
    width: 280px;
    background-color: white;
    position: relative;
    overflow: hidden;
    -webkit-box-shadow: 0px 0px 15px 3px rgba(104, 103, 103, 0.427); 
    box-shadow: 0px 0px 15px 3px rgba(104, 103, 103, 0.427);
    box-sizing: border-box;
}

.header-logo {
    height: 30px;
    position: absolute;
    top: 5px;
    left: 5px;
}

/* Première page (page de garde) */
.page1 .rounded-background {
    width: 90%;
    height: 100%;
    clip-path: ellipse(100% 85% at 0 45%);
    background: #0F1C67;
    overflow: hidden;
}

.page1 .main-logo {
    position: absolute;
    height: 60px;
    top: 25%;
    left: 60px;
}

.page1 .decoration-bar {
    position: absolute;
    height: 50%;
    width: 3px;
    background-color: white;
    top: 25%;
    left: 50px;
}
.page1 .text-frame {
    position: absolute;
    top: 40%;
    left: 60px;
}
.page1 .text-frame h1 {
    font-size: 16px;
    color: white;
}
.page1 .text-frame h2 {
    font-size: 12px;
    color: white;
}

.page1 .triangle1 {
    height: 90px;
    clip-path: polygon(100% 0,0 60%,90% 100%);
    position: absolute;
    bottom: -30px;
    left: 40px;
    background-color: white;
    aspect-ratio: cos(0deg);
    rotate: 10deg;
}

.page1 .triangle2 {
    height: 90px;
    clip-path: polygon(100% 0,0 60%,90% 100%);
    position: absolute;
    bottom: -40px;
    left: 80px;
    aspect-ratio: cos(0deg);
    rotate: 0deg;
}

/* 2eme page (transitions) */
.page2 h1 {
    position: absolute;
    top: 55%;
    left: 25%;
    color: white;
    font-size: 16px;
}
.page2 .triangle1 {
    height: 90%;
    clip-path: polygon(70% 0,0 60%, 70% 100%);
    position: absolute;
    top: 20px;
    left: 20px;
    width: 90%;
    background-color: white;
    aspect-ratio: cos(0deg);
    rotate: 10deg;
}
.page2 .triangle2 {
    height: 90%;
    clip-path: polygon(70% 0,0 60%, 70% 100%);
    position: absolute;
    top: 30px;
    left: 20px;
    width: 90%;
    background-color: white;
    aspect-ratio: cos(0deg);
    rotate: -15deg;
}

/* Page 3 (coprs du texte) */
.page3 {
    padding: 30px 20px;
}
.page3 h1 {
    font-family: "Montserrat Alternates", sans-serif;
    font-size: 18px;
    color: black;
}
.page3 h2 {
    font-family: "Montserrat Alternates", sans-serif;
    font-size: 16px;
}
.page3 h3 {
    font-family: "Montserrat Alternates", sans-serif;
    font-size: 14px;
}
.page3 h4 {
    font-family: "Montserrat Alternates", sans-serif;
    font-size: 12px;
    color: #0B1C6B;
    border-bottom: 1px solid #0B1C6B;
}

.page3 p {
    font-family: "Montserrat", "sans-serif";
    font-size: 9px;
    text-align: justify;
}

.page3 .legal-infos {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    font-size: 3px;
    padding: 5px 10px;
}
</style>