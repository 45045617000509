<template>
    <div class="page-container">
        <h1>Vos préconisations</h1>
        <p>Vous trouverez ci-dessous la liste des simulations réalisées pour {{ $store.getters.userName }}.</p>

        <div class="simulations">
            <div class="simulation-component" v-for="scenario in scenarios" :key="scenario._id">
                <div class="simulation-label">{{ scenario.label.toUpperCase() }}</div>

                <span class="material-symbols-outlined">dataset</span>

                <div class="simulation-modification">
                    <span class="material-symbols-outlined" style="cursor: pointer" @click="goToAdivorTool(scenario._id)">border_color</span>
                    <span class="material-symbols-outlined" @click="deleteScenario(scenario._id)">delete_forever</span>
                </div>

                <div class="simulation-date">{{ dateToString(scenario.creationDate) }}</div>
            </div>

            <div class="add-scenario" @click="createScenario">
                <span class="material-symbols-outlined">add_circle</span>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            recommendation: undefined,
            scenarios: []
        }
    },
    methods: {
        async getClientRecommendation() {
            const clientRecoRes = await axios.get(this.$store.getters.get_api_url + 'clients/find-client-recommendation/' + this.$route.params.clientId, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.recommendation = clientRecoRes.data
        },
        async createClientRecommendation() {
            await axios.post(this.$store.getters.get_api_url + 'clients/recommendation/', {
                clientId: this.$route.params.clientId,
                isClientRecommandation: true,
            }, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.$router.replace('/client/' + this.$route.params.clientId + '/view/')
        },
        async createScenario() {
            const label = prompt("Choisissez un nom de scenario", "Scenario");
            if (label && label != '') {
                const res = await axios.get(this.$store.getters.get_api_url + 'clients/scenario-original/' + this.$route.params.clientId, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })

                const originalScenarioId = res.data._id

                await axios.post(this.$store.getters.get_api_url + 'clients/duplicate-scenario/', {
                    clientId: this.$route.params.clientId,
                    recommendationId: this.recommendation._id,
                    originalScenarioId: originalScenarioId,
                    scenarioLabel: label,
                }, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })

                this.loadScenarios()

            } else {
                alert('Veuillez choisir un nom de scenario')
            }
        },
        async loadScenarios() {
            const scenario_response = await axios.get(this.$store.getters.get_api_url + 'clients/scenario/' + this.recommendation._id, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.scenarios = scenario_response.data
        },
        async deleteScenario(scenarioId) {
            const confirmation = confirm('Supprimer le scenario ?')
            if (confirmation) {
                await axios.delete(this.$store.getters.get_api_url + 'clients/scenario/' + scenarioId, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })
                await this.loadScenarios()
            }
        },
        goToAdivorTool(scenarioId) {
            this.$router.push('/client/' + this.$route.params.clientId + '/advisor-tool/' + scenarioId)
        }
    },
    async mounted() {
        await this.getClientRecommendation()

        if (!this.recommendation) {
            await this.createClientRecommendation()
            await this.getClientRecommendation()
        }

        await this.loadScenarios()
    }
}
</script>

<style scoped>

.page-container {
    position: relative;
}



.simulations {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.simulation-component {
    cursor:default;
    position: relative;
    border-radius: 10px;
    border: 1px solid #0a1c6b;
    margin: 5px;
    display: flex;
    flex-direction: column;
    flex-basis: 300px;
    background-color: #f8f4eb7d;
    font-size: 20px;
}

.simulation-label {
    margin: -1px;
    border-radius: 8px 8px 0 0;
    font-size: 20px;
    color: white;
    background-color: #0a1c6b;
    padding: 10px 15px;
    text-align: center;
}


.material-symbols-outlined {
    text-align: center;
    font-variation-settings: 'FILL' 0, 'wght' 200, 'GRAD' 0, 'opsz' 24;
    font-size: 200px;
    color: #0a1c6b;
}

.simulation-modification {
    padding: 10px 15px;
    border-radius: 0 20px 20px 0;
    flex-basis: 10%;
    text-align: center;
    color: #0a1c6b;
}
.simulation-modification > :nth-child(1):hover {
    color: orange;
}
.simulation-modification > :nth-child(2):hover {
    color: red;
}
.simulation-modification span {
    font-size: 20px;
}

.simulation-date {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 5px;
    font-size: 12px;
    font-weight: bold;
    background-color: #0a1c6b;
    color: white;
    border-radius: 0 10px 0 10px;
} 


.add-scenario {
    flex-basis: 300px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #0a1c6b;
    margin: 5px;
}
.add-scenario:hover {
    border-color: black;
}
.add-scenario:hover .material-symbols-outlined {
    color: black;
}

</style>