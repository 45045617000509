<template>
    <div class="family">
        <Couple/>

        <div class="container">
            <div class="side-left">
                <Family :roles="['user', 'spouse']" title="Clients"/>
                <Family :roles="['child']" title="Enfants"/>
                <Family :roles="['grandchild']" title="Petits enfants"/>
            </div>

            <div class="side-right">
                <Family :roles="['parent']" title="Parents"/>
                <Family :roles="['sibling']" title="Frères et soeurs"/>
                <Family :roles="['nephew']" title="Neveux et nièces"/>
                <Family :roles="['other']" title="Tiers"/>
            </div>
        </div>

    </div>
</template>

<script>
import Couple from '../sections/Couple.vue'
import Family from '../sections/Family.vue'

export default {
    components: {
        Couple,
        Family,
    },
    methods: {

    },
    computed: {
    }
}
</script>

<style scoped src="./style.css"></style>