<template>
    <form class="demand-form">
        <section>
            <label>Type de mission</label>
            <select v-model="body.category">
                <option v-for="(label, key) in categories" :key="key" :value="key">{{ label }}</option>
            </select>
        </section>

        <section>
            <label>Description de la mission</label>
            <textarea v-model="body.label"></textarea>
        </section>

        <section>
            <label>Nombre d'heures passées (max 7h/jour)</label>
            <input type="number" v-model="body.nb_hours">
        </section>

        <section>
            <label>Date</label>
            <input type="date" v-model="body.date">
        </section>
        
        <div class="btn-wrapper">
            <div class="save-btn" @click="createDemand">Enregistrer</div>
        </div>

        <p>{{ errMsg }}</p>
    </form>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        categories: {
            type: Object,
            required: true
        },
        demandData: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            errMsg: "",
            body: {
                category: "other",
                label: "",
                date: "",
                employeeId: undefined,
                nb_hours: 1,
                nb_half_day: 1,
                is_confirmed: false,
                status: '',
                confirmed_by: undefined,
            },
        }
    },
    watch: {
        "body.nb_hours": {
            handler() {
                if (this.body.nb_hours > 7) {
                    this.body.nb_hours = 7;
                } else if (this.body.nb_hours < 0) {
                    this.body.nb_hours = 0;
                }
            }
        }
    },
    methods: {
        verifyForm() {
            let isValid = true;
            if (this.body.label == '') {
                this.errMsg = "Veuillez saisir une description.";
                isValid = false;
            } else if (this.body.nb_half_hour < 1) {
                this.errMsg = "Veuillez renseigner le temps passé.";
                isValid = false;
            } else if (!this.body.date) {
                this.errMsg = "Veuillez renseigner une date.";
                isValid = false;
            } else if (!this.body.category) {
                this.errMsg = "Veuillez renseigner une catégorie de mission annexe";
                isValid = false;
            }

            return isValid;
        },
        async createDemand() {
            
            const isValid = this.verifyForm();
            if (!isValid) {
                return;

            } else if (this.isNew) {
                this.body.employeeId = this.$store.getters.getAdminId
                await axios.post(this.$store.getters.get_api_url + 'rh-management/demand/', this.body, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                });

            } else if (!this.isNew) {
                await axios.put(this.$store.getters.get_api_url + 'rh-management/demand/', this.body, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                });
            }
            
            this.$emit('demandCreated');
        },
    },
    computed: {
        isNew() {
            if (this.demandData) {
                return false;
            } else {
                return true;
            }
        }
    },
    mounted() {
        if (this.isNew) {
            const current_date = new Date();
            const french_date = this.dateToString(current_date);
            const date_array = french_date.split('/');
            this.body.date = `${date_array[2]}-${date_array[1]}-${date_array[0]}`;
        } else {
            this.body = this.demandData;
            const current_date = new Date(this.body.date);
            const french_date = this.dateToString(current_date);
            const date_array = french_date.split('/');
            this.body.date = `${date_array[2]}-${date_array[1]}-${date_array[0]}`;
        }
    },
}
</script>

<style scoped>
.demand-form {
    width: 90%;
    margin: 10px auto;
}
.demand-form section {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
}

.demand-form section label,
.demand-form section input,
.demand-form section select,
.demand-form section textarea {
    font-family: "verdana", sans-serif;
    flex-grow: 1;
    font-size: 16px;
    padding: 5px;
}
.demand-form section textarea {
    flex-basis: 100%;
}

.demand-form section input,
.demand-form section select,
.demand-form section textarea {
    text-align: right;
    border-radius: 5px;
    border: 1px solid lightgrey;
}

.btn-wrapper {
    display: flex;
    justify-content: center;
}
.save-btn {
    cursor: pointer;
    background-color: rgb(255, 182, 45);
    margin: 10px auto;
    padding: 10px 20px;
    border-radius: 5px;
}

.save-btn:hover {
    background-color: rgb(252, 200, 105);
}
</style>