<template>
    <div class="loan-capacity-page">
        <div class="params-frame">
            <div class="form-section">
                <label>Durée de l'emprunt en mois</label>
                <input type="number" v-model="loan_data.nb_mois">
            </div>

            <div class="form-section">
                <label>Taux d'emprunt</label>
                <percent-input  v-model="loan_data.taux"/>
            </div>

            <div class="form-section">
                <label>Taux d'assurance</label>
                <percent-input  v-model="loan_data.taux_assurance"/>
            </div>

            <div class="form-section">
                <label>Achat de la résidence principale</label>
                <input type="checkbox" v-model="loan_data.achat_rp">
            </div>
        </div>
        <div class="result-frame" v-if="simulator_result.resultat">
            <h1>Capacité d'emprunt estimée : {{ toEuro(simulator_result.resultat.capacite_emprunt) }}</h1>

            <h2>Prise en compte des revenus annuels</h2>
            <p>Seuls les revenus récurrents sur plusieurs années doivent être pris en compte. Certaines catégories de revenus se voient appliquer un coefficient pour tenir compte de leur instabilité :</p>
            <table>
                <tr>
                    <th>Type de revenu</th>
                    <th>Montant annuel</th>
                    <th>Coefficient</th>
                    <th>Valeur retenue</th>
                </tr>

                <tr v-for="income in simulator_result.liste_revenus" :key="income.id">
                    <td>{{ income.type }}</td>
                    <td>{{ toEuro(income.net_annuel) }}</td>
                    <td>{{ toPercent(income.coeff) }}</td>
                    <td>{{ toEuro(income.valeur_retenue) }}</td>
                </tr>

                <tr>
                    <th colspan="3">Revenu annuel</th>
                    <th>{{ toEuro(simulator_result.revenu_annuel) }}</th>
                </tr>

                <tr>
                    <th colspan="3">Revenu mensuel</th>
                    <th>{{ toEuro(simulator_result.revenu_annuel / 12) }}</th>
                </tr>
            </table>

            <h2>Calcul de la mensualité maximale</h2>

            <p>Les règles du Haut Conseil pour la Stabilité Financière (HCSF) imposent au banques de limiter significativement les emprunts qui portent le taux d'endettement des épargnants à plus de 35% de leurs revenus annuels.</p>
            <p>Dans cette situation, la mensualité maximale du foyer serait donc de {{ toEuro(simulator_result.revenu_annuel / 12) }} x 35% = <b>{{ toEuro(simulator_result.resultat.mensualite_max) }}</b>.</p>

            <h2>Prise en compte des charges du foyer</h2>

            <table>
                <tr>
                    <th>Type de charge</th>
                    <th>Montant mensuel retenu</th>
                    <th>commentaire</th>
                </tr>

                <tr v-for="charge in simulator_result.liste_charges" :key="charge.id">
                    <td>{{ charge.type }}</td>
                    <td>{{ toEuro(charge.paiement_mensuel) }}</td>
                    <td>{{ charge.msg }}</td>
                </tr>

                <tr>
                    <th colspan="2">Total des charges mensuelles</th>
                    <th>{{ toEuro(simulator_result.total_charges_mensuelles) }}</th>
                </tr>

                <tr>
                    <th colspan="2">Taux d'endettement actuel</th>
                    <th>{{ toPercent(simulator_result.resultat.taux_endettement_actuel) }}</th>
                </tr>
            </table>

            <p>La mensualité maximale restante est donc de {{ toEuro(simulator_result.resultat.mensualite_max) }} - {{ toEuro(simulator_result.total_charges_mensuelles) }} = <b>{{ toEuro(simulator_result.resultat.mensualite_max_restante) }}</b>.</p>

            <h2>Calcul de la capacité d'emprunt</h2>
            <p>La capacité d'emprunt, c'est à dire le montant qu'il est possible d'emprunter au maximum, se calcule par la formule suivante :</p>
            <div class="formula-container">
                <span class="fraction">
                    <span class="num">T</span>
                    <span class="den">r</span>
                </span>
                x <span class="parentheses">(</span> 1 - 
                <span class="fraction">
                    <span class="num">1</span>
                    <span class="den">(1 + r)<sup>n</sup></span>
                </span>
                <span class="parentheses">)</span>
            </div>

            <p>avec :</p>
            <ul>
                <li><b>T</b> : la mensualité pouvant être consacrée au remboursement</li>
                <li><b>r</b> : le taux mensuel</li>
                <li><b>n</b> : la durée du prêt en mois.</li>
            </ul>

            <p>Dans cette situation, le calcul est donc le suivant :</p>
            <div class="formula-container">
                <span class="fraction">
                    <span class="num">{{ toEuro(simulator_result.resultat.mensualite_max_restante) }}</span>
                    <span class="den">{{ toPercent(simulator_result.resultat.taux_mensuel) }}</span>
                </span>
                x <span class="parentheses">(</span> 1 - 
                <span class="fraction">
                    <span class="num">1</span>
                    <span class="den">(1 + {{ toPercent(simulator_result.resultat.taux_mensuel) }})<sup>{{ simulator_result.resultat.duree }}</sup></span>
                </span>
                <span class="parentheses">)</span>
            </div>

            <p>Le montant pouvant être emprunté au maximum sur une durée de {{ simulator_result.resultat.duree }} mois au taux annuel de {{ toPercent(simulator_result.resultat.taux_annuel_total) }} est donc de <b>{{ toEuro(simulator_result.resultat.capacite_emprunt) }}</b>.</p>
        </div>

        <!-- {{ simulatorPayload }}

        <p>Response</p>

        {{ simulator_result }} -->

        <!-- <div class="btn-detail" @click="download_detail">Détail du calcul</div> -->
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            loan_data: {
                nb_mois: 240,
                taux: 3,
                taux_assurance: 0,
                achat_rp: false,
            },
            simulator_result: {},
        }
    },
    methods: {
        async computeLoanCapacity() {
            const res = await axios.post(this.$store.getters.get_api_url + 'simulators/loan-capacity', this.simulatorPayload, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            });

            this.simulator_result = res.data;
        },
    },
    computed: {
        simulatorPayload() {
            const body = this.$store.getters.loanCapacityDatas({
                nb_mois: this.loan_data.nb_mois,
                taux: this.loan_data.taux / 100,
                taux_assurance: this.loan_data.taux_assurance / 100,
                achat_rp: this.loan_data.achat_rp,
            }, {});

            return body;
        }
    },
    watch: {
        loan_data: {
            deep: true,
            handler() {
                this.computeLoanCapacity();
            }
        }
    },
    mounted() {
        this.computeLoanCapacity();
    }
}
</script>

<style scoped>
.loan-capacity-page {
   display: flex;
   flex-direction: column;
   align-items: center;
}

.params-frame {
    max-width: 800px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.params-frame div {
    flex-basis: 45%;
}
.params-frame div label,
.params-frame div input {
    font-size: 11px;
}

.result-frame {
    max-width: 800px;
}

.loan-capacity-page .result-frame h1 {
    text-align: center;
    color: rgb(0, 0, 80);
    font-size: 30px;
}
.loan-capacity-page .result-frame h2 {
    text-align: left;
    color: rgb(0, 0, 80);
}

.loan-capacity-page .result-frame table {
    border-collapse: collapse;
    width: 100%;
} 

.loan-capacity-page .result-frame table th,
.loan-capacity-page .result-frame table td {
    border: 1px solid lightgrey;
    padding: 5px;
    text-align: center;
}
.loan-capacity-page .result-frame table th {
    background-color: #f7efdf;
    color: rgb(92, 91, 91);
}

.loan-capacity-page .result-frame table tr th:first-child,
.loan-capacity-page .result-frame table tr td:first-child {
    text-align: left;
}

.formula-container {
    border: 1px solid grey;
    padding: 10px;
    border-radius: 5px;
    display: inline-block;
    font-size: 18px;
}
.fraction {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
}
.fraction span {
    display: block;
    padding: 2px;
}
.fraction .num {
    border-bottom: 1px solid black;
}
.parentheses {
    font-size: 1.2em;
}
.exponent {
    font-size: 0.8em;
    vertical-align: super;
}

.btn-detail {
    display: inline-block;
    margin: 10px auto;
    cursor: pointer;
    text-align: center;
    padding: 20px;
    background-color: rgb(0, 0, 67);
    color: white;
    border-radius: 5px;
}
.btn-detail:hover {
    background-color: rgba(0, 0, 67, 0.559);
}
</style>