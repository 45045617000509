<template>
    <div class="side-template">
        <div class="template-content">
            <div class="side-section">
                <span class="material-symbols-outlined person-icon">account_circle</span>

                <h2>{{$store.getters.userName}}</h2>

                <h5 class="btn-return" @click="openProfile">Revenir à son dossier</h5>

                <button>Télécharger sa fiche</button>
            </div>
            

            <div class="separator"></div>

            <ul>
                <li class="selected-menu"><router-link :to="'/client/' + $route.params.clientId + '/recommendation/' + $route.params.recommendationId + '/audit'">Audit patrimonial</router-link></li>
                <li class="selected-menu"><router-link :to="'/client/' + $route.params.clientId + '/recommendation/' + $route.params.recommendationId + '/overview'">Simulation des solutions</router-link></li>
                <li class="selected-menu"><router-link :to="'/client/' + $route.params.clientId + '/recommendation/' + $route.params.recommendationId + '/solutions'">Solutions d'optimisation</router-link></li>
                <li class="selected-menu"><router-link :to="'/client/' + $route.params.clientId + '/recommendation/' + $route.params.recommendationId + '/recommendation'">Récapitulatif</router-link></li>
            </ul>

            <div class="separator"></div>

            <h4 @click="toggleModale">
                <span class="material-symbols-outlined">settings_applications</span>
                <span>Paramètres</span>
            </h4>
        </div>

        <modale :show="showModale" width="500px" @toggle="toggleModale">
            <div class="form-section">
                <label>Partenaire</label>
                <searchable-select v-model="style" :options="$store.getters.partnerStylesOptions"/>
            </div>
        </modale>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data(){
        return {
            showModale: false,
            style: undefined,
        }
    },
    methods: {
        openProfile() {
            this.$router.replace('/client/' + this.$route.params.clientId + '/view/')
        },
        toggleModale() {
            this.showModale = !this.showModale
        },
        async getClientStyle() {
            const res = await axios.get(this.$store.getters.get_api_url + 'clients/find-client/' + this.$route.params.clientId, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            
            this.style = res.data.client_style
        },
        updateStyle() {
            axios.put(this.$store.getters.get_api_url + 'clients/client', {
                _id: this.$route.params.clientId,
                client_style : this.style,
            }, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
        },
    },
    watch: {
        style() {
            this.updateStyle()
        }
    },
    mounted() {
        this.getClientStyle()
    }
}
</script>

<style scoped>
.side-template {
    background-color: rgb(30,44,88);
}
.template-content {
    position: fixed;
    top: 100px;
    left: 25px;
    bottom: 10px;
    width: 225px;
    display: flex;
    flex-direction: column;
    color: white;
    align-items: center;
    justify-content: space-evenly;
}
.side-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.person-icon {
    font-size: 50px;
}
.separator {
    height: 2px;
    background-color: #fb9e4b;
    width: 80%;
}
.side-template h2 {
    color: white;
    text-align: center;
}
.side-template h4 {
    cursor: pointer;
    display: flex;
    align-items: center;
}
.btn-return {
    cursor: pointer;
    color: orange;
    text-decoration: underline;
}
.side-template a {
    cursor: pointer;
    color: white;
    font-weight: lighter;
    text-decoration: none;
}
.side-template .router-link-active {
    cursor: pointer;
    color: #fb9e4b;
    font-weight: lighter;
}
.side-template h5:hover {
    font-weight: bold;
}
.side-template li {
    padding: 20px 0;
}

.selected-menu {
    cursor: pointer;
    color: #fb9e4b;
}

.form-section {
    display: flex;
    flex-direction: column;
    margin: 20px;
}
.form-section label, 
.form-section input, 
.form-section select {
    font-size: 18px;
}

.form-section label {
    font-weight: bold;
}
.form-section input, 
.form-section select {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid lightgrey;
}
</style>