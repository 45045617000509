<template>
    <div class="form-wrapper">
        <div class="form-box">
            <h4>Informations générales</h4>

            <div class="form-container">
                <div class="form-section">
                    <label for="owner">Testateur<span class="mandadory">*</span></label>
                    <select id="owner" v-model="body.owner">
                        <option v-for="(value, index) in $store.getters.getMainPersons" :key="index" :value="value">{{ $store.getters.findOwnerName(value) }}</option>
                    </select>
                </div>

                <div class="form-section">
                    <label for="type">Type de testament<span class="mandadory">*</span></label>
                    <select id="type" v-model="body.type">
                        <option v-for="(value, key) in $store.getters.willTypes" :key="key" :value="key">{{ value }}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="form-box" v-if="body.type == 'universal'">
            <h4>Legs universel ou à titre universel</h4>

            <div class="will-type-detail" >
                <div class="form-section">
                    <label for="right">Droit légué<span class="mandadory">*</span></label>
                    <select v-model="body.universal_detail.right">
                        <option v-for="(value, key) in $store.getters.rights" :key="key" :value="key">{{ value}}</option>
                    </select>
                </div>
                
                <div class="legee-section">
                    <h2>Légataires universels
                        <div class="tooltip">
                            <span class="material-symbols-outlined add-rank" @click="body.universal_detail.ranks.push([])">add_box</span>
                            <span class="tooltiptext">Ajouter un rang</span>
                        </div>
                    </h2>
                    
                    <!-- Rangs -->
                    <div class="rank" v-for="(rank, index) in body.universal_detail.ranks" :key="index">
                        <!-- Bénéfifiaires -->
                        <div class="rank-label">
                            <p>Rang {{ index + 1 }} </p>
                            <div class="tooltip">
                                <span class="material-symbols-outlined delete-rank-btn" @click="deleteUniversalRank(index)">delete_forever</span>
                                <span class="tooltiptext">Supprimer ce rang</span>
                            </div>
                        </div>
                        
                        <multi-select
                            :elements="beneficiariesList"
                            :values="rank"
                            @closed="(result) => updateUniversalRank(result, index)"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="form-box" v-else-if="body.type == 'legs'">
            <h4>Legs particuliers</h4>

            <div class="will-type-detail" >
                <h2>Liste des legs
                    <div class="tooltip">
                        <span class="material-symbols-outlined add-legs" @click="addLegs">add_box</span>
                        <span class="tooltiptext">Ajouter un legs</span>
                    </div>
                </h2>
                
                <div class="shared-asset-detail" v-for="(legs, legsIndex) in body.legs" :key="legsIndex">
                    <div class="shared-asset-label">
                        <h2>Legs {{ legsIndex + 1 }}</h2>
                        <div class="tooltip">
                            <span class="material-symbols-outlined delete-shared-btn" @click="deletelegs(legsIndex)">delete_forever</span>
                            <span class="tooltiptext">Supprimer ce legs</span>
                        </div>
                    </div>
                    

                    <!-- Rangs -->
                    <div class="rank" v-for="(rank, rankIndex) in legs.ranks" :key="rankIndex">
                        <!-- Bénéfifiaires -->
                        <div class="rank-label">
                            <p>Rang {{ rankIndex + 1 }} </p>
                            <span class="material-symbols-outlined delete-rank-btn" @click="deleteLegsRank(rankIndex, legsIndex)">delete_forever</span>
                        </div>
                        
                        <multi-select
                            :elements="beneficiariesList"
                            :values="rank"
                            @closed="(result)=>{legs.ranks[rankIndex] = result}"
                        />
                    </div>

                    <div class="tooltip">
                        <span class="material-symbols-outlined add-legs" @click="legs.ranks.push([])">group_add</span>
                        <span class="tooltiptext">Ajouter un rang</span>
                    </div>

                    <!-- Bien partagé -->
                    <div class="form-section">
                        <label>Actif légué<span class="mandadory">*</span></label>
                        <select v-model="legs.assetId">
                            <option v-for="asset in assetList" :key="asset._id" :value="asset._id"> {{ $store.getters.allTypes[asset.type] }} - {{ toEuro(asset.value) }}</option>
                        </select>
                    </div>

                    <!-- Droit légué -->
                    <div class="form-section">
                        <label>Droit légué<span class="mandadory">*</span></label>
                        <select id="rights" v-model="legs.right">
                            <option v-for="(value, key) in $store.getters.rights" :key="key" :value="key">{{ value }}</option>
                        </select>
                    </div>
                </div>
            
            </div>
        </div>

        <div class="btn-container">
            <div class="btn-submit" @click="submit">Enregistrer</div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['import'],
    data() {
        return {
            isNew: true,
            errorMsg: "",
            body: {
                _id: undefined,
                clientId: undefined,
                scenarioId: undefined,
                owner: undefined,
                type: undefined,
                universal_detail: {
                    right: undefined,
                    ranks: [ [] ],
                },
                legs: [
                    {
                        right: "pp",
                        value: 0,
                        ranks: [ [] ],
                        aps: false,
                        assetId: undefined,
                    }
                ],
                isIpCreation: false,
            },
        }
    },
    watch: {
        errorMsg() {
            if (this.errorMsg != "") {
                alert(this.errorMsg);
            }
        },
    },
    methods: {
        submit() {
            if (this.isOriginalScenario) {
                this.body.isIpCreation = false
            } else if (this.isNew) {
                this.body.isIpCreation = true
            }

            if (this.body.type == undefined) {
                this.errorMsg = "Précisez le type de testament"

            } else if (this.body.owner == undefined) {
                this.errorMsg = "Précisez le testateur"

            } else if (this.$store.getters.hasWill(this.body.owner) && this.isNew) {
                this.errorMsg = "Cet utilisateur a déjà un testament"

            } else if (this.body.type == 'universal' && this.body.universal_detail.ranks.length == 0) {
                this.errorMsg = "Renseignez au moins un légataire universel"

            } else if (this.body.type == 'universal' && !this.body.universal_detail.right) {
                this.errorMsg = "Renseignez le droit légué"

            } else if (this.body.type == 'sharing' && this.body.sharing_detail.length == 0) {
                this.errorMsg = "Renseignez au moins un bien à partager"

            } else {
                this.errorMsg = ""

                if (this.isNew) {
                    this.body.addToStore = true
                    this.$store.dispatch('create_will', this.body)

                } else {
                    this.$store.dispatch('modify_will', this.body)
                }
                this.$emit('closeModale')
            }
        },
        addLegs() {
            this.body.legs.push({
                asset: {
                    assetId: undefined,
                    right: 'pp'
                },
                value: 0,
                ranks: [
                    []
                ],
                aps: false
            })
        },
        deletelegs(index) {
            this.body.legs.splice(index, 1)
        },
        deleteUniversalRank(index) {
            this.body.universal_detail.ranks.splice(index, 1)
        },
        deleteLegsRank(rankIndex, legsIndex) {
            this.body.legs[legsIndex].ranks.splice(rankIndex, 1)
        },
        updateUniversalRank(result, index) {
            this.body.universal_detail.ranks[index] = result
        }
    },
    computed: {
        isOriginalScenario() {
            const routeArray = this.$route.fullPath.split('/')

            if (routeArray[3] == 'profile' || this.$route.query.isOriginal) {
                return true
            } else {
                return false
            }
        },
        beneficiariesList() {
            if (this.body.owner == this.$store.getters.spouseId) {
                return this.$store.getters.findSpouseBeneficiaries
                
            } else {
                return this.$store.getters.findUserBeneficiaries
            }
        },
        assetList() {
            if (this.body.owner == this.$store.getters.spouseId) {
                return this.$store.getters.getSpouseAssets
                
            } else {
                return this.$store.getters.getUserAssets
            }
        }
    },
    mounted() {
        if (this.import) {
            this.isNew = false
            JSON.parse(JSON.stringify(this.import))

            this.body = this.import
        }

        if (this.isNew) {
            this.body.clientId = this.$route.params.clientId
            this.body.scenarioId = this.$route.params.scenarioId
        }
    }
}
</script>

<style scoped>
.will-type-detail {
    display: flex;
    flex-direction: column;
    margin: 10px 20px;
    padding: 10px;
}
.will-type-detail h2 {
    display: flex;
    justify-content: space-between;
}
.legee-section {
    margin-top: 10px;
    padding: 10px;
}
.legee-section h2 {
    font-size: 22px;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-rank,
.add-legs {
    cursor: pointer;
    font-weight: lighter;
    display: inline-block;
}
.add-rank:hover,
.add-legs:hover {
    color: orange;
}
.rank-label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-top: 2px white solid;
    border-bottom: 2px white solid;
    font-weight: bold;
}
.delete-rank-btn {
    cursor: pointer;
}
.delete-rank-btn:hover {
    color: red;
}

.rank {
    padding: 0 20px;
}
.shared-asset-detail {
    display: flex;
    flex-direction: column;
    position: relative;
}
.shared-asset-label {
    display: flex;
    justify-content: center;
    align-items: center;
}
.shared-asset-detail h2 {
    margin: 0;
    font-size: 20px;
}
.delete-shared-btn {
    cursor: pointer;
}
.delete-shared-btn:hover {
    color: red;
}

.add-shared-property {
    cursor: pointer;
    text-align: center;
    background-color: orange;
    font-size: 20px;
}
</style>

<style src="./newStyle.css" scoped></style>