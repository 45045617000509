<template>
    <h1 class="section-label">Simulations des plus-values immobilières</h1>
    <div class="separator"></div>

    <table class="simulation-table">
        <tr>
            <th>Bien vendu</th>
            <th>Valeur</th>
            <th>Date de la vente</th>
            <th>Prix d'achat</th>
            <th>Détail</th>
        </tr>

        <tr v-for="sell in propertySells" :key="sell._id">
            <td>{{ property_types[sell.property_type] }}</td>
            <td>{{ toEuro(sell.current_value) }}</td>
            <td>{{ dateToString(sell.selling_date) }}</td>
            <td>{{ toEuro(sell.initial_value) }}</td>
            <td> 
                <span class="material-symbols-outlined" style="cursor: pointer" @click="toggleShowPropertyGain(sell._id)">border_color</span>
            </td>

            <modale :show="selectedPropertySell == sell._id" @toggle="toggleShowPropertyGain(sell._id)" width="50%">
                <div class="modale-content">
                    <table class="form-table">
                        <tr class="header">
                            <th colspan="2">Données de calcul</th>
                        </tr>

                        <tr>
                            <th>Type de bien</th>
                            <td>
                                <select v-model="sell.property_type">
                                    <option v-for="(value, key) in property_types" :key="key" :value="key">{{ value }}</option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <th>Mode d'acquisition</th>
                            <td>
                                <select v-model="sell.is_bought">
                                    <option :value="true">Acquisition à titre onéreux</option>
                                    <option :value="false">Acquisition à titre gratuit</option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <th>Date d'acquisition</th>
                            <td> <input type="date" v-model="sell.acquisition_date"> </td>
                        </tr>

                        <tr>
                            <th>Date de la vente</th>
                            <td> <input type="date" v-model="sell.selling_date"> </td>
                        </tr>

                        <tr>
                            <th>Prix de vente</th>
                            <td> <currency-input v-model="sell.current_value"/> </td>
                        </tr>

                        <tr>
                            <th>Prix d'achat</th>
                            <td> <currency-input v-model="sell.initial_value"/> </td>
                        </tr>

                        <tr>
                            <th>Amortissements déduits de l'impôt sur le revenu</th>
                            <td> <currency-input v-model="sell.amortization"/> </td>
                        </tr>

                        <tr>
                            <th>Frais d'acquisition</th>
                            <td> <currency-input v-model="sell.acquisition_fees"/> </td>
                        </tr>

                        <tr>
                            <th>Travaux réalisés (sur justificatif)</th>
                            <td> <currency-input v-model="sell.work_value"/> </td>
                        </tr>
                    </table>

                    <div class="btn-frame">
                        <div class="save-btn" @click="savePropertySell(sell._id)">Calculer</div>
                    </div>

                    <div class="result-frame" v-if="sell.result">
                        <h2>Résultats de la simulation</h2>
                        <h3>
                            <span>Années de détention :</span>
                            <span>{{ sell.result.duree_detention.duree_detention }} ans (du {{ dateToString(sell.acquisition_date)}} au {{ dateToString(sell.selling_date) }})</span>
                        </h3>

                        <table class="result-table">
                            <tr>
                                <th>Élément</th>
                                <th>Acquisition</th>
                                <th>Cession</th>
                                <th>Plus-value imposable</th>
                            </tr>

                            <tr>
                                <td>Prix</td>
                                <td>{{ toEuro(sell.initial_value) }}</td>
                                <td>{{ toEuro(sell.current_value) }}</td>
                                <td></td>
                            </tr>

                            <tr>
                                <td>Frais</td>
                                <td>{{ toEuro(sell.result.frais_acquisition.frais_deductibles) }}</td>
                                <td></td>
                                <td></td>
                            </tr>

                            <tr>
                                <td>Travaux</td>
                                <td>{{ toEuro(sell.result.travaux_deductibles.montant_deductible) }}</td>
                                <td></td>
                                <td></td>
                            </tr>

                            <tr>
                                <th>Total</th>
                                <th>{{ toEuro(sell.initial_value + sell.result.frais_acquisition.frais_deductibles + sell.result.travaux_deductibles.montant_deductible) }}</th>
                                <th>{{ toEuro(sell.current_value) }}</th>
                                <th>{{ toEuro(sell.result.plus_value_realisee.plus_value_realisee) }}</th>
                            </tr>
                        </table>
                        
                        <table class="result-table">
                            <tr>
                                <th>Label</th>
                                <th>Impôt sur le revenu</th>
                                <th>Prélèvements sociaux</th>
                            </tr>

                            <tr>
                                <td>Taux de réduction lié à la durée de détention</td>
                                <td>{{ toPercent(sell.result.impot_sur_revenu.exoneration_duree_detention) }}</td>
                                <td>{{ toPercent(sell.result.prelevements_sociaux.exoneration_duree_detention) }}</td>
                            </tr>

                            <tr>
                                <td>Montant de la réduction lié à la durée de détention</td>
                                <td>{{ toEuro(sell.result.impot_sur_revenu.exoneration_duree_detention * sell.result.plus_value_realisee.plus_value_imposable) }}</td>
                                <td>{{ toEuro(sell.result.prelevements_sociaux.exoneration_duree_detention * sell.result.plus_value_realisee.plus_value_imposable) }}</td>
                            </tr>

                            <tr>
                                <td>Plus-value taxable</td>
                                <td>{{ toEuro(sell.result.impot_sur_revenu.assiette) }}</td>
                                <td>{{ toEuro(sell.result.prelevements_sociaux.assiette) }}</td>
                            </tr>

                            <tr>
                                <td>Taux de prélèvement</td>
                                <td>19%</td>
                                <td>17.20%</td>
                            </tr>

                            <tr>
                                <th>Montant à payer</th>
                                <th>{{ toEuro(sell.result.impot_sur_revenu.impot_revenu) }}</th>
                                <th>{{ toEuro(sell.result.prelevements_sociaux.prelevement) }}</th>
                            </tr>

                            <tr>
                                <td>Taux de la surtaxe</td>
                                
                                <td>{{ toPercent(sell.result.impot_sur_revenu.surtaxe.taux) }}</td>
                                <td></td>
                            </tr>

                            <tr>
                                <th>Montant de la surtaxe</th>
                                <th>{{ toEuro(sell.result.impot_sur_revenu.surtaxe.surtaxe) }}</th>
                                <th></th>
                            </tr>
                        </table>

                        <h3>
                            <span>Total à payer :</span>
                            <span>{{ toEuro(sell.result?.prelèvement_total ) }}</span>
                        </h3>
                    </div>
                </div>
            </modale>
        </tr>
    </table>
</template>

<script>
import axios from 'axios';

export default {
    props: ['selectedScenario'],
    data() {
        return {
            property_types: {
                residence_principale: "Résidence principale",
                location_nue: "Bien en location nue",
                location_lmnp: "Bien en LMNP",
            },
            propertySells: [],
            selectedPropertySell: undefined,
        }
    },
    methods: {
        async loadPropertySells() {
            const res = await axios.get(this.$store.getters.get_api_url + 'clients/property-sell/' + this.selectedScenario, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            this.propertySells = res.data

            for (let i = 0; i < this.propertySells.length; i++) {
                let computation = await axios.post(this.$store.getters.get_api_url + 'simulators/real-estate-gain',{
                    date_acquisition: this.propertySells[i].acquisition_date,
                    date_vente: this.propertySells[i].selling_date,
                    valeur_acquisition: this.propertySells[i].initial_value,
                    est_achat : this.propertySells[i].is_bought,
                    frais_acquisition: this.propertySells[i].acquisition_fees,
                    travaux_deductibles: this.propertySells[i].work_value,
                    amortissements: this.propertySells[i].amortization,
                    prix_vente: this.propertySells[i].current_value,
                    frais_vente: this.propertySells[i].selling_fees,
                    type_bien_immobilier: this.propertySells[i].property_type,
                }, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })
                this.propertySells[i].acquisition_date = this.dateToString(this.propertySells[i].acquisition_date, true)
                this.propertySells[i].selling_date = this.dateToString(this.propertySells[i].selling_date, true)
                this.propertySells[i].result = computation.data
            }
        },
        
        async savePropertySell(id) {
            const sell = this.propertySells.find(s => s._id == id)
            await axios.put(this.$store.getters.get_api_url + 'clients/property-sell/' + id, sell, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            this.loadPropertySells()
        },
        toggleShowPropertyGain(_id) {
            if (this.selectedPropertySell == _id) {
                this.selectedPropertySell = undefined
            } else {
                this.selectedPropertySell = _id
            }   
        },
    },
    watch: {
        selectedScenario() {
            this.loadPropertySells()
        }
    }, 
    mounted() {
        this.loadPropertySells()
    }
}
</script>

<style src="./style.css" scoped></style>

<style scoped>
.modale-content {
    padding: 20px;
}

/* Formulaire */
.form-table {
    table-layout: fixed;
    margin: 10px 0;
    border-collapse: collapse;
    width: 100%;
}
tr.header {
    background-color: #0a1c6b;
}
tr.header th {
    color: white;
    text-align: center;
    padding: 10px;
}
.form-table th, 
.form-table td {
    font-family: "verdana", sans-serif;
    font-size: 16px;
    padding: 5px 10px;
    position: relative;
    border: 1px lightgrey solid;
}
.form-table th {
    text-align: left;
    color: grey;
}
.form-table td {
    text-align: center;
    color: grey;
}
.form-table td input,
.form-table td select {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: none;
    text-align: center;
    font-size: 16px;
    color: grey;
}

/* Résultats */
.result-frame {
    display: flex;
    flex-direction: column;
}
.result-frame h3 {
    font-size: 15px;
    display: flex;
    justify-content: space-between;
}

.result-table {
    border-collapse: collapse;
    margin: 10px 0;
}
.result-table th,
.result-table td {
    font-size: 15px;
    padding: 5px 10px;
    border: 1px solid lightgrey;
    text-align: center;
}
.result-table th:first-child,
.result-table td:first-child {
    text-align: left;
}

.result-table th {
    background-color: #F7F4EC;
}

/* Actions */

.btn-frame {
    display: flex;
    justify-content: center;
}

.save-btn {
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 5px;
    background-color: #0a1c6b3b;
}
.save-btn:hover {
    background-color: #0a1c6b1e;
}
</style>