<template>
    <h1 class="section-label">Changements de régime fiscal de sociétés</h1>
        <div class="separator"></div>

        <table class="simulation-table">
            <tr>
                <th>Société</th>
                <th>Nouveau régime fiscal</th>
                <th>Durée de la simulation</th>
                <th>Détail</th>
            </tr>

            <tr v-for="simulation in simulations" :key="simulation._id">
                <td>{{ $store.getters.findSocietyName(simulation.societyId) }}</td>
                <td>{{ $store.getters.findSocietyTaxOption(simulation.societyId) }}</td>
                <td>{{ simulation.simulation_duration }} ans</td>
                <td> 
                    <span class="material-symbols-outlined" style="cursor: pointer" @click="toggleShowSimulation(simulation._id)">border_color</span>
                    <modale :show="showSimulation == simulation._id" @toggle="toggleShowSimulation(simulation._id)" width="75%">
                        <SocietyTaxesForm :simulationDatas="simulation"/>
                    </modale>
                </td>
            </tr>
        </table>
</template>

<script>
import axios from 'axios'
import SocietyTaxesForm from "./forms/SocietyTaxes.vue"

export default {
    props: ['selectedScenario'],
    components: {
        SocietyTaxesForm,
    },
    data() {
        return {
            simulations: [],
            showSimulation: undefined,
            currentSimulation: {},
        }
    },
    methods: {
        toggleShowSimulation(_id) {
            if (this.showSimulation == _id) {
                this.showSimulation = undefined
            } else {
                this.showSimulation = _id
            }   
        },
    },
    async mounted() {
        const res = await axios.get(this.$store.getters.get_api_url + 'clients/society-tax-comparison/' + this.selectedScenario, {
            headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
        }) 
        
        this.simulations = res.data
    }
}
</script>

<style src="./style.css" scoped></style>