<template>
    <div>
        <div class="menu">
            <div :class="{'menu-selection': !show_detailed_result}" @click="show_detailed_result=false">Données de simulation</div>
            <div :class="{'menu-selection': show_detailed_result, 'menu-disabled': computationResult == undefined}" @click="computationResult ? show_detailed_result=true : null">Résultat détaillé</div>
        </div>

        <div class="calculator-frame">
            <div class="left-section" v-if="!show_detailed_result">
                <div class="section-element">
                    <h3>Entreprise</h3>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Capital social</label>
                            <currency-input v-model="share_capital"/>
                        </div>

                        <div class="input-frame">
                            <label>Résultat fiscal</label>
                            <currency-input v-model="society_taxes_result"/>
                        </div>
                    </div>
                </div>

                <div class="section-element">
                    <h3>Données de simulation</h3>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Régime social</label>
                            <select v-model="job_status">
                                <option v-for="(value, key) in $store.getters.socialStatus" :key="key" :value="key">{{ value }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Budget de rémunération</label>
                            <currency-input v-model="budget"/>
                        </div>

                        <div class="input-frame">
                            <label>Dont dividendes</label>
                            <currency-input v-model="dividends"/>
                        </div>
                    </div>
                </div>

                <div class="section-element">
                    <h3>Fiscalité</h3>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Couple fiscal</label>
                            <select v-model="is_couple">
                                <option :value="true">OUI</option>
                                <option :value="false">NON</option>
                            </select>
                        </div>
                        
                        <div class="input-frame">
                            <label>Nombre d'enfants rattachés fiscalement</label>
                            <input type="number" v-model="nb_attached_children">
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Autres revenus imposables du foyer</label>
                            <currency-input v-model="other_incomes"/>
                        </div>
                    </div>
                </div>

                <div class="section-element btn-section">
                    <loader-spin v-if="computation_loading"></loader-spin>
                    <div class="btn-send-computation btn-grow" @click="computeResult" v-else>Calculer</div>
                </div>
            </div>

            <div class="left-section" v-else>

                <div class="section-element">
                    <h3>Résultat détaillé</h3>

                    <table class="calculation-result">
                        <tr>
                            <th>Régime social</th>
                            <td>{{ computationResult.social_status }}</td>
                        </tr>

                        <tr>
                            <th>Budget de rémunération</th>
                            <td>{{ toEuro(computationResult.budget) }}</td>
                        </tr>

                        <tr>
                            <th>Dividendes</th>
                            <td>- {{ toEuro(computationResult.dividends) }}</td>
                        </tr>

                        <tr>
                            <th>Impôt sur les sociétés sur la part de dividendes</th>
                            <td>- {{ toEuro(computationResult.society_tax) }}</td>
                        </tr>

                        <tr class="highlight">
                            <th>Rémunération « super brut »</th>
                            <td>- {{ toEuro(computationResult.gross_remuneration) }}</td>
                        </tr>

                        <tr>
                            <th>Cotisation sociales</th>
                            <td>- {{ toEuro(computationResult.contributionsResult.contributions) }}</td>
                        </tr>

                        <tr class="highlight">
                            <th>Rémunération brute avant impôts</th>
                            <td>{{ toEuro(computationResult.net_income_before_taxes) }}</td>
                        </tr>

                        <tr>
                            <th>Impôt sur le revenu au barème</th>
                            <td>{{ toEuro(computationResult.taxes) }}</td>
                        </tr>

                        <tr>
                            <th>Prélèvement forfaitaire unique (PFU)</th>
                            <td>{{ toEuro(computationResult.pfu) }}</td>
                        </tr>

                        <tr class="highlight">
                            <th>Rémunération « nette dans la poche » </th>
                            <td>{{ toEuro(computationResult.disposable_income) }}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="right-section">
                <div class="section-element" v-if="!computationResult">
                    <h3>Résultats</h3>

                    <img src="../ressources/img/illustration.png"/>

                    <p>Lancez le calcul pour obtenir un résultat</p>
                </div>

                <div class="section-element" v-if="computationResult">
                    <h3>Cotisations sociales</h3>
                    <h4>{{ toEuro(computationResult.contributionsResult.contributions) }}</h4>
                </div>

                <div class="section-element" v-if="computationResult">
                    <h3>Rémunération nette après impôts</h3>
                    <h4>{{ toEuro(computationResult.disposable_income) }}</h4>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            show_detailed_result: false,

            share_capital: 0,
            society_taxes_result: 0,
            job_status: "employee",
            budget: 100000,
            dividends: 0,
            other_incomes: 0,
            is_couple: false, 
            nb_attached_children: 0,

            computationResult: undefined,
        }
    },
    computed: {
        payload() {
            let id_count = 3
            let taxes_datas = {
                couple: {
                    situation: this.is_couple ? "married" : "alone"
                },
                persons: [
                    { _id: 1, birthdate: "1960-01-01", role: 'user' },
                ],
                incomes: [
                    { _id: 2, type: 'salary', net_value: this.other_incomes, person_id: '1', isMicro: true }
                ],
                properties: [],
                assets: [],
                societies: [],
                tax_reductions: [],
                deductible_expenses: [],
            }

            // Ajout des autres membres du foyer fiscal
            if (this.is_couple) {
                taxes_datas.persons.push(
                    { _id: id_count, birthdate: "1960-01-01", role: 'spouse' }
                )
                id_count ++
            }

            for (let i = 0; i < this.nb_attached_children; i++) {
                taxes_datas.persons.push(
                    { _id: id_count, birthdate: "2010-01-01", role: 'child', is_handicaped: false, is_veteran: false, is_attached: true, lives_at_home: true, situation: 'alone' }
                )

                id_count ++
            }

            return {
                datas: {
                    job_status: this.job_status,
                    budget: this.budget,
                    dividends: this.dividends,
                    auto_repartition: false,
                    share_capital: this.share_capital,
                    is_sel_director: false,
                    societyTaxesResult: this.society_taxes_result,
                },
                taxes_datas: {
                    data: taxes_datas,
                    choices: {
                        bpi_pvmo: false,
                    },
                    replaced_incomes: []
                }
            }
        }
    },
    methods: {
        async computeResult() {

            const res = await axios.post(this.$store.getters.get_api_url + 'simulators/management-remuneration', this.payload, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.computationResult = res.data
        }
    },
}
</script>

<style src="./style.css" scoped></style>